import React, { useState, useEffect } from 'react';
import Table from '../util/Table.js';
import UserWordSingle from './UserWordSingle.js';
import Cookies from 'universal-cookie';
import { style } from '../config/styleConfig'; // /lib/connect / connect
import { connect } from 'react-redux'; // /lib/connect / connect
import { Link } from 'react-router-dom';
import Dropdown from './Dropdown';
import url from '../config/config.js';
import levenshtein from 'fast-levenshtein';
import UserInfoKPI from './UserInfoKPI';
import SrsDistribution from './SrsDistribution.js';
import ScatterPlotAllWords from './ScatterPlotAllWords.js';
import LearnHistoryChart from './LearnHistoryChart.js';
import { useUserWords, useUserLearnData } from './useUserWords.js';

const cookies = new Cookies();

//to={`/UserWordSingle/${props.original._id}`}
const UserInfo = (props) => {
  const [state, setState] = useState({
    scatterShowHistory: true,
    userLearnData: false,
    userWords: [],
    userInfo: false,
    wordId: '',
    wordSearch: '',
    modal: false,
    hoverLink: '',
    dropDownValX: 'counter',
    dropDownValY: 'learnScore',
    columns: [
      {
        Header: 'English',
        accessor: 'english',
        Cell: (props) => (
          <p
            style={{ cursor: 'pointer', color: 'blue' }}
            className="String"
            id={props.original.wordId}
            onClick={(e) => {
              e.persist();
              setState((state) => {
                return { ...state, wordId: e.target.id, modal: true };
              });
            }}
          >
            {props.value}
          </p>
        ), // String-based value accessors! // String-based value accessors!
      },
      {
        Header: 'Counter - how often you had this word',
        accessor: 'currentLearnData.counter',
        Cell: (props) => <span className="number">{props.value}</span>, // Custom cell components!
      },
      {
        Header: 'streak',
        accessor: 'currentLearnData.streak',
        Cell: (props) => <span className="number">{props.value}</span>, // Custom cell components!
      },
      {
        Header: 'srs level',
        accessor: 'currentLearnData.srs',
        Cell: (props) => <span className="number">{props.value}</span>, // Custom cell components!
      },
      {
        Header: 'Learnscore',
        accessor: 'currentLearnData.learnScore',
        Cell: (props) => <span className="number">{props.value}</span>, // Custom cell components!
      },
      {
        Header: 'rank',
        accessor: 'rank',
        Cell: (props) => <span className="number">{props.value}</span>, // Custom cell components!
      },
    ],
  });

  let userId = props.match.params.userId;
  userId = userId !== 'null' ? userId : null;
  let [userWords] = useUserWords(props.getUserWords, userId);
  const [userLearnData] = useUserLearnData();

  useEffect(() => {
    if (props.store.userInfo)
      setState((state) => {
        return { ...state, userInfo: props.store.userInfo };
      });
  }, []);
  const disableModal = () => {
    setState((state) => {
      return { ...state, modal: false };
    });
  };
  const calcHardWords = () => {
    if (!userWords) return false;
    return userWords.filter(
      (word) =>
        (word.currentLearnData.counter >= 30 && word.currentLearnData.learnScore <= 100) ||
        word.currentLearnData.streak < -2
    );
  };
  const calcLearnedWords = () => {
    if (!userWords) return false;
    return userWords.filter(
      (word) =>
        word.currentLearnData.counter >= 10 && word.currentLearnData.learnScore >= 200 && word.currentLearnData.srs >= 3
    );
  };
  const calcTodayWords = () => {
    if (!userWords) return false;
    let today = new Date();
    today = today.setHours(0, 0, 0, 0);
    return userWords.filter((word) => new Date(word.currentLearnData.practiceDate) > today);
  };
  const calcScatterData = (words, learnedWords, hardWords) => {
    if (!words || !learnedWords) return false;
    let scatterData = words
      .map((w) => {
        return {
          ...w,
          currentLearnData: {
            ...w.currentLearnData,
            practiceDate: Date.now() - new Date(w.currentLearnData.practiceDate),
          },
        };
      })
      .map((word) => {
        return {
          y: word.currentLearnData[state.dropDownValY],
          x: word.currentLearnData[state.dropDownValX],
        };
      });
    let scatterDataLearnedWords =
      learnedWords &&
      learnedWords.map((word) => {
        return {
          y: word.currentLearnData[state.dropDownValY],
          x: word.currentLearnData[state.dropDownValX],
        };
      });
    let scatterDataHardWords =
      hardWords &&
      hardWords.map((word) => {
        return {
          y: word.currentLearnData[state.dropDownValY],
          x: word.currentLearnData[state.dropDownValX],
        };
      });

    scatterData =
      scatterData &&
      scatterData.filter(
        (s1) =>
          !scatterDataLearnedWords.some((s2) => s1.y === s2.y) && !scatterDataHardWords.some((s3) => s1.y === s3.y)
      );
    return { scatterData, scatterDataHardWords, scatterDataLearnedWords };
  };

  const searchFilterWords = (userWords) => {
    if (!userWords) return false;
    if (state.wordSearch === '') return userWords;
    let filteredWords = userWords
      .filter((word) => levenshtein.get(word.english, state.wordSearch) < 3)
      .sort((a, b) => levenshtein.get(a.english, state.wordSearch) - levenshtein.get(b.english, state.wordSearch));
    return filteredWords;
  };

  let dropDownValues = ['practiceDate', 'errorScore', 'learnScore', 'srs', 'streak', 'counter'];
  userWords = searchFilterWords(userWords);
  let learnLanguage = props.store.learnLanguage;
  let learnedWords = calcLearnedWords(learnLanguage);
  let hardWords = calcHardWords(learnLanguage);
  let todayWords = calcTodayWords();
  let { scatterData, scatterDataLearnedWords, scatterDataHardWords } = calcScatterData(
    userWords,
    learnedWords,
    hardWords
  );
  let kpiStyle = { display: 'flex', flexDirection: 'column', alignItems: 'center' };
  let kpiUnderline = { borderBottom: '2px solid black' };
  if (!userWords) {
    return (
      <div className="container">
        <div className="row white" style={{ ...style, height: '100vh' }}>
          <button onClick={props.history.goBack} style={{}} type="button" className="btn grey">
            Back
          </button>
          <br />
          <p style={{ textAlign: 'center' }}>you have no words yet</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="Userinfo">
        <UserWordSingle userId={userId} id={state.wordId} modal={state.modal} disableModal={disableModal} />
        <div className="container ">
          <div className="row">
            <br />
            <div className="col s12 white" style={{ ...style }}>
              <button onClick={props.history.goBack} type="button" className="btn grey">
                Back
              </button>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <UserInfoKPI
                  props={{ kpiStyle, kpiUnderline, words: userWords, learnedWords, hardWords, todayWords }}
                />
                <SrsDistribution words={userWords} />
              </div>
              <LearnHistoryChart props={{ userLearnData: userLearnData }} />
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 25 }}>
                <div style={{ marginRight: 25 }}>
                  <Dropdown
                    dropDownVal={state.dropDownValY}
                    dropDownValues={dropDownValues}
                    changeValue={Dropdown.changeValue}
                    stateName={'dropDownValY'}
                  />
                </div>
                <div style={{ marginRight: 25 }}>
                  <Dropdown
                    dropDownVal={state.dropDownValX}
                    dropDownValues={dropDownValues}
                    changeValue={Dropdown.changeValue}
                    stateName={'dropDownValX'}
                  />
                </div>
              </div>
              <h5>All Words</h5>
              <Link to={`${state.hoverLink.length > 2 ? '/UserWordSingle/' + state.hoverLink : '/userinfo'}`}>
                <ScatterPlotAllWords
                  props={{
                    words: userWords,
                    dropDownValX: state.dropDownValX,
                    dropDownValY: state.dropDownValY,
                    scatterData,
                    scatterDataLearnedWords,
                    scatterDataHardWords,
                  }}
                />
              </Link>
              <div>
                <input
                  onChange={(e) => {
                    e.persist();
                    setState((state) => {
                      return { ...state, wordSearch: e.target.value };
                    });
                  }}
                  value={state.wordSearch}
                  placeholder={'search for a word'}
                />
                <Table
                  data={userWords && userWords}
                  columns={state.columns}
                  showPageJump={true}
                  showPagination={true}
                  defaultPageSize={30}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
const mapStateToProps = (state) => {
  return {
    store: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    endTime: () => {
      dispatch({
        type: 'VOCAB_END_TIME',
        payload: '',
      });
    },
    getUserWords: (response) => {
      dispatch({
        type: 'USERWORDS_GET',
        payload: response,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
