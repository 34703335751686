import React, { Component } from 'react';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Link from "react-router-dom"
const Item = () => (
    <div>

    </div>
)


class Table extends React.Component {
    constructor(props) {
        super(props);
        ////console.log(props)
        this.state = {
            defaultColumns: [{
                Header: "English",
                accessor: "english" // String-based value accessors!
                }, {
                Header: 'Answer',
                accessor: "userAnswer",
                Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
            }]
        }
    }
    componentWillReceiveProps(nextProps) {

    }
    render() {

        if(!this.props.data) return null;
        return (
            <ReactTable className="answer-table"
                //pageSizeOptions={[5, 10, 20, 25, 50, 5]}
                showPageJump={this.props.showPageJump}
                showPagination={this.props.showPagination}
                data={this.props.data}
                columns={this.props.columns}
                defaultPageSize={this.props.defaultPageSize}
                style={{...this.props.style, color: 'black'}}
            />
        )

    }
}
Table.defaultProps = {
    defaultPageSize: 6,
    showPageJump: false,
    showPagination: false

}
// class List extends React.Component {

// 	render(props){
// 		const itemList = this.props.data.map((d) => {return <li key={d.name}>{d.name}}</li>
// 	  	  	return
// 	  	  	( 			
//   	  		<div> 
// 	  	  		{itemList} 
// 			</div>
// 			)

//   	  }

// }


export default Table;
