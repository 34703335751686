import url from '../config/config';
import axios from "axios"
import Cookies from 'universal-cookie';
import VideoWatcherService from './TextService';
const cookies = new Cookies();
export default class TextAPIService{

    static async saveTextDataAPI(textUrl, data) {
        let {textArray, title, subTitle, img} = data;
        axios({ 
            method: 'post', 
            url: `${url}/create/TextData`,
            headers: { 'X-Auth-Token': cookies.get('token') },
            data: {textArray, title, subTitle, img, textUrl}
        }).then( response => {
        })



    }
    static async getOrCreateManyDictWordsAPI(stringArray){
        let res = await axios({ 
            method: 'post', 
            url: `${url}/create/dictWord/many/spanish`,
            data: {spanish: stringArray},
            headers: { 'X-Auth-Token': cookies.get('token') }
        })
        return res.data.dictWords;

    }
    static async scrapeTextAPI(textUrl) {
        let res = await axios({ 
            method: 'post', 
            url: `${url}/scrape/text`,
            headers: { 'X-Auth-Token': cookies.get('token') },
            data: {textUrl}
        });
        return res.data;
        
    }
    static async getUserDictWordsAPI() {
        let res = await axios({ 
            method: 'get', 
            url: `${url}/get/userDictWords/all`,
            headers: { 'X-Auth-Token': cookies.get('token') }
        });
        return  res.data.dictWords
    }
    static async getUserTextListAPI() {
        let res = await axios({ 
            method: 'get', 
            url: `${url}/get/userText/all`,
            headers: { 'X-Auth-Token': cookies.get('token') }
        });
        return res.data.userTextList
    }
    static getManyDictsWordsAPI(spanish){

            return axios({ 
                method: 'post', 
                url: `${url}/get/many/dictWord/spanish`,
                data: {spanish: [spanish]},
                headers: { 'X-Auth-Token': cookies.get('token') }
            })
    }

    // static getUserDictWordsAPI(){
        // if(!cookies.get('token')) return false;
        // return axios({ 
            // method: 'get', 
            // url: `${url}/get/userDictWords/all`,
            // headers: { 'X-Auth-Token': cookies.get('token') }
        // }).then( response => {
            // return response.data.dictWords;
            // this.setState({userDictWords: response.data.dictWords})
        // }).catch(e => {
            // console.log('err', e)
            // 
        // });
    // }

    static saveUserYouTubeDataAPI({currentSubIndex, videoUrl, videoId}){
        axios({ 
            method: 'post', 
            url: `${url}/save/userYouTubeData`,
            headers: { 'X-Auth-Token': cookies.get('token') },
            data: {
                currentSubIndex,
                videoUrl,
                videoId: VideoWatcherService.getIdFromTYURL(videoUrl)
            },
            //headers: { 'X-Auth-Token': cookies.get('token') }
        }).then( response => {
        }).catch(e => {
            console.log('err', e)
        });

    }
    static async getSingleYoutubeDataAPI(videoId){

        try {
            let res = await axios({ 
                method: 'post', 
                url: `${url}/get/single/youTubeData`,
                data: {videoId}
                //headers: { 'X-Auth-Token': cookies.get('token') }
            })
            return res.data;
            
        } catch (error) {
            
            console.log('err', error);
        }

    }
    static async getAllYouTubeDataAPI(){
        let res = await axios({ 
            method: 'get', 
            url: `${url}/get/all/youTubeData`,
            headers: { 'X-Auth-Token': cookies.get('token') },
            //headers: { 'X-Auth-Token': cookies.get('token') }
        })
        return res.data

    }
    static getUserYouTubeDataAPI(){
        axios({ 
            method: 'get', 
            url: `${url}/get/userYouTubeData`,
            headers: { 'X-Auth-Token': cookies.get('token') },
            //headers: { 'X-Auth-Token': cookies.get('token') }
        }).then( response => {
            console.log(response.data)
            let userYouTubeData = response.data.userYouTubeData;
            let {videoUrl, usage} = userYouTubeData;
            let currentSubIndex = usage[usage.length - 1].subTitleIndex
            console.log(videoUrl, usage, currentSubIndex)
            //this.setState({videoUrl: videoUrl, currentSubIndex});
        }).catch(e => {
            console.log('err', e)
        });

    }

  static async getDictWordsAPI(stringArray){
        if(!cookies.get('token')){
            setTimeout(async () => {
                return await TextAPIService.getDictWordsAPI(stringArray)
            }, 1000)
            return false;

        }
        stringArray = stringArray.map( s => {
            //let newS = s.replace('/[ !,¡.¿?:]/gi', 'test')
            let newS = VideoWatcherService.cleanString(s)
            return newS
        })

        try{
            let res = await axios({ 
                method: 'post', 
                url: `${url}/create/dictWord/many/spanish`,
                data: {spanish: stringArray},
                //headers: { 'X-Auth-Token': cookies.get('token') }
            })
            return res.data.dictWords

        } catch (e){

            console.log('err', e)
            return false;
        }

        
    }

  static async saveDictWordAPI(dictWord){
      let response = await axios({
        method: 'post',
        url: `${url}/DictWord/Save`,
        headers: { 'X-Auth-Token': cookies.get("token") },
        data: {dictWord}
      });

    }
  static addAllWordsAPI(subtitles){
        axios({ 
            method: 'post', 
            url: `${url}/add/words/All`,
            headers: { 'X-Auth-Token': cookies.get('token') },
            data: {subtitles}
        }).then( response => {
        })

    }
  static saveVideoDataAPI(subtitles, videoUrl, videoId){

        if(!cookies.get('token')){
            setTimeout(() => {
                this.saveVideoDataAPI(subtitles, videoUrl, videoId)
            }, 1000)
            return false;

        }
        axios({ 
            method: 'post', 
            url: `${url}/create/youTubeData`,
            headers: { 'X-Auth-Token': cookies.get('token') },
            data: {subtitles, videoUrl, videoId}
        }).then( response => {
          return response.data
        })


    }
    static async getTextDataAPI(textUrl){
        let res = await axios({ 
            method: 'post', 
            url: `${url}/get/TextData`,
            headers: { 'X-Auth-Token': cookies.get('token') },
            data: {textUrl}
        });
        return res.data.textData;
    }
}