import React from 'react';
import Navbar from './config/navbar.js';
import Main from './config/Main.js';

function App() {
  return (
    <div className="mainWrapper " style={{ margin: 0, padding: 0 }}>
      <Navbar />
      <div id="body ">
        <Main className="main" style={{ margin: 0, padding: 0 }} />
      </div>
    </div>
  );
}

export default App;
