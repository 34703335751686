import React, { Component } from 'react';
import levenshtein from 'fast-levenshtein';
import { style } from '../../config/styleConfig.js';
import url from '../../config/config.js';
import axios from 'axios';
import Dropdown from '../Dropdown';
import { connect } from 'react-redux'; // /lib/connect / connect

import QuickWordEdit from './QuickWordEdit';
import Syllabus from './Syllabus';
import CreateHeadWord from './CreateHeadWord';
import { SyllabusNavigation, SearchResults } from './components';

import Cookies from 'universal-cookie';
import { Button } from '@chakra-ui/button';
import { Flex, Box } from '@chakra-ui/layout';
import Pagination from './components/Pagination.js';
const cookies = new Cookies();

class Editor extends Component {
  constructor() {
    super();
    this.state = {
      find: [],
      search: '',
      success: true,
      showSearch: false,
      headWords: [],
      searchHeadWords: [],
      showCreateHeadWord: false,
      paginationStep: 0,
      headWord: {
        english: 'abc',
        spanish: 'test',
        orderRank: 1,
        disabled: false,
        synonyms: {
          english: ['test'],
          spanish: ['test'],
          german: ['test'],
        },
      },
      navigationData: {
        headWordAmount: 30,
        rank: 1,
        showDisabled: false,
        pos: 'all',
      },
      changeRankData: {
        changeRank: false,
        rangeStart: null,
        rangeEnd: null,
        value: null,
        newRank: 0,
      },
    };
  }

  // only contain english
  getSearchHeadWords = async () => {
    const {
      data: { headWords },
    } = await axios({
      method: 'get',
      url: `${url}/get/headWords`,
      headers: { 'X-Auth-Token': cookies.get('token') },
    });
    this.setState({
      searchHeadWords: headWords,
    });
  };

  // only contain english
  getSingleHeadWord = async (_id) => {
    const {
      data: { headWord },
    } = await axios({
      method: 'post',
      url: `${url}/get/headWord`,
      headers: { 'X-Auth-Token': cookies.get('token') },
      data: { _id },
    });
    this.setState({
      headWord,
    });
  };

  componentDidMount() {
    this.getHeadWords();
    this.getSearchHeadWords();
    window.onkeypress = this.pressKeyS;
  }

  // needs to be at parent
  handleKeyPress = (e) => {
    e = e || window.event;
    const key = e.key;
    if (key === 'Enter') {
      this.getSingleHeadWord(this.state.find[0].english);
      this.setState({ search: '', showSearch: false });
    }
    // first 3 dont work for some reason
    if (key === 'Escape' || e.which === 27 || e.keyCode === 27 || key === 'X') {
      this.setState({ search: '', showSearch: false });
    }
  };

  handleWordClick = (e) => {
    const id = e.target.id;
    const headWord = this.state.searchHeadWords.find((h) => h._id === id);
    console.log(headWord);
    if (headWord) {
      this.getSingleHeadWord(headWord._id);
      this.setState({ search: '', showSearch: false });
    }
  };

  search = ({ target: { value } }) => {
    const localSearch = value;
    this.setState({ search: localSearch });

    let allWords = this.state.searchHeadWords;
    let find = 'search for it';
    if (allWords.length > 0) {
      allWords.forEach((word) => (word.match = levenshtein.get(word.english, localSearch)));
      find = allWords.filter((a) => a.match < 3).sort((a, b) => a.match - b.match);

      find.length = find.length > 5 ? 5 : find.length;

      if (find.length > 0) {
        this.setState({ find });
      }
    }
  };

  pressKeyS = (e) => {
    if (e.key === 'S') {
      e.preventDefault();
      this.toggleSearch();
    }
  };

  toggleSearch = () => {
    this.setState({ showSearch: this.state.showSearch ? false : true, search: '' });
  };
  toggleStateVal = (val) => {
    this.setState({ [val]: this.state[val] ? false : true });
  };

  toggleShowCreateHeadWord = () => {
    this.toggleStateVal('showCreateHeadWord');
    console.log('state', this.state);
  };

  handleWordSave = async () => {
    const { headWord } = this.state;
    this.setState({ success: false });
    const { changeRank, rangeStart, rangeEnd, value, newRank } = this.state.changeRankData;
    console.log(this.state);

    const {
      data: { headWord: updatedHeadWord, success },
    } = await axios({
      method: 'post',
      url: `${url}/post/headWord`,
      headers: { 'X-Auth-Token': cookies.get('token') },
      data: { headWord: headWord, changeRank, rangeStart, rangeEnd, value, newRank },
    });

    this.setState({
      headWord: updatedHeadWord,
      success: success,
    });
    this.getHeadWords();
  };

  wordClick = (e) => {
    const { headWords } = this.state;
    const id = e.target.id;
    const headWord = headWords.find((hw) => hw._id === id);

    if (headWord) {
      this.getSingleHeadWord(headWord._id);
      // this.setState({ headWord });
    }
  };

  handleDropdown = (e) => {
    let navigationData = this.state.navigationData;
    navigationData[e.target.getAttribute('name')] = e.target.innerText;
    this.setState({ navigationData });
    this.getHeadWords();
  };

  handleNavtionToggle = (e) => {
    let navigationData = this.state.navigationData;
    navigationData[e.target.name] = navigationData[e.target.name] ? false : true;
    this.setState({
      navigationData,
    });
    this.getHeadWords();
  };

  handleSetNavigation = (e) => {
    let val = e.target.value;
    val = val === '' ? 0 : val;
    val = Number(val);
    if (val < 1) {
      val = 1;
    }

    let navigationData = this.state.navigationData;
    navigationData[e.target.name] = val;
    this.setState({
      navigationData,
    });
    this.getHeadWords();
  };

  getHeadWords = async () => {
    const { headWordAmount, rank, showDisabled, pos } = this.state.navigationData;
    const { paginationStep } = this.state;
    let {
      data: { headWords },
    } = await axios({
      method: 'post',
      url: `${url}/basic/headWords/all`,
      headers: { 'X-Auth-Token': cookies.get('token') },
      data: { minRank: headWordAmount * paginationStep, maxRank: false, limit: headWordAmount, showDisabled, pos },
    });

    this.setState({ headWords });
  };

  handleDisableWord = (e) => {
    console.log(e.target);
    const { headWords } = this.state;
    const english = e.target.id;
    let headWord = headWords.find((hw) => hw.english === english);
    if (headWord) {
      headWord.disabled = headWord.disabled ? false : true;
      console.log(headWord);
      this.setState({ headWord }, () => {
        this.handleWordSave();
      });
    }
  };

  changeOrderRank = (newRank) => {
    let { changeRankData, headWord } = this.state;
    console.log(changeRankData, newRank, this.state);
    // 9 should become 4
    // 9 - 4 = 5
    //
    const direction = headWord.orderRank - newRank;
    if (direction > 0) {
      changeRankData.changeRank = true;
      changeRankData.rangeStart = newRank;
      changeRankData.rangeEnd = headWord.orderRank - 1;
      changeRankData.value = 1;
      changeRankData.newRank = newRank;
    } else if (direction < 0) {
      // 4 should become 9
      // 4 - 9  = -5
      //
      changeRankData.changeRank = true;
      changeRankData.rangeStart = headWord.orderRank + 1;
      changeRankData.rangeEnd = newRank;
      changeRankData.value = -1;
      changeRankData.newRank = newRank;
    }
    console.log('rank set', changeRankData, newRank);
    headWord = { ...headWord };
    headWord.orderRank = newRank;

    this.setState({ changeRankData, headWord });
  };

  handleOrderRankChange = (e) => {
    const newRank = parseInt(e.target.value);
    this.changeOrderRank(newRank);
  };

  handleSynonymChange = (e) => {
    e.preventDefault();
    let headWord = { ...this.state.headWord };
    let newVal = e.target.value;
    let language = e.target.name;
    const updatedSynonyms = headWord.synonyms[language].splice(e.target.id, 1, newVal);

    // headWord.synonyms[language].map((value, i) => {
    //   if (i === e.target.id) {
    //     console.log(newVal);
    //     return newVal;
    //   } else {
    //     return value;
    //   }
    // });
    console.log('updtate array', newVal, updatedSynonyms, headWord.synonyms[language]);
    // headWord.synonyms[language] = updatedSynonyms;
    // headWord.synonyms[language] = headWord.synonyms[language].filter((value) => value !== '');
    this.setState({ headWord });
    // e.target.focus();
  };

  handleChangeWord = (e) => {
    let updatedHeadWord = this.state.headWord;
    let newVal = e.target.value;
    let language = e.target.name;
    updatedHeadWord[language] = newVal;
    console.log(updatedHeadWord, newVal, language);
    this.setState({ headWord: updatedHeadWord });
  };

  handleDisableWord = () => {
    let headWord = this.state.headWord;
    headWord.disabled = headWord.disabled ? false : true;
    this.setState({ headWord });
  };

  handleAddWordSynonyms = (e) => {
    let headWord = this.state.headWord;
    const language = e.target.name;
    headWord.synonyms[language].push('');
    this.setState({ headWord });
  };

  handleSetPagination = (direction) => {
    if (direction === 'next') {
      this.setState({ paginationStep: this.state.paginationStep + 1 });
    } else if (direction === 'prev') {
      this.setState({ paginationStep: this.state.paginationStep - 1 });
    } else if (direction === 'first') {
      this.setState({ paginationStep: 0 });
    } else if (direction === 'last') {
      this.setState({ paginationStep: 3500 / this.state.navigationData.headWordAmount });
    }
    this.getHeadWords();
    console.log('state pagination', this.state);
  };

  handleSetRank = (direction) => {
    const { headWord } = this.state;
    const currentRank = headWord.orderRank;
    if (direction === 'next') {
      this.changeOrderRank(currentRank + 10);
    } else if (direction === 'prev') {
      this.changeOrderRank(currentRank - 10);
    } else if (direction === 'first') {
      this.changeOrderRank(1);
    } else if (direction === 'last') {
      this.changeOrderRank(3500);
    }
    console.log('state rank', this.state);
  };

  // state of the bug:
  // a minor change to the props
  // does NOT trigger a re-render
  // eg: if english on headWord is chagned
  // but only headWord is the prop
  // NO re-render
  // if headWord.english is the prop
  // re-render
  render() {
    const { headWord, search, showSearch, find, showCreateHeadWord } = this.state;
    console.log('Editor render', headWord);
    return (
      <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Flex px="8">
          <Button onClick={this.toggleSearch} colorScheme="teal" mr="4">
            Search
          </Button>
          <Button onClick={this.toggleShowCreateHeadWord} colorScheme={showCreateHeadWord ? 'teal' : 'blue'}>
            Create Head Word
          </Button>
        </Flex>
        {showCreateHeadWord && <CreateHeadWord />}
        {headWord && (
          <QuickWordEdit
            onSetRank={this.handleSetRank}
            headWord={this.state.headWord}
            handleOrderRankChange={this.handleOrderRankChange}
            {...this.state.headWord}
            // force deep compare
            englishSynonyms={this.state.headWord.synonyms.english}
            spanishSynonyms={this.state.headWord.synonyms.spanish}
            germanSynonyms={this.state.headWord.synonyms.german}
            length={this.state.headWord.synonyms.english.length}
            length2={this.state.headWord.synonyms.spanish.length}
            success={this.state.success}
            // fuck you react
            handleWordSave={this.handleWordSave}
            orderRank={this.state.changeRankData.newRank}
            originalOrderRank={this.state.headWord.orderRank}
            handleSynonymChange={this.handleSynonymChange}
            handleChangeWord={this.handleChangeWord}
            handleChangeWordEnglish={this.handleChangeWordEnglish}
            handleDisableWord={this.handleDisableWord}
            handleAddWordSynonyms={this.handleAddWordSynonyms}
          />
        )}
        <Syllabus
          navigation={
            <SyllabusNavigation
              paginationStep={this.state.paginationStep}
              onSetPagination={this.handleSetPagination}
              handleSetNavigation={this.handleSetNavigation}
              navigationData={this.state.navigationData}
              handleNavtionToggle={this.handleNavtionToggle}
              handleDropdown={this.handleDropdown}
            />
          }
          headWords={this.state.headWords}
          wordClick={this.wordClick}
          headWord={this.state.headWord}
          handleDisableWord={this.handleDisableWord}
          pagination={
            <Pagination onSetPagination={this.handleSetPagination} paginationStep={this.state.paginationStep} />
          }
        />
        <SearchResults
          toggleSearch={this.toggleSearch}
          search={this.search}
          value={search}
          handleKeyPress={this.handleKeyPress}
          handleWordClick={this.handleWordClick}
          showSearch={showSearch}
          find={find}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.vocabReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset: () => {
      dispatch({
        type: 'LESSON_RESET',
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
