import React from "react";
import BarChart from '../util/BarChart.js';
export default (props) => {
    let {userLearnData} = props.props;
    if (userLearnData && userLearnData.length > 0) return (
        <BarChart data={{
            labels: userLearnData.map( d => d.displayWord + " - " + d.testWord),
            datasets: [
                {
                label: "errorscore",
                data: userLearnData.map(d => d.errorScore),
                //backgroundColor: "red",
                fill: false,
                borderColor: 'red',
                type: 'line',
                },
                {
                label: "streak",
                data: userLearnData.map(d => d.streak),
                //backgroundColor: "pink",
                fill: false,
                borderColor: 'orange',
                type: 'line',
                },
                {
                label: "learnscore",
                data: userLearnData.map(d => d.learnScore / 10),
                backgroundColor: "teal",
                hidden: true,
                borderColor: 'teal',
                },
                {
                label: "time",
                data: userLearnData.map(d => d.totalTime),
                fill: false,
                //backgroundColor: "red",
                borderColor: 'black',
                type: 'line',
                },
        ]
        }}/>
    )
    else return null;
}