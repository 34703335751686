import React, { Component, useState, useEffect, useRef } from 'react';
import PlayerControlls from './PlayerControlls';
import url from '../config/config.js';
import axios from 'axios';
import { connect } from 'react-redux'; // /lib/connect / connect
import YouTube from 'react-youtube';
import Cookies from 'universal-cookie';
import UserWordSingle from './UserWordSingle.js';
import Text from './Text.js';
import VideoAPIService from './TextAPIService.js';
import TextService from './TextService.js';
import useUserDictWords from './useUserDictWords';
import useVideoData from './useVideoData';
import useVideoList from './useVideoList';
import useCurrentSubIndex from './useCurrentSubIndex';
import useWindowSize from './useWindowSize';
const cookies = new Cookies();

function ClickedDictWordsList({ handleDictWordClick, clickedDictWords, showDictWords }) {
  //if(!clickedDictWords) return null
  return (
    <div>
      <p>words you clicked on</p>
      <ul className="collection">
        {clickedDictWords.map((dictWord, i) => {
          return (
            <li
              onClick={(e) => {
                handleDictWordClick(e);
              }}
              id={dictWord.word}
              //onMouseEnter={(e)=> this.setState({ foundWord: dictWord}) }
              //onMouseLeave={(e)=> setTimeout(() => this.setState({ foundWord: this.state.foundWord.english == word.english ? {} : this.state.foundWord}), 3000) }
              className="collection-item"
              style={{
                color: 'black',
                //backgroundColor: dictWord.english === this.state.foundWord.english ? '#81d4fa' : 'white',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              key={i}
            >
              <p style={{ maxWidth: '60%', fontSize: '12' }}>
                {dictWord.word} - {dictWord.translation}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
const VideoWatcher = (props) => {
  const [state, setState] = useState({
    toggleFullScreen: false,
    showMoreVideos: false,
    showDictWords: false,
    errorMessage: '',
    subtitleMode: false,
    videoUrl: '',
    userInput: '',
    videoId: '',
    idList: ['Nx1_EnI0D5s', 'hKajsCb2JhA', 'KqTw0iAzDpk'],
    urlList: [
      //viz politic
      'https://www.youtube.com/watch?v=gNAdnYTcgUo',
      'https://www.youtube.com/watch?v=Nx1_EnI0D5s',
    ],
    player: false,
    currentSub: false,
    clickedWord: false,
    showPopup: false,
    clickedDictWords: [],
    userDictWords: [],
    dictWords: [],
    apiTranslation: false,
    screenWidth: window.innerWidth,
    isPlaying: false,
    isAdvancedMode: false,
  });

  let [player, setPlayer] = useState(false);

  let [videoId, setVideoId] = useState('');
  let [videoUrl, setVideoUrl] = useState('');
  let [idList, initialVideoId] = useVideoList();

  if (videoId === '') videoId = initialVideoId;

  let [subtitles, currentSub, errorMessage, dictWords] = useVideoData(videoId, videoUrl);
  let [currentSubIndex, setCurrentSubIndex] = useCurrentSubIndex(
    subtitles,
    state.subtitleMode,
    state.clickedWord,
    player
  );
  let [screenWidth] = useWindowSize();
  console.log(currentSubIndex);
  //let [dictWords] = useUserDictWords(cookies.get('token'));
  let [userDictWords] = useUserDictWords(cookies.get('token'));

  //let [subtitles, setSubtitles] = useState(false);
  const handleWindowSizeChange = () => {
    setState((state) => {
      return { ...state, screenWidth: window.innerWidth };
    });
  };
  const intervalSaveDataRef = useRef();

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.code == 'Escape') {
        setState(() => {
          return { ...state, showPopup: false, foundWord: {} };
        });
      }
    });

    return () => {
      document.removeEventListener('keydown', () => {});
      window.removeEventListener('resize', handleWindowSizeChange);

      //clearInterval(intervalRef.current)
      //clearInterval(intervalSaveDataRef.current)
    };
  }, []);

  useEffect(() => {
    //let saveInterval = setInterval(() => {
    //    VideoAPIService.saveUserYouTubeData(state);
    //}, 5000);
  }, [videoId]);

  const getTranslation = async (text) => {
    let response = await axios({
      method: 'post',
      url: `${url}/translate/text`,
      headers: { 'X-Auth-Token': cookies.get('token') },
      data: { text: text },
    });
    if (response.data.success) {
      setState((state) => {
        return {
          ...state,
          translation: response.data.translation.translations[0].translation,
          translateText: '',
          showPopup: true,
        };
      });
      let translation = await axios({
        method: 'post',
        url: `${url}/create/dictWord`,
        headers: { 'X-Auth-Token': cookies.get('token') },
        data: { word: text, translation: response.data.translation.translations[0].translation },
      });
      setState((state) => {
        return { ...state, translation: translation.data.newDictWord.translation };
      });
    } else {
      //very confusing
      setState((state) => {
        return { ...state, translateText: '' };
      });
    }
  };
  const clickedWord = (e) => {
    console.log(e.target.id);

    let spanish = e.target.id;
    axios({
      method: 'post',
      url: `${url}/get/many/dictWord/spanish`,
      data: { spanish: [spanish.trim()] },
      headers: { 'X-Auth-Token': cookies.get('token') },
    })
      .then((response) => {
        if (response.data.dictWords.length > 0) {
          let { clickedDictWords } = state;
          console.log(response.data);

          let userDictWord = Text.getUserDict(dictWords, spanish, response, userDictWords);

          userDictWord = Text.addCounter(userDictWord);
          clickedDictWords = Text.addToList(clickedDictWords, userDictWord);
          let clickedWord = response.data.dictWords[0]; // ? response.data.dictWords[0] : state.clickedWord;
          setState((state) => {
            return { ...state, clickedWord, clickedDictWords };
          });
          VideoAPIService.saveDictWordAPI(userDictWord);
        } else {
          //new dictWord
          getTranslation(spanish);
        }
      })
      .catch((e) => {
        console.log('err', e);
      });
  };
  const handleDictWordClick = (e) => {
    let c = state.clickedDictWords.find((c) => c.word == e.target.id);
    let clickedWord = c ? c : state.clickedWord;
    setState((state) => {
      return { ...state, modal: true, clickedWord };
    });
  };

  const calcHeight = (height) => {
    return screenWidth > 1000 ? height : 800;
  };

  let { toggleFullScreen } = state;
  const opts = {
    height: toggleFullScreen ? window.screen.height - 150 : 390, //'390',
    width: toggleFullScreen ? (screenWidth < 1000 ? screenWidth - 100 : screenWidth) : 800, // '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  let sub = currentSub;
  //if(!currentSubIndex) currentSubIndex = 0
  if (subtitles && dictWords && currentSubIndex) {
    sub = subtitles[currentSubIndex];
    sub = TextService.matchSubToDict(sub, dictWords);
  }
  return (
    <div className="" style={{}}>
      <UserWordSingle
        id={'abc'}
        dictWord={state.clickedWord}
        modal={state.modal}
        disableModal={UserWordSingle.disableModal}
      />
      <div
        style={{
          display: state.toggleFullScreen ? 'flex' : '',
          flexDirection: screenWidth > 1000 ? 'row' : 'column',
          justifyContent: screenWidth > 1000 ? 'space-between' : 'flex-start',
          alignItems: 'center',
        }}
      >
        <div
          className="white"
          style={{
            padding: 10,
            position: 'absolute',
            display: state.showMoreVideos ? 'flex' : 'none',
            order: screenWidth > 1000 ? 0 : 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* <p>paste in any youtube video link</p>
                    <input placeholder={state.videoUrl} style={{fontSize: 10,width: 180, border: '2px solid red'}} onChange={(e) => {
                            let input = e.target.value
                            e.persist()
                            setState((state) => {
                                return {...state, userInput: e.target.value}
                            })} 
                        }
                    value={state.userInput} /> */}
          {/* <button
            className="btn blue"
            style={{ width: 180 }}
            onClick={() => {
              let videoId = TextService.getIdFromTYURL(state.userInput);
              setVideoId(videoId);
              setVideoUrl(state.userInput);
            }}
          >
            find
          </button> */}
          <ul className="collection" style={{ width: 200, height: 600, overflowY: 'scroll' }}>
            {idList.map((id, i) => {
              let thumbnailUrl = id; //this.getIdFromTYURL(url);
              thumbnailUrl = TextService.getThumbnailUrl(thumbnailUrl);
              return (
                <img
                  alt="click to play"
                  key={id + i}
                  src={thumbnailUrl}
                  width={200}
                  id={id}
                  onClick={(e) => {
                    let videoId = e.target.id; //this.getIdFromTYURL(e.target.id)
                    setVideoId(videoId);
                  }}
                />
              );
            })}
          </ul>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: calcHeight(800) }}>
          {errorMessage && <p>{errorMessage}</p>}
          <div style={{ display: 'flex', justifyContent: 'space-around', width: '50%' }}>
            <button
              className="btn blue"
              onClick={() =>
                setState((state) => {
                  return { ...state, showMoreVideos: !state.showMoreVideos };
                })
              }
            >
              more videos
            </button>
            <button
              className="btn blue"
              onClick={() =>
                setState((state) => {
                  return { ...state, toggleFullScreen: !state.toggleFullScreen };
                })
              }
            >
              full screen
            </button>
            <button
              className="btn blue"
              onClick={() =>
                setState((state) => {
                  return { ...state, showDictWords: !state.showDictWords };
                })
              }
            >
              words
            </button>
          </div>
          <h5>move your mouse inside the black area to pause / out to resume</h5>
          <YouTube
            opts={opts}
            videoId={videoId}
            //videoId={this.getIdFromTYURL(state.videoUrl)}
            onReady={(event) => {
              event.target.pauseVideo();
              setPlayer(event.target);
            }}
          />
          {sub && sub.words && (
            <div
              style={{
                display: 'column',
                bottom: -120,
                position: state.toggleFullScreen ? 'absolute' : '',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  //bottom: 0
                }}
                onMouseLeave={() => {
                  if (!state.isAdvancedMode) {
                    return;
                  }
                  pauseVideo(player, setState);
                }}
                onMouseEnter={() => {
                  if (!state.isAdvancedMode) {
                    return;
                  }
                  playVideo(player, setState);
                }}
              >
                <div
                  id="subs"
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'middle',
                    flexWrap: 'wrap',
                    width: screenWidth < 1000 ? screenWidth - 100 : 800,
                  }}
                >
                  {sub &&
                    sub.words.map((w, i) => {
                      return (
                        <span
                          key={i}
                          //className="white btn small"
                          //style={{color: !w.dictWord ? 'black' : w.dictWord.match ? 'blue' : 'red', backgroundColor: 'green', margin: 5}}
                          style={{
                            color: !w.dictWord ? 'black' : w.dictWord.match ? 'teal' : 'black',
                            backgroundColor: 'white',
                            margin: 5,
                            padding: 8,
                            fontSize: screenWidth > 1200 ? 22 : 14,
                          }}
                          id={w.lowerCaseWord}
                          onClick={(e) => clickedWord(e)}
                          //onMouseMove={(e) => setState({ x: e.screenX, y: e.screenY, }) }
                        >
                          {w.word + ' '}
                        </span>
                      );
                    })}
                </div>
              </div>
              <div
                style={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  width: '100%',
                  height: 100,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onMouseLeave={() => {
                  if (!state.isAdvancedMode) {
                    return;
                  }
                  playVideo(player, setState);

                  console.log(player);
                }}
                onMouseEnter={() => {
                  if (!state.isAdvancedMode) {
                    return;
                  }
                  pauseVideo(player, setState);
                }}
              >
                <h2>
                  {state.clickedWord.translation} - {state.clickedWord.word}
                </h2>
              </div>
            </div>
          )}

          <div style={{ height: 100, width: 1000 }}>
            <PlayerControlls
              screenWidth={screenWidth}
              player={state.player}
              togglePlayback={() => {
                if (state.isPlaying) {
                  pauseVideo(player, setState);
                } else {
                  playVideo(player, setState);
                }
              }}
              toggleAdvancedControllsMode={() => {
                setState((state) => {
                  return { ...state, isAdvancedMode: !state.isAdvancedMode };
                });
              }}
              isPlaying={state.isPlaying}
              isAdvancedMode={state.isAdvancedMode}
              moveBack={() => {
                currentSubIndex = TextService.moveBack(currentSubIndex, subtitles, player);
                setCurrentSubIndex(currentSubIndex);
              }}
              moveForward={() => {
                currentSubIndex = TextService.moveForward(currentSubIndex, subtitles, player);
                setCurrentSubIndex(currentSubIndex);
              }}
              subtitleMode={state.subtitleMode}
              toggleSubtitleMode={() => {
                setState((state) => {
                  return { ...state, subtitleMode: !state.subtitleMode };
                });
              }}
            />
          </div>
        </div>
        <div
          className="white"
          style={{
            top: '10%',
            right: 0,
            width: 250,
            order: 3,
            display: state.showDictWords ? 'flex' : 'none',
            height: 800,
            overflowY: state.clickedDictWords.length > 3 ? 'scroll' : '',
            padding: 10,
            position: 'absolute',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ClickedDictWordsList
            clickedDictWords={state.clickedDictWords}
            handleDictWordClick={handleDictWordClick}
            showDictWords={state.showDictWords}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    store: state.vocabReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    test: () => {
      dispatch({
        type: 'TEST',
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoWatcher);

function pauseVideo(player, setState) {
  if (player) {
    player.pauseVideo();
    setState((state) => {
      return {
        ...state,
        isPlaying: false,
      };
    });
  }
}

function playVideo(player, setState) {
  if (player) {
    player.playVideo();
    setState((state) => {
      return {
        ...state,
        isPlaying: true,
      };
    });
  }
}
