export default class Text {
  static getUserDict(dictWords, spanish, response, userDictWords) {
    console.log('check params', dictWords, spanish, response, userDictWords);
    userDictWords = userDictWords.filter((u) => u.match);
    dictWords = dictWords.filter((u) => u.match);
    let userDictWord = userDictWords.find((u) => spanish === u.word);
    if (!userDictWord) userDictWord = dictWords.find((u) => spanish === u.word);
    if (!userDictWord) userDictWord = response.data.dictWords[0];
    return userDictWord;
  }
  static addCounter(userDictWord) {
    if (userDictWord.clickedCounter) {
      userDictWord.clickedCounter++;
      userDictWord.clickedCounterList.push({ clickedCounter: userDictWord.clickedCounter, date: new Date() });
    } else {
      userDictWord.clickedCounter = 1;
      userDictWord.clickedCounterList = [{ clickedCounter: 1, date: new Date() }];
    }
    return userDictWord;
  }
  static addToList(clickedDictWords, userDictWord) {
    if (!clickedDictWords.some((c) => c.word == userDictWord.word)) clickedDictWords.unshift(userDictWord);
    else {
      clickedDictWords = clickedDictWords.map((clickedWord) => {
        if (clickedWord.word === userDictWord.word) return userDictWord;
        else return clickedWord;
      });
    }
    clickedDictWords = clickedDictWords.sort((a, b) => b.clickedCounter - a.clickedCounter);
    return clickedDictWords;
  }
  static cleanString(string) {
    string = string.replace(' ', '');
    string = string.replace('!', '');
    string = string.replace('.', '');
    string = string.replace(',', '');
    string = string.replace('?', '');
    string = string.replace(':', '');
    string = string.replace('¿', '');
    string = string.replace('¡', '');
    string = string.replace('”', '');
    string = string.replace('“', '');
    string = string.replace('…', '');
    string = string.replace('-', '');
    string = string.replace('–', '');
    string = string.replace('!', '');
    string = string.replace('(', '');
    string = string.replace(')', '');
    string = string.replace('[', '');
    string = string.replace(']', '');
    string = string.toLowerCase();
    string = string.trim();

    return string;
  }
}
