import React, { Component } from 'react';
import { Bubble, Line,Scatter, defaults} from 'react-chartjs-2';

defaults.global.animation = false;

class ScatterPlot extends Component {
    constructor(props){
        super(props);
            this.state ={
               
                chartData: {
                  labels: ['Boston', 'Worcester', 'Springfield', 'Lowell', 'Cambridge', 'New Bedford'],
                      datasets: [{
                          label: "cities",
                          data: [2,3,3,2,3,1]
                      }]
                },
                  options: {
                    maintainAspectRatio: false,
                    tooltips: {
                       callbacks: {
                          label: function(tooltipItem, data) {
                              //console.log(tooltipItem)
                             var label = data.labels[tooltipItem.index];
                             var id = data.ids && data.ids[tooltipItem.index] || "";
                              //console.log(id)
                              data.getId && data.getId(id)
                             return label + ': (' + tooltipItem.xLabel + ', ' + tooltipItem.yLabel + ')';
                          }
                       }
                    }
                  }  
            }
        }
        componentWillReceiveProps(nextProps){

             ////console.log(nextProps)
             ////console.log(nextProps)
            this.setState({chartData: nextProps});
             ////console.log("!!! this.state")
             ////console.log(nextProps)
        }
        componentDidMount(){
        
            // this.dataFunc()
        }
        // dataFunc(){
        //     //  ////console.log("this.state check if all ok")
        //     // this.setState(this.props.data)
        // }
        // componentWillReceiveProps(nextProps){
        //      ////console.log("nextProps.data")
        //      ////console.log(nextProps)
        //     this.setState({chartData: nextProps});
        //      ////console.log(this.state)
        // }
    
  render() {
    return (
        <div className="chart">
        
         <Bubble data={this.props.data} options={{...this.state.options, ...this.props.options}} 
                height={this.props.height}
                width={this.props.width}
             />
        </div>

    );
  }
}
function CheckData(props) {
     ////console.log(props)
      const data = props.data;
      if (data) {
         return  (
        <Scatter data={props.chartData} options={{
            maintainAspectRatio: false
                }} 
                height={500}
                width={250}
             />
             )
  } 
  else {
    return  (
        <Scatter data={props.chartData} options={{
            maintainAspectRatio: false
                }} 
                height={250}
                width={250}
             />
             )
  }
  
}
ScatterPlot.defaultProps = {
  height : 250,
  width: 250
}

export default ScatterPlot;