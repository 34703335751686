import React from 'react';
import { Link } from 'react-router-dom';

import { style } from '../../config/styleConfig';
import profilePic from './profilePic.jpg';
import showCaseGif from './completeLong.gif';
import insightsMode from './insightsMode.gif';
import lessonInfo from './lessonInfo.gif';
import lessonMode from './lessonMode.gif';
import practiceModeMobile from './practiceModeMobile.jpg';
import readerMode from './readerMode.gif';
import readerMode2 from './readerMode.PNG';
import readerModeMobile from './readerModeMobile.jpg';
import sentences from './sentences.gif';
import textMode from './textMode.gif';
import userInfo from './userInfo.gif';
import videoMode from './videoMode.gif';
import vocab from './vocab.gif';
import wordInfo from './wordInfo.gif';

const imgStyle = {
  backgroundColor: 'white',
  display: 'inline-block',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  alignSelf: 'center',
  margin: 4,
  marginTop: 64,
  maxHeight: 500,
};

const textStylePic = { alignSelf: 'center', maxWidth: 800 };

export default function About() {
  return (
    <div className="" style={{ minHeight: '100vh', fontSize: '14pt' }}>
      <div className="row white" style={{ ...style, display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '', marginRight: 16 }}>
          {/* <h4>Goal of this App:</h4>
          <p>
            <a href="https://en.wikipedia.org/wiki/Input_hypothesis">Input hypothesis</a> - Learn with input
          </p> */}
          {/* <p >
            To somewhat understand text and speech, recall of many words is needed. Otherwise you need to look up their
            translations. But if you dont know any words because you are starting out, its kind of impossible to read or
            listen. So you need to quickly learn a critical mass of words before you can really start understanding
            Spnaish. And then, after you got to this point, it really helps if you can efficiently learn new words that
            you encounter when watching a video or reading an article.
          </p> */}
          <h1 style={{ fontSize: 36 }}>What this is:</h1>
          <p style={{ fontSize: 24 }}>
            This is a passion project of mine. 5 years ago I started learning programming by building this app and I
            have been working on it ever since. Back then, the aim was totally different so it has gone through many
            iterations. Down below you can find gifs of a lot of the features that I built.
          </p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '' }}>
          <img
            style={{
              backgroundColor: 'white',
              display: 'inline-block',
              width: 'auto',
              height: 150,
              //borderRadius: '50%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              alignSelf: 'center',
              marginRight: 10,
            }}
            src={profilePic}
            alt="some alt text"
          ></img>
          <div style={{ padding: 16, fontSize: 16 }}>
            <p>Creator: Michael Baumgarten</p>
            <p>Want to contact me?</p>
            <p>Michael.baumgarn@gmail.com</p>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 32 }}>
              <Link to="/TOS">Terms of service</Link>
              <Link to="/Privacy">Privacy Policy</Link>
              <Link to="/PrivacyMobileAndroid">Privacy Policy android app</Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row white"
        style={{ ...style, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        <img style={imgStyle} src={lessonInfo} alt="some alt text"></img>
        <p style={textStylePic}>Quick overview, scroll way down for more info.</p>
        <img style={imgStyle} src={insightsMode} alt="some alt text"></img>
        {/* <img style={imgStyle} src={userInfo} alt="some alt text"></img> */}
        <p style={textStylePic}>
          The above feature shows the app's extensive ability to explore learning data. it can identify words that are
          quickly learned and more importantly those that are difficult. This really helped me to explore my learning
          data, and based on that I fine-tuned the learning algorithm. Eg. there are certain words that for some reason
          are learned near instantly, and now the algo covers that case (it doesn't keep asking you a word if you got it
          right unusually quickly). Conversly there are words that for some reason I can't get right but it's annoying
          that the app keeps pestering me with them. So after some point words get put on the backburner. In future work
          it would be cool to group words by filtering them down and then create vocab lists. Eg. learn all the
          difficult backburner words.
        </p>
        <img style={imgStyle} src={textMode} alt="some alt text"></img>
        <img style={imgStyle} src={readerMode2} alt="some alt text"></img>
        <p style={textStylePic}>
          Read an article and the words get matched with the internal vocab corpus. It's then possible to learn all the
          article words as a vocab deck and have the corresponding stats. This also makes it possible to filter for
          articles that contain optimal words based on the user's learning state. Eg. find articles that contain only
          10% new words.
        </p>
        <img style={imgStyle} src={videoMode} alt="some alt text"></img>
        <p style={textStylePic}>
          Same attempt but with video which I thought was cooler. After I build this youtube changed their auth API so
          dealing with that became even more of a nightmare.
        </p>
        <img style={imgStyle} src={vocab} alt="some alt text"></img>
        <p style={textStylePic}>
          Vocab training is the heart of the app. I have spent a lot of time tweaking the learning algorithm, eg. should
          you learn a new word, practice a fresh word or review an old one (called spaced repetition system, srs). Which
          side, eg. english or spanish should be learned first? Should one side of a flash card be treated as new if the
          user just learned the other side? If the user learned the base form of a verb, how well does the user know the
          conjugations? What about the other way around? Making wrong assumption about the answers to these questions
          causes the learning algo to become frustrating.
        </p>
        <img style={imgStyle} src={sentences} alt="some alt text"></img>
        <p style={textStylePic}>
          My favorite experiment, generating sentences on the fly based on the user's learning needs. Eg. combining
          review with a new word. A difficult engineering challenge was to deal with matching words when checking the
          user's answer, as both sentances sometimes dont have the same length. People using the app gave me lots of
          feedback regarding small grammatical mistakes, but as a language learner I cared more about the grammar
          pattern rather than minor mistakes. Getting all grammer edge case right also is one of those 80% effort for
          10% reward things.
        </p>
        <img style={imgStyle} src={lessonMode} alt="some alt text"></img>
        <p style={textStylePic}>
          Mixing both vocab and conjugations. It was too annoying to fine tune when to do what. So now it's a toggle to
          enable one or the other. Overall, practicing conjugations via this flashcard style is really nice and
          effective and I'm very glad I implemented it. The biggest challenge is adjusting learning weight. Eg. should
          spanish or english (ir vs. go) be learned first? After learning one of them, the other side becomes easy
          somehow so treating the other language as new is also wrong, it becomes annoying.
        </p>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <img style={imgStyle} src={practiceModeMobile} alt="some alt text"></img>
          <img style={imgStyle} src={readerModeMobile} alt="some alt text"></img>
        </div>
        <p style={textStylePic}>
          Building an app for the sake of building an app was kind of cool. Maybe I will do it again. Tapping on words
          as opposed to writing them is also nice. If I had more time with it I would add a countdown feature.
        </p>
        <img style={imgStyle} src={showCaseGif} alt="some alt text"></img>
        <p style={textStylePic}>Long overview. Not bored yet? You can watch this 2 minute long slow gif.</p>
      </div>
    </div>
  );
}
