import React, { useState, useEffect } from 'react';
import axios from 'axios'
import url from '../config/config';


import Cookies from 'universal-cookie';
const cookies = new Cookies();


const ChangePW = (props) => {

    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [message, setMessage] = useState('');

    const ChangePW = () => {
        setMessage('...')
        let token, verifyCode;
        if (props.props.match.params.verifyCode) {
            token = null;
            verifyCode = props.props.match.params.verifyCode
        } else {
            token = cookies.get("token");
            verifyCode = null
        }
        if(newPassword === newPassword2){
            axios({
                method: 'post',
                url: `${url}/changePassword`,
                headers: { 'X-Auth-Token': cookies.get('token') },
    
                data: {
                    newPassword: newPassword,
                    verifyCode: verifyCode
                }
            }).then(response => {
                setMessage(response.data.message)
            })

        } else {
            setMessage("passwords dont match")
        }
    }

    return (
        <div >
            <form>
                <br />
                <br />
                <p>{message}</p>
                <br />
                <input   type="password" style={{ color: 'black', fontSize: '16pt' }} name="newPassword" placeholder="your new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)} />
                <input   type="password" style={{ color: 'black', fontSize: '16pt' }} name="newPassword2" placeholder="repeat"
                    value={newPassword2}
                    onChange={(e) => setNewPassword2(e.target.value)} />
                <a type="button" className="btn pink" onClick={ChangePW}>ChangePW</a>

            </form>
        </div>


    );


}


export default ChangePW;
