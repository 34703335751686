import axios from 'axios';
import url from '../config/config';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const randDecision = (userInfo) => {
  if (!userInfo) return Math.random() < 0.5;
  if (userInfo) return Math.random() * 100 < userInfo.userSettings.englishFrequency;
};

export async function getTTSTokenAPI() {
  let response = await axios({
    method: 'get',
    url: `${url}/ttsToken`,
    headers: {
      'X-Auth-Token': cookies.get('token'),
    },
  });
  this.setState({
    ttsToken: response.data.token,
  });
}
export async function EndlessGetFirstWord(props) {
  let dec = randDecision(this.state.userInfo);
  try {
    let response = await axios({
      method: 'get',
      url: `${url}/vocab`,
      headers: {
        'X-Auth-Token': cookies.get('token'),
      },
    });
    let { question, learnLanguage, displayLanguage, testLanguage, selectedLearnData } = response.data;

    let currentLearnData = question.currentLearnData ? question.currentLearnData : false;
    this.setState({
      selectedLearnData,
      question,
      learnLanguage,
      displayLanguage,
      testLanguage,
      currentLearnData,
    });
  } catch (e) {
    console.log(e, 'getting vocab failed');
    props.history.push({
      pathname: '/Login',
    });
  }
}

const postToEndless = async (state, totalTime) => {
  const {
    input: answer,
    question,
    sentence,
    sentenceMetaData,
    displayLanguage,
    testLanguage,
    conjugationMode,
    selectedLearnData,
    srsMode,
  } = state;
  let response = await axios({
    method: 'post',
    url: `${url}/vocab/endless`,
    headers: {
      'X-Auth-Token': cookies.get('token'),
    },

    data: {
      // sendNextQuestion: true,
      answer,
      question,
      sentence,
      sentenceMetaData,
      // index: 0,
      displayLanguage,
      testLanguage,
      totalTime,
      // answerMode: true,
      // test: 0,
      conjugationMode,
      selectedLearnData,
      srsMode,
    },
  });
  return response;
};
const sendDataScoresMapper = ({ scores, response }) => {
  if (scores) {
    scores = scores.map((score) => {
      //if(score.errorScore !== undefined) return score.errorScore
      return score.score;
    });

    let errorRates = response.data.sendData.scoredSentenceWords
      .map((word) => {
        if (word.errorRate) return word.errorRate;
      })
      .filter((a) => a);
    return { scores, errorRates };
  } else {
    let errorRate = response.data.sendData.newUserLearnData.errorRate;
    let errorScore = response.data.sendData.newUserLearnData.errorScore;
    return {
      scores: false,
      errorRate,
      errorScore,
    };
  }
};

export async function EndlessSendAnswer(totalTime) {
  this.setState({
    allowEnter: false,
  });
  const response = await postToEndless(this.state, totalTime);
  //console.log(response.data)
  let { sentences, sendData } = response.data;
  let scores = response.data.sendData.scores;
  let { scoredSentenceWords } = sendData;
  let sentenceMainWordLearnData = false;
  if (scoredSentenceWords) {
    let sentenceHeadWord = scoredSentenceWords.find(
      (sentenceHeadWord) => sentenceHeadWord.english === this.state.question.english
    );
    sentenceMainWordLearnData = sentenceHeadWord && sentenceHeadWord.currentLearnData;
  }

  // if(response.data.sentenceMetaData){
  //   sentenceMainWordLearnData = response.data.sentenceMetaData.chosenLearnData
  // }

  const sendDataScoresMapperResults = sendDataScoresMapper({ scores, response });
  this.setState({
    ...sendDataScoresMapperResults,
  });

  let sentenceV2 = response.data.sentenceV2;
  let sentence = false;
  if (sentenceV2 && sentenceV2.length > 0) sentence = sentenceV2;
  let sentenceMetaData = response.data.sentenceMetaData;

  let question = this.state.question;
  question.currentLearnData = response.data.sendData.newUserLearnData;
  let currentLearnScoreChange;
  if (response.data.sendData.newUserLearnData) {
    currentLearnScoreChange = response.data.sendData.newUserLearnData.change;
  } else {
    currentLearnScoreChange = response.data.sendData.scoredSentenceWords.reduce((a, b) => {
      if (b.currentLearnData) return a + b.currentLearnData && b.currentLearnData.change;
      else return 0;
    }, 0);
  }
  let performanceData = this.state.performanceData;
  let { totalLearnScoreChange, totalEnergy, learnScoreChangeArray, totalEnergyChangeArray } = performanceData;
  let energy = response.data.sendData.userAnswer.length;
  let userWordsLength = response.data.learnMetaData.userWordsLength;
  let headWordsLength = response.data.learnMetaData.headWordsLength;
  totalEnergy = totalEnergy + energy;
  let learnScoreChange = currentLearnScoreChange;
  totalLearnScoreChange = totalLearnScoreChange + currentLearnScoreChange;
  learnScoreChangeArray.push(currentLearnScoreChange);
  totalEnergyChangeArray.push(response.data.sendData.userAnswer.length);
  performanceData = {
    userWordsLength,
    headWordsLength,
    totalEnergyChangeArray,
    learnScoreChangeArray,
    totalEnergy,
    learnScoreChange,
    totalLearnScoreChange,
    srsLength: response.data.srsLength,
  };

  let currentLearnData = question.currentLearnData ? question.currentLearnData : sentenceMainWordLearnData;

  // test if it works
  if (!currentLearnData) {
    currentLearnData = sentenceMetaData.chosenLearnData;
  }
  // if(response.data.sentenceMetaData.sentence){
  //   currentLearnData = response.data.sentenceMetaData.chosenLearnData
  // }
  this.setState({
    performanceData,
    currentLearnData,
    question,
    nextSentenceMetaData: sentenceMetaData,
    nextSentences: sentence,
    errorScore: response.data.sendData.errorScore || response.data.sendData.errorSum,
    nextQuestion: response.data.question,
    learnLanguage: response.data.learnLanguage,
    nextTestLanguage: response.data.testLanguage,
    nextDisplayLanguage: response.data.displayLanguage,
    nextSelectedLearnData: response.data.selectedLearnData,
    //nextDisplayLanguage: dec ? this.props.store.baseLanguage : this.props.store.learnLanguage,
    //nextTestLanguage: dec ? this.props.store.learnLanguage : this.props.store.baseLanguage,
    allowEnter: true,
  });
}
export async function getUserInfo() {
  let response = await axios({
    method: 'get',
    url: `${url}/userSettings`,
    headers: {
      'X-Auth-Token': cookies.get('token'),
    },
  });
  if (response.data.success == false) return false;
  // //console.log(response.data.userInfo.learnLanguage)
  this.setState({
    userInfo: response.data.userInfo,
    // lessonLength: this.state.questions.length * response.data.userInfo.userSettings.lessonMultiplier,
  });
}
