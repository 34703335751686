import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { style } from '../config/styleConfig.js';

import { connect } from 'react-redux'; // /lib/connect / connect
import url from '../config/config';

import Cookies from 'universal-cookie';
import { getUserInfo, loginAPI, autoLogin, anonSignUp } from './authentication.js';
const cookies = new Cookies();

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      password: '',
      loginData: false,
    };
    this.loginAPI = loginAPI.bind(this);
    this.autoLogin = autoLogin.bind(this);
    this.anonSignUp = anonSignUp.bind(this);
  }

  changeLoginStatus() {
    let newStatus = this.state.loginStatus ? false : true;
    this.setState({ loginStatus: newStatus });
  }
  componentWillMount() {
    let pass = cookies.get('token') ? true : false;
    if (pass) this.autoLogin(this.props);
    else this.anonSignUp(this.props);
    this.setState({ message: '' });
  }

  getAuthenticationKeyPress(e) {
    if (e.key == 'Enter') this.loginAPI(this.state);
  }
  getAuthenticationClick() {
    this.loginAPI(this.state);
    getUserInfo(this.props);
  }

  render() {
    return (
      <div className="container">
        <div className=" white" style={{ ...style, width: '50%', margin: 'auto' }}>
          <Link to="/SignUp" className="btn grey">
            Go to Sign Up
          </Link>
          <br />
          <br />
          <h4>Login</h4>
          <p>Please delete all cookies and reload if the app is not working.</p>

          <br />
          <p>{this.props.store.message}</p>
          <br />
          <input
            type="text"
            name="login"
            placeholder="user name"
            value={this.userName}
            onChange={(e) => this.setState({ userName: e.target.value })}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={this.password}
            onKeyPress={this.getAuthenticationKeyPress.bind(this)}
            onChange={(e) => this.setState({ password: e.target.value })}
          />
          <button className="btn" onClick={this.getAuthenticationClick.bind(this)} style={{ width: '100%' }}>
            Submit
          </button>
          <Link to="/endlessMode" className="btn blue" style={{ width: '100%', marginTop: 25 }}>
            {this.state.loginData && this.state.loginData.userName && this.state.loginData.userName.length > 0
              ? 'continue'
              : 'skip'}
          </Link>
          <br />
          <br />
          <br />
          <br />
          {false && (
            <Link to="/recover" className="btn">
              Forgot Password
            </Link>
          )}
        </div>
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    ////console.log("nextProps in login")
    //  ////console.log(
    //if (nextProps.isLoggedIn && this.props.store.email !== '') {
    //    const { history } = this.props;;
    //    history.push({ pathname: '/' });
    //}
    //if (nextProps.goToLogin) {
    //    console.log('redirect to login via goToLogin')
    //    const { history } = this.props;
    //    history.push({ pathname: '/login' });
    //}
  }
}

const mapStateToProps = (state) => {
  return {
    store: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    //getAuthentication: (state) => {
    //    dispatch({
    //        type: "LOGIN_CHECK",
    //        payload: {
    //            request: {
    //                method: 'post',
    //                url: `${url}/authenticate`,
    //                headers: { 'X-Auth-Token': cookies.get('token') },
    //                data: {
    //                    password: state.password,
    //                    userName: state.userName.toLowerCase()
    //                }
    //            }
    //        }
    //    })
    //},
    sendUserInfoToReducer: (data) => {
      dispatch({
        type: 'USERINFO_GET',
        payload: data,
      });
    },
    anonSignUp: (response) => {
      dispatch({
        type: 'LOGIN_SIGNUP_ANON',
        payload: response,
      });
    },
    autoLogin: (response) => {
      dispatch({
        type: 'LOGIN_AUTO_LOGIN',
        payload: response,
      });
    },
    removeMessage: () => {
      dispatch({
        type: 'REMOVE_MESSAGE',
      });
    },
    onLogIn: (response) => {
      dispatch({
        type: 'LOGIN_CHECK',
        payload: response,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
