import React, { Component } from 'react';
import { Line,Bar,Doughnut, defaults} from 'react-chartjs-2';

defaults.global.animation = false;

class LineChart extends Component {
    constructor(props){
        super(props);
            this.state ={
               
                chartData: {
                labels: ['a', 'b', 'c', 'd', 'e', 'f'],
                    datasets: [{
                        label: "abc",
                        data: [2,3,3,2,3,1]
                    }]
                }
            }
        }
        componentWillReceiveProps(nextProps){

            this.setState({chartData: nextProps});
        }
        componentDidMount(){
        
            // this.dataFunc()
        }
        // dataFunc(){
        //     //  ////console.log("this.state check if all ok")
        //     // this.setState(this.props.data)
        // }
        // componentWillReceiveProps(nextProps){
        //      ////console.log("nextProps.data")
        //      ////console.log(nextProps)
        //     this.setState({chartData: nextProps});
        //      ////console.log(this.state)
        // }
    
  render() {
    return (
        <div className="chart">
        
         <Line data={this.props.data} 
            options={{
              legend: {
                display: true,
                  position: 'top',
                  labels: {
                    usePointStyle: true,
                  }
                },
                elements: {
                  line: {
                    tension: 0
                  }
                },
                maintainAspectRatio: false,
                scales: {
                  xAxes: [{
                    ticks: {
                      fontSize: 18
                    }
                  }],
                  yAxes: [{
                    ticks: {
                      fontSize: 18,
                      beginAtZero: true
                    }
                  }]
                }
              }}
                height={this.props.height}
                width={this.props.width}
             />
        </div>

    );
  }
}
function CheckData(props) {
     ////console.log(props)
      const data = props.data;
      if (data) {
         return  (
        <Bar data={props.chartData} options={{
            maintainAspectRatio: false
                }} 
                height={500}
                width={250}
             />
             )
  } 
  else {
    return  (
        <Line 
            data={props.chartData} 
            options={{
              maintainAspectRatio : false
            }} 
            height={250}
            width={250}

             />
             )
  }
  
}
LineChart.defaultProps = {
  height : 250,
  width: 250
}

export default LineChart;
