import React, { Component, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import ChangePW from './changePW';
import { style as st } from '../config/styleConfig';
import { connect } from 'react-redux'; // /lib/connect / connect
import url from '../config/config';
import Slider from '@material-ui/core';
import Dropdown from './Dropdown';
import { getUserInfo } from './authentication';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';

import Info from '../util/Info.js';
import { Button } from '@chakra-ui/button';

const cookies = new Cookies();

let style = {
  box: st,
};
let optionStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 25,
  marginTop: 50,
  borderBottom: 'solid 1px black',
  padding: 20,
};
class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetWords: false,
      resetWordsSuccess: false,
      userInfo: null,
      email: '',
      message: '',
      learnLanguage: 'spanish',
      getOut: false,
      showDelete: false,
      ignoredWords: [],
    };
  }

  componentWillMount() {
    ////console.log(this.props)
    // 25f5cece5665c6fb2f7d823091a457cda862522f
  }

  async componentDidMount() {
    const data = await getUserInfo(this.props);
    await this.getIgnoredWords();
    if (this.props.store.userInfo) this.setState({ userInfo: this.props.store.userInfo });
    console.log(this.props, data);
    let userInfo = data.userInfo;
    this.setState({ userInfo, learnLanguage: userInfo.learnLanguage });
  }
  componentWillReceiveProps(nextProps) {}
  componentWillUnmount() {}
  resetWords() {
    axios({
      method: 'delete',
      url: `${url}/delete/resetWords`,
      headers: { 'X-Auth-Token': cookies.get('token') },
    }).then((response) => {
      this.setState({ resetWordsSuccess: response.data.success });
    });
  }
  deleteUser() {
    axios({
      method: 'delete',
      url: `${url}/delete/User`,
      headers: { 'X-Auth-Token': cookies.get('token') },
    }).then((response) => {
      ////console.log(response.data)
      this.setState({ getOut: true });
      cookies.remove('token');
    });
  }
  saveUserInfo(userInfo) {
    axios({
      method: 'post',
      url: `${url}/userSettings`,
      headers: { 'X-Auth-Token': cookies.get('token') },
      data: { userInfo },
    }).then((response) => {
      //console.log(response.data)
      this.setState({ userInfo: response.data.userInfo });
      getUserInfo(this.props);
    });
  }

  getUserInfo() {
    axios({
      method: 'get',
      url: `${url}/userSettings`,
      headers: { 'X-Auth-Token': cookies.get('token') },
    }).then((response) => {
      // //console.log(response.data.userInfo.learnLanguage)
      this.setState({ userInfo: response.data.userInfo });
      getUserInfo(this.props);
      //this.props.tempUserSettings(response.data.userInfo.learnLanguage, response.data.userInfo.email)
    });
  }
  getIgnoredWords() {
    axios({
      method: 'get',
      url: `${url}/Data/ignoredWords`,
      headers: { 'X-Auth-Token': cookies.get('token') },
    }).then((response) => {
      // //console.log(response.data.userInfo.learnLanguage)
      this.setState({ ignoredWords: response.data.ignoredWords });
      //this.props.tempUserSettings(response.data.userInfo.learnLanguage, response.data.userInfo.email)
    });
  }
  updateIgnoredWords(ignoredWords) {
    axios({
      method: 'post',
      url: `${url}/Data/ignoredWords/update`,
      headers: { 'X-Auth-Token': cookies.get('token') },
      data: { ignoredWords },
    }).then((response) => {
      // //console.log(response.data.userInfo.learnLanguage)
      this.setState({ ignoredWords: response.data.ignoredWords });
      //this.props.tempUserSettings(response.data.userInfo.learnLanguage, response.data.userInfo.email)
    });
  }

  handleEmailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }

  //recover(tempId) {
  //    let data;
  //    if (tempId) {
  //        data = {
  //            verifyCode: this.props.match.params.verifyCode,
  //            token: 'dw'
  //        }
  //    } else {
  //        data = {
  //            email: this.state.email
  //        }
  //    }

  //    axios({
  //        method: 'post',
  //        url: 'http://localhost/',
  //        data: data
  //    }).then(response => {
  //        if (response.data.success) {
  //            this.setState({ message: "password changed" })
  //        }
  //        ////console.log(response.data)

  //    })
  //}
  removeIgnoredWord(e) {
    const ignoredWords = this.state.ignoredWords;
    const newIgnoredWords = ignoredWords.filter((w) => w._id !== e.target.id);
    this.setState({ ignoredWords: newIgnoredWords });
    this.updateIgnoredWords(newIgnoredWords);
  }

  selectLanguage(e) {
    //console.log(e.target.id)
    this.setState({ learnLanguage: e.target.innerText });
    let userInfo = this.state.userInfo;
    console.log('userInfo', userInfo);
    userInfo.learnLanguage = e.target.innerText;
    // this.setState({ userInfo });
    this.saveUserInfo(userInfo);
  }
  customChangeValue(e) {
    let userInfo = this.state.userInfo;
    userInfo.userSettings.grammar = e.target.innerText;
    this.setState({ userInfo });
    this.saveUserInfo(userInfo);
    setTimeout(() => {}, 100);
  }
  render() {
    let email = this.props.store.email;
    let length = email ? email.length : 0;

    const { classes } = this.props;
    let showSentences;
    let learnRate;
    let englishFrequency;
    let grammar;
    if (this.state.userInfo) showSentences = this.state.userInfo.userSettings.showSentences;
    if (this.state.userInfo) learnRate = this.state.userInfo.userSettings.learnRate;
    if (this.state.userInfo) englishFrequency = this.state.userInfo.userSettings.englishFrequency;

    grammar = [
      'presentIndicative',
      'preteritIndicative',
      'futureIndicative',
      'presentContinuous',
      'futureContinuous',
      'futurePerfect',
      // 'imperative',
      'presentPerfect',
      'pastPerfect',
    ];
    if (this.state.getOut) {
      return <Redirect to="/" />;
    } else {
      return (
        <div className="container" style={{ minHeight: '100vh' }}>
          <div className="white" style={style.box}>
            <button onClick={this.props.history.goBack} style={{ marginBottom: 25 }} type="button" className="btn grey">
              Back
            </button>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 250 }}>
              <p>Username:</p>
              <p>
                {' '}
                {this.state.userInfo && this.state.userInfo.userName.length > 0
                  ? this.state.userInfo.userName
                  : 'not signed in'}
              </p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 250 }}>
              <p>Email: </p>
              <p>{length > 0 ? email : 'not available'}</p>
            </div>
            <h5>Select a language</h5>
            <Dropdown
              //buttonStyle={{width: 300, fontSize: 8}}
              dropDownVal={`Language: ${this.state.learnLanguage}`}
              dropDownValues={['spanish', 'german']}
              changeValue={this.selectLanguage.bind(this)}
              stateName={'learnLanguage'}
            />
          </div>
          {this.state.userInfo && true && (
            <div className="white" style={style.box}>
              <br />
              <button className="btn teal" onClick={this.saveUserSettings}>
                {' '}
                Save Settings{' '}
              </button>
              <div style={optionStyle}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Info
                    message={'Spanish vocab questions will be spoken out loud'}
                    style={{ alignSelf: 'center', marginRight: 15 }}
                  />
                  <p style={{ alignSelf: 'center' }}>Allow sound:</p>
                </div>
                <button
                  className={`btn ${this.state.userInfo.userSettings.allowTTS ? 'blue' : 'grey'}`}
                  onClick={() => {
                    let userInfo = this.state.userInfo;
                    userInfo.userSettings.allowTTS = userInfo.userSettings.allowTTS ? false : true;
                    this.setState({ userInfo });
                    this.saveUserInfo(userInfo);
                  }}
                  style={{}}
                >
                  {this.state.userInfo.userSettings.allowTTS ? 'active' : 'disabled'}
                </button>
              </div>
              <div style={optionStyle}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Info
                    message={
                      'Will help with learning significantly faster, but sentences can contain grammatical or semantic errors'
                    }
                    style={{ alignSelf: 'center', marginRight: 15 }}
                  />
                  <p style={{ alignSelf: 'center' }}>Allow experimental sentences:</p>
                </div>
                <button
                  className={`btn ${this.state.userInfo.userSettings.experimentalSentences ? 'blue' : 'grey'}`}
                  onClick={() => {
                    let userInfo = this.state.userInfo;
                    userInfo.userSettings.experimentalSentences = userInfo.userSettings.experimentalSentences
                      ? false
                      : true;
                    this.setState({ userInfo });
                    this.saveUserInfo(userInfo);
                  }}
                  style={{}}
                >
                  {this.state.userInfo.userSettings.experimentalSentences ? 'active' : 'disabled'}
                </button>
              </div>
              <div style={optionStyle}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Info
                    message={'Random types of sentences, or none at all. The exact type can be specified later.'}
                    style={{ alignSelf: 'center', marginRight: 15 }}
                  />
                  <p style={{ alignSelf: 'center' }}>Sentence Type:</p>
                </div>
                <Dropdown
                  style={{ width: 600 }}
                  dropDownVal={`${this.state.userInfo.userSettings.grammar}`}
                  dropDownValues={grammar}
                  changeValue={this.customChangeValue.bind(this)}
                  stateName={'userInfo'}
                />
              </div>
            </div>
          )}
          {false && this.state.userInfo && (
            <div className="white" style={style.box}>
              <h5>Study Settings</h5>
              <div style={optionStyle}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Info
                    message={'shows your learning data in detail'}
                    style={{ alignSelf: 'center', marginRight: 15 }}
                  />
                  <p style={{ alignSelf: 'center' }}>Show learn data tables: </p>
                </div>
                <button
                  className={`btn ${this.state.userInfo.userSettings.showTables ? 'blue' : 'grey'}`}
                  onClick={() => {
                    let userInfo = this.state.userInfo;
                    userInfo.userSettings.showTables = userInfo.userSettings.showTables ? false : true;
                    this.setState({ userInfo });
                    this.saveUserInfo(userInfo);
                  }}
                  style={{}}
                >
                  {this.state.userInfo.userSettings.showTables ? 'active' : 'disabled'}
                </button>
              </div>
              <div style={optionStyle}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Info
                    message={
                      'Will help with learning significantly faster, but sentences can contain grammatical or semantic errors'
                    }
                    style={{ alignSelf: 'center', marginRight: 15 }}
                  />
                  <p style={{ alignSelf: 'center' }}>Allow experimental sentences:</p>
                </div>
                <button
                  className={`btn ${this.state.userInfo.userSettings.experimentalSentences ? 'blue' : 'grey'}`}
                  onClick={() => {
                    let userInfo = this.state.userInfo;
                    userInfo.userSettings.experimentalSentences = userInfo.userSettings.experimentalSentences
                      ? false
                      : true;
                    this.setState({ userInfo });
                    this.saveUserInfo(userInfo);
                  }}
                  style={{}}
                >
                  {this.state.userInfo.userSettings.experimentalSentences ? 'active' : 'disabled'}
                </button>
              </div>
              <div style={optionStyle}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Info
                    message={'Frequency of asking in english for a spanish answer, 50% is even'}
                    style={{ alignSelf: 'center', marginRight: 15 }}
                  />
                  <p style={{ alignSelf: 'center' }}>
                    English Frequency: {Math.floor(this.state.userInfo.userSettings.englishFrequency)}%
                  </p>
                </div>
                <Slider
                  //classes={{ container: classes.slider }}
                  value={englishFrequency}
                  aria-labelledby="label"
                  onChange={(e, v) => {
                    let userInfo = this.state.userInfo;
                    userInfo.userSettings.englishFrequency = v;
                    this.setState({ userInfo });
                  }}
                  onMouseDownCapture={() => {
                    setTimeout(() => {
                      let userInfo = this.state.userInfo;
                      this.saveUserInfo(userInfo);
                    }, 3000);
                  }}
                  style={{ width: '50%', alignSelf: 'center' }}
                />
              </div>
              <div style={optionStyle}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Info
                    message={'Frequency of sentence if one is available'}
                    style={{ alignSelf: 'center', marginRight: 15 }}
                  />
                  <p style={{ alignSelf: 'center' }}>
                    Show sentences: {Math.floor(this.state.userInfo.userSettings.showSentences)}%
                  </p>
                </div>
                <Slider
                  //classes={{ container: classes.slider }}
                  value={showSentences}
                  aria-labelledby="label"
                  onChange={(e, v) => {
                    let userInfo = this.state.userInfo;
                    userInfo.userSettings.showSentences = v;
                    this.setState({ userInfo });
                  }}
                  onMouseDownCapture={() => {
                    setTimeout(() => {
                      let userInfo = this.state.userInfo;
                      this.saveUserInfo(userInfo);
                    }, 3000);
                  }}
                  style={{ width: '50%', alignSelf: 'center' }}
                />
              </div>

              <div style={optionStyle}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Info
                    message={'Random types of sentences, or none at all. The exact type can be specified later.'}
                    style={{ alignSelf: 'center', marginRight: 15 }}
                  />
                  <p style={{ alignSelf: 'center' }}>Sentence Type:</p>
                </div>
                <Dropdown
                  //buttonStyle={{width: 300, fontSize: 8}}
                  dropDownVal={`Grammar: ${this.state.userInfo.userSettings.grammar}`}
                  dropDownValues={grammar}
                  changeValue={this.customChangeValue.bind(this)}
                  stateName={'userInfo'}
                />
              </div>
              <div style={optionStyle}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Info
                    message={
                      'Higher means that you have to practice you words to a higher degree before you encounter new words'
                    }
                    style={{ alignSelf: 'center', marginRight: 15 }}
                  />
                  <p style={{}}>Rate of new words: {Math.floor(this.state.userInfo.userSettings.learnRate)}</p>
                </div>
                <Slider
                  //classes={{ container: classes.slider }}
                  max={30}
                  min={0}
                  value={learnRate}
                  aria-labelledby="label"
                  onChange={(e, v) => {
                    let userInfo = this.state.userInfo;
                    userInfo.userSettings.learnRate = Math.floor(v);
                    this.setState({ userInfo });
                  }}
                  onMouseDownCapture={() => {
                    setTimeout(() => {
                      let userInfo = this.state.userInfo;
                      this.saveUserInfo(userInfo);
                    }, 3000);
                  }}
                  style={{ width: '50%', alignSelf: 'center' }}
                />
              </div>
              <br />
              <div style={optionStyle}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Info message={'Adjust the duration of a lesson'} style={{ alignSelf: 'center', marginRight: 15 }} />
                  <p style={{}}>Lesson length:</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <button
                    style={{ marginRight: 25 }}
                    onClick={() => {
                      let userInfo = this.state.userInfo;
                      userInfo.userSettings.lessonMultiplier = 2;
                      this.setState({ userInfo });
                      this.saveUserInfo(userInfo);
                    }}
                    className={`btn ${this.state.userInfo.userSettings.lessonMultiplier == 2 ? 'blue' : 'grey'}`}
                  >
                    short
                  </button>
                  <button
                    style={{ marginRight: 25 }}
                    onClick={() => {
                      let userInfo = this.state.userInfo;
                      userInfo.userSettings.lessonMultiplier = 3;
                      this.setState({ userInfo });
                      this.saveUserInfo(userInfo);
                    }}
                    className={`btn ${this.state.userInfo.userSettings.lessonMultiplier == 3 ? 'blue' : 'grey'}`}
                  >
                    normal
                  </button>
                  <button
                    style={{ marginLeft: 0 }}
                    onClick={() => {
                      let userInfo = this.state.userInfo;
                      userInfo.userSettings.lessonMultiplier = 4;
                      this.setState({ userInfo });
                      this.saveUserInfo(userInfo);
                    }}
                    className={`btn ${this.state.userInfo.userSettings.lessonMultiplier == 4 ? 'blue' : 'grey'}`}
                  >
                    long
                  </button>
                </div>
              </div>
            </div>
          )}
          {true && (
            <div className="white" style={style.box}>
              <h5>Account Settings</h5>
              <ChangePW props={this.props} style={{ color: 'black' }} />
              <p style={{ marginBottom: 25 }}>{this.state.message}</p>
              <br />
              <div style={{ marginBottom: 50 }}>
                <p>reset words</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <br />
                  <button className="btn pink" onClick={() => this.setState({ resetWords: !this.state.resetWords })}>
                    Enable reset
                  </button>
                  {this.state.resetWords && (
                    <div className="container white" style={style.box}>
                      <p>Rest Words</p>
                      <br />
                      <button
                        className={`btn ${this.state.resetWordsSuccess ? 'teal' : 'pink'}`}
                        onClick={this.resetWords.bind(this)}
                      >
                        reset words
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <p>delete account</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <br />
                  <button
                    className="btn pink"
                    onClick={() => this.setState({ deleteUser: this.state.deleteUser ? false : true })}
                  >
                    Enable deletion
                  </button>
                  {this.state.deleteUser && (
                    <div className="container white" style={style.box}>
                      <p>
                        If you press this button your account will be deleted (no recovery possible), so think carefully
                      </p>
                      <br />
                      <Link to="/Login" className="btn pink" onClick={this.deleteUser.bind(this)}>
                        {' '}
                        Delete Account{' '}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <IgnoredWords ignoredWords={this.state.ignoredWords} onClick={(e) => this.removeIgnoredWord(e)} />
        </div>
      );
    }
  }
}
const IgnoredWords = ({ ignoredWords, onClick }) => {
  return (
    <Table size="xs">
      <Thead>
        <Tr>
          <Th>english </Th>
          <Th>enable</Th>
        </Tr>
      </Thead>
      <Tbody>
        {ignoredWords.length > 0 &&
          ignoredWords.map((w) => (
            <Tr key={Math.random()}>
              <Td>{w.english}</Td>
              <Td>
                <Button onClick={onClick} id={w._id}>
                  unignore
                </Button>
              </Td>
            </Tr>
          ))}
      </Tbody>
    </Table>
  );
};

const mapStateToProps = (state) => {
  console.log('check test store', state);
  return {
    store: state.vocabReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    endTime: () => {
      dispatch({
        type: 'VOCAB_END_TIME',
        payload: '',
      });
    },
    sendUserInfoToReducer: (data) => {
      dispatch({
        type: 'USERINFO_GET',
        payload: data,
      });
    },
    tempUserSettings: (learnLanguage, email) => {
      dispatch({
        type: 'USERINFO_USER_SETTING_TEMP',
        payload: { learnLanguage, email },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);

// export default UserSettings;
