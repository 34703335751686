import { createStore, combineReducers, applyMiddleware } from 'redux';
import vocabReducer from './vocabReducer';

const myLogger = (store) => (next) => (action) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Update', action);
  }
  next(action);
};
const store = createStore(combineReducers({ vocabReducer }), {}, applyMiddleware(myLogger));

store.subscribe(() => {
  if (process.env.NODE_ENV === 'development') {
    console.log('store', store.getState());
  }
});

export default store;
