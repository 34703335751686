import React, { Component } from 'react';
//import {style} from "./styleConfig.js"
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'; // /lib/connect / connect
import Cookies from 'universal-cookie';
import pic from './landing.jpeg';
import readerModePic from './readerMode.gif';
import readerModeMobile from './readerModeMobile.jpg';
import practiceModeMobile from './practiceModeMobile.jpg';
import lessonMode from './lessonMode.gif';
import insightsMode from './insightsMode.gif';
import videoMode from './videoMode.gif';
import textMode from './textMode.gif';
import vocabMode from './vocab.gif';
import wordInfo from './wordInfo.gif';
import userInfo from './userInfo.gif';
import sentences from './sentences.gif';
import completeLong from './completeLong.gif';
import {
  IoIosSync,
  IoIosCheckmark,
  IoMdFastforward,
  IoIosBook,
  IoMdCreate,
  IoMdOptions,
  IoMdStats,
  IoMdBook,
} from 'react-icons/io';

import { FaGooglePlay, FaYoutube } from 'react-icons/fa';

const cookies = new Cookies();

let style = {
  container: {
    margin: 0,
    width: '100%',
  },
  pic: {
    backgroundColor: 'black',
    zIndex: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundImage: `url(${pic})`,
    //marginTop: '-9vh',
    //position: 'absolute',
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  demoPic: {},
  text: {},

  transparentTextBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',

    width: '70%',
    marginTop: 50,
    //marginTop: '-9vh',
    backgroundColor: 'rgba(255,255,255,0.9)',
    //backgroundColor:'white' ,
  },
  transparentTextBoxBlack: {
    margin: '50px',
    //marginTop: '-9vh',
    backgroundColor: 'rgba(0,0,0,0.8)',
    //backgroundColor:'white' ,
    width: '50%',
    color: 'white',
    padding: 50,
    fontSize: 15,
  },
  blue: {
    //height:   '90vh',
    //height:  window.matchMedia('(max-width:1200px)').matches ? '120vh': '70vh',
    backgroundColor: 'black', //'rgb(42, 131, 232)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: 50,
  },
};

class LandingPage extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }
  componentDidMount() {
    this.props.landingPage();
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    this.props.landingPage();
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  render() {
    if (this.state.width < 1200 && cookies.get('token')) return <Redirect to="/watch" />;
    //if(cookies.get('token')) return ( <Redirect to="/Login" />)
    if (false && this.state.width < 1000) {
      return (
        <div
          style={{
            backgroundColor: 'rgb(42, 131, 232)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            padding: 50,
            //height: '100vh'
          }}
        >
          <div
            style={{
              width: '90%',
              height: '50%',
              color: 'white',
              alignSelf: 'center',
              textAlign: 'center',
              marginBottom: 50,
            }}
          >
            <p style={{ fontSize: 22, marginBottom: 25 }}>
              {
                'Ling Academy is an adaptive spanish learning app. Please use a desktop browser with sufficient width (> 1000px) to see the full web app. This is just the mobile companion app.'
              }
            </p>
            {<FaGooglePlay style={{ fontSize: '10vh' }} />}
            <h3 style={{ borderBottom: '1px solid white' }}>{'Mobile Companion App'}</h3>
            <p style={{ fontSize: 22, marginBottom: 25 }}>{'Continue your learning progress on the go'}</p>
            <a
              href="https://play.google.com/store/apps/details?id=com.lingacademyapp"
              className="btn teal"
              style={{
                width: '80%',
                //alignSelf: 'center',
                fontSize: 12,
              }}
            >
              {' '}
              Try out{' '}
            </a>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                width: '100%',
                maxWidth: 350,
                height: '70vh',
                minHeight: 800,
                display: 'block',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <img
                src={readerModeMobile}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                }}
              />
            </div>

            <div
              style={{
                width: 'auto',
                maxWidth: 350,
                height: '70vh',
                minHeight: 800,
                display: 'block',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <img
                src={practiceModeMobile}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="white" style={style.container}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginBottom: 50,
            width: '100%',
            paddingTop: '5vh',
            height: '60vh',
            zIndex: 10,
          }}
        >
          <h1
            style={{
              fontSize: 60,
              fontWeight: 600,
              //width: '80%',
              alignSelf: 'center',
            }}
          >
            Learn Spanish vocabulary with real text and videos
          </h1>

          <p style={{ fontSize: 22, alignSelf: 'center', width: '70%', textAlign: 'center' }}>
            The fastest way for developing comprehension in Spanish.
          </p>
          <br />
          <p style={{ fontSize: 22, alignSelf: 'center', width: '70%', textAlign: 'center' }}>
            Ling academy is interactive and adapts to you, giving you what you most need when attaining reading
            comprehension in Spanish.
          </p>
          <br />
          <Link to={`/watch`} className="btn pink" style={{ width: 400, borderRadius: 50, alignSelf: 'center' }}>
            Try out without signing up
          </Link>
        </div>

        <Link to={'/endlessMode'}>
          <TextLeft
            text={
              'Activley learn vocabulary as fast as possible. Words are ordered in their frequency so you learn the most important words first. Only your memory recall matters, so you wont be punished for minor spelling mistakes. This all helps with understanding text.'
            }
            pic={vocabMode}
            icon={<IoMdCreate style={{ fontSize: '10vh' }} />}
            title={'Learn Vocab'}
          />
        </Link>
        <Link to={'/watch'}>
          <TextRight
            title={'Watch real videos'}
            text={
              'Enjoy authentic videos to quickly learn. Any youtube video with spanish subtitles will work. The subtitles are perfectly integrated with the App.'
            }
            pic={videoMode}
            icon={<FaYoutube style={{ fontSize: '10vh' }} />}
          />
        </Link>
        {/* <Link to={'/ArticleOverview'}>
                <TextLeft 
                    title={'Read real articles'}
                    text={'Instantly translate words you don`t know. They are automatically added for later review.'} 
                    pic={textMode}
                    icon={<IoIosBook style={{fontSize: '10vh'}} />}
                    />
                </Link> */}
        {/* <TextRight
          text={
            'You can take a detailed look at your learning data to understand your progress. Based on those insights you can also customize your learning experience.'
          }
          pic={insightsMode}
          icon={<IoMdStats style={{ fontSize: '10vh' }} />}
          title={'Track your progress'}
        /> */}
        <TextLeftMobile
          text={'Continue your learning progress on the go.'}
          pic={readerModeMobile}
          pic2={practiceModeMobile}
          icon={<FaGooglePlay style={{ fontSize: '10vh' }} />}
          title={'Mobile Companion App'}
        />
        {false && (
          <div style={{ ...style.pic, justifyContent: 'center' }}>
            <div style={{ ...style.transparentTextBox, width: '80%', marginRight: 25, padding: 50, marginBottom: 50 }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <div style={{ width: 200, textAlign: 'center' }}>
                  <div style={{ margin: '0% 20%' }}>
                    <IoMdCreate style={{ fontSize: '10vh' }} />
                  </div>
                  <p>Vocab Learning: exercises that adapt to you in real time</p>
                </div>
                <div style={{ width: 200, textAlign: 'center' }}>
                  <div style={{ margin: '0% 20%' }}>
                    <IoIosBook style={{ fontSize: '10vh' }} />
                  </div>
                  <p>Read: Improve your vocab knowledge by encountering them in real text</p>
                </div>
                <div style={{ width: 200, textAlign: 'center' }}>
                  <div style={{ margin: '0% 20%' }}>
                    <IoMdStats style={{ fontSize: '10vh' }} />
                  </div>
                  <p>
                    Insights: Besides real time adaptation based on your knowledge, you can also see relevant insights
                    about your learning progress
                  </p>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <div style={{ width: 200, textAlign: 'center' }}>
                  <div style={{ margin: '0% 20%' }}>
                    <IoIosSync style={{ fontSize: '10vh' }} />
                  </div>
                  <p>
                    Sync: The active learning mode knows about what words you just read, and articles show you how well
                    you know their words
                  </p>
                </div>
                <div style={{ width: 200, textAlign: 'center' }}>
                  <div style={{ margin: '0% 20%' }}>
                    <IoMdFastforward style={{ fontSize: '10vh' }} />
                  </div>
                  <p>
                    Efficient: You learn words in the order how relevant they are and the App adapts to how difficult
                    and easy words are
                  </p>
                </div>
                <div style={{ width: 200, textAlign: 'center' }}>
                  <div style={{ margin: '0% 20%' }}>
                    <IoMdOptions style={{ fontSize: '10vh' }} />
                  </div>
                  <p>
                    Customize: Let the App take care of everything or customize your learning decisions based on what
                    you want
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {false && (
          <TextRight
            text={
              'We learn better in sequencens than when we learn isolated facts. Dynamically generated sentences might contain grammar or semantic errors, but if you enable them, they will significantly boost your memory recall. You will learn more words in shorter time.'
            }
            pic={sentences}
            icon={<IoMdFastforward style={{ fontSize: '10vh' }} />}
            title={'Dynamic Sentences'}
          />
        )}
        {false && (
          <div
            style={{ ...style.pic, backgroundImage: `url(${completeLong})`, justifyContent: 'center', height: '70vh' }}
          >
            <div style={{ ...style.transparentTextBoxBlack }}>
              <IoMdCreate style={{ margin: '5% 50%', fontSize: 100 }}>Features</IoMdCreate>
              <ul>
                <li style={{ display: 'flex', flexDirection: 'row' }}>
                  <IoIosCheckmark style={{ alignSelf: 'center', marginRight: 5 }} />
                  <p>Learn the most frequent words first to quickly transiton into reading text.</p>
                </li>
                <li style={{ display: 'flex', flexDirection: 'row' }}>
                  <IoIosCheckmark style={{ alignSelf: 'center', marginRight: 5 }} />
                  <p>
                    Minor spelling mistakes are not punished, because recall is what matters for understanding text.
                  </p>
                </li>
                <li style={{ display: 'flex', flexDirection: 'row' }}>
                  <IoIosCheckmark style={{ alignSelf: 'center', marginRight: 5 }} />
                  <p>Review and learn mulitple words at the same time with dynamic sentences.</p>
                </li>
              </ul>
            </div>
            <div style={{ ...style.transparentTextBoxBlack }}>
              <IoMdBook style={{ margin: '5% 50%', fontSize: 100 }}>Features</IoMdBook>
              <ul>
                <li style={{ display: 'flex', flexDirection: 'row' }}>
                  <IoIosCheckmark style={{ alignSelf: 'center', marginRight: 5 }} />
                  <p>Instantly translate unkown words.</p>
                </li>
                <li style={{ display: 'flex', flexDirection: 'row' }}>
                  <IoIosCheckmark style={{ alignSelf: 'center', marginRight: 5 }} />
                  <p>Directly learn words in an article which will help with comprehension</p>
                </li>
                <li style={{ display: 'flex', flexDirection: 'row' }}>
                  <IoIosCheckmark style={{ alignSelf: 'center', marginRight: 5 }} />
                  <p>See where words are located in text.</p>
                </li>
              </ul>
            </div>
          </div>
        )}
        {false && (
          <TextLeft
            text={
              'Learning and Reading are interconected. Which words you should learn and which one need practice changes all the time so it is important to practice the right thing.'
            }
            pic={wordInfo}
            title={'Synchronization'}
            icon={<IoIosSync style={{ fontSize: '10vh' }} />}
          />
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            //marginBottom: 50,
            width: '100%',
            paddingTop: '5vh',
            height: '50vh',
            zIndex: 10,
          }}
        >
          <h1
            style={{
              fontSize: 100,
              fontWeight: 600,
              alignSelf: 'center',
            }}
          >
            Try out now
          </h1>
          <p style={{ fontSize: 22, alignSelf: 'center' }}>Convince yourself in 5 minutes</p>
          <br />
          <Link
            to={`/${cookies.get('token') ? 'endlessMode' : 'LessonOverview'}`}
            className="btn pink"
            style={{ width: 400, borderRadius: 50, alignSelf: 'center' }}
          >
            Try out without signing up
          </Link>
        </div>
      </div>
    );
  }
}

let TextLeft = (props) => {
  return (
    <div
      style={{ ...style.blue, flexWrap: window.matchMedia('(max-width:1200px)').matches ? 'wrap' : '', height: 'auto' }}
    >
      <div
        style={{
          width: '30%',
          marginLeft: '5%',
          height: '50%',
          color: 'white',
          //alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        {props.icon}
        <h3 style={{ borderBottom: '1px solid white' }}>{props.title}</h3>
        <p style={{ fontSize: 22 }}>{props.text}</p>
      </div>
      <div>
        <img
          src={props.pic}
          style={{
            width: '80%',
            margin: '0% 10%',
          }}
        />
      </div>
    </div>
  );
};
let TextLeftMobile = (props) => {
  return (
    <div style={{ ...style.blue, height: '100vh', width: '100%' }}>
      <div
        style={{
          width: '30%',
          marginLeft: '5%',
          height: '50%',
          color: 'white',
          //alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        {props.icon}
        <h3 style={{ borderBottom: '1px solid white' }}>{props.title}</h3>
        <p style={{ fontSize: 22, marginBottom: 25 }}>{props.text}</p>
        <a
          href="https://play.google.com/store/apps/details?id=com.lingacademyapp"
          className="btn teal"
          style={{
            width: 250,
            borderRadius: 50,
            alignSelf: 'center',
            fontSize: 12,
          }}
        >
          Try out android app <FaGooglePlay style={{ marginLeft: 5 }} />
        </a>
      </div>
      <div style={{ display: 'flex' }}>
        <img
          src={props.pic2}
          style={{
            height: '400px',
            margin: '0% 5%',
          }}
        />
        <img
          src={props.pic}
          style={{
            height: '400px',
            margin: '0% 5%',
          }}
        />
      </div>
    </div>
  );
};

let TextRight = (props) => {
  return (
    <div style={{ ...style.blue, flexWrap: window.matchMedia('(max-width:1200px)').matches ? 'wrap' : '' }}>
      <div>
        <img
          src={props.pic}
          style={{
            width: '80%',
            margin: '0% 10%',
          }}
        />
      </div>
      <div
        style={{
          width: '30%',
          marginRight: '5%',
          height: '50%',
          color: 'white',
          //alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        {props.icon}
        <h3 style={{ borderBottom: '1px solid white' }}>{props.title}</h3>
        <p style={{ fontSize: 22 }}>{props.text}</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    store: state.vocabReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    landingPage: () => {
      dispatch({
        type: 'LANDING_PAGE',
      });
    },
    reset: () => {
      dispatch({
        type: 'LESSON_RESET',
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
