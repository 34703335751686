import React, { Component } from 'react';
import { style } from '../../config/styleConfig';
import { Link } from 'react-router-dom';
import { MdWhatshot } from 'react-icons/md';
import { FaRedo } from 'react-icons/fa';

import WordProgressBar from '../../util/WordProgressBar.js';
import StatusItem from './subcomponents/StatusItem/StatusItem';
import { IoIosFlash } from 'react-icons/io';
export default (props) => {
  //if(!props.currentLearnData) return null;
  let {
    totalLearnScoreChange,
    learnScoreChange,
    totalEnergy,
    learnScoreChangeArray,
    totalEnergyChangeArray,
    srsLength,
    userWordsLength,
    headWordsLength,
  } = props.performanceData;
  let { streak, srs, counter, srsDueDate } = props.currentLearnData ? props.currentLearnData : false;
  const { setDebug, setConjugationMode, setSrsMode, setArticleMode, userInfo } = props;
  //if(learnScoreChangeArray.length > 15) learnScoreChangeArray.splice(0,learnScoreChangeArray.length - 15 )
  //if(totalXPChangeArray.length > 15) totalXPChangeArray.splice(0,totalXPChangeArray.length - 15 )
  let learnScoreChangeAvarage =
    learnScoreChangeArray.reduce((a, b) => a + b, 0) /
    (learnScoreChangeArray.length > 0 ? learnScoreChangeArray.length : 1);
  let totalEnergyAvarage =
    totalEnergyChangeArray.reduce((a, b) => a + b, 0) /
    (totalEnergyChangeArray.length > 0 ? totalEnergyChangeArray.length : 1);
  learnScoreChangeAvarage = Math.floor(learnScoreChangeAvarage * 100) / 100;
  totalEnergyAvarage = Math.floor(totalEnergyAvarage * 100) / 100;
  let energy = Math.floor((learnScoreChangeAvarage / totalEnergyAvarage) * 100) / 100;

  let learnScore = 0;
  if (props.currentLearnData) {
    learnScore = props.currentLearnData.learnScore;
  }
  const allowConjugationMode = userInfo?.learnLanguage === 'spanish';
  const allowArticleMode = userInfo?.learnLanguage === 'spanish';

  return (
    <div
      className="white"
      style={{
        margin: '20px',
        padding: 5,
        verticalAlign: 'middle',
        boxShadow: '0 10px 10px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.05)',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 5,
        }}
      >
        <StatusItem text={`${totalLearnScoreChange} XP`} />
        <StatusItem text={totalEnergy} icon={<IoIosFlash style={{ alignSelf: 'center' }} />} />
        <StatusItem text={counter ? counter : 0} icon={<FaRedo style={{ alignSelf: 'center' }} />} />
        <StatusItem text={`${srsLength ? srsLength : 0} ${learnScore > 65 ? 'srs' : 'practice'} length`} />
        <StatusItem text={`${props.headWord && props.headWord.orderRank}-rank`} />
        <StatusItem text={`${srs ? srs : 0}-srs`} />
        {/* <p style={{ marginRight: 5 }}>{`${srsDueDate ? srsDueDate : 'no srs duedate'}`}</p> */}
        <p style={{ color: props.debug ? 'teal' : 'black', marginLeft: 5 }} onClick={setDebug}>
          debug
        </p>
        {allowConjugationMode && (
          <p style={{ color: props.conjugationMode ? 'teal' : 'black', marginLeft: 5 }} onClick={setConjugationMode}>
            conjugation: {props.conjugationMode ? 'on' : 'off'}
          </p>
        )}
        <p style={{ color: props.srsMode ? 'teal' : 'black', marginLeft: 5 }} onClick={setSrsMode}>
          srs: {props.srsMode ? 'on' : 'off'}
        </p>
        {allowArticleMode && (
          <p style={{ color: props.articleMode ? 'teal' : 'black', marginLeft: 5 }} onClick={setArticleMode}>
            articles: {props.articleMode ? 'on' : 'off'}
          </p>
        )}
        <StatusItem text={`+ ${learnScoreChange} `} />
        <StatusItem
          text={`Streak ${streak ? streak : 0}`}
          icon={<MdWhatshot color="orange" style={{ alignSelf: 'center' }} />}
        />
      </div>
      <div>
        {
          <WordProgressBar
            word={props.currentLearnData}
            learnScoreChange={learnScoreChange}
            style={{ width: '100%' }}
          />
        }
      </div>
    </div>
  );
};

//<p>
//    {`${userWordsLength ? userWordsLength : 0} learned`}
//</p>
//<p>
//    {`${userWordsLength ? (headWordsLength - userWordsLength) : 0} remaining`}
//</p>
//<p>
//    {`${learnScoreChangeAvarage} LS avg`}
//</p>
//<p>
//    {`${totalEnergyAvarage} Energy avg`}
//</p>
//<p>
//    {`${energy ? energy : 0} energy`}
//</p>
