import { useEffect, useState } from 'react';
import axios from 'axios';
import url from '../config/config.js';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const useUserWords = (sendUserWordsToStore, userId = null) => {
  const [userWords, setUserWords] = useState([]);
  useEffect(() => {
    (async () => {
      let response = await axios({
        method: 'get',
        url: `${url}/basic/userWords/get/${userId}`,
        headers: { 'X-Auth-Token': cookies.get('token') },
      });
      let { userWords } = response.data;
      setUserWords(userWords);
      sendUserWordsToStore(response);
    })();
  }, []);
  return [userWords];
};

export const useAllWords = (userWords, headWords) => {
  const [allWords, setAllWords] = useState([]);
  useEffect(() => {
    headWords.forEach((headWord) => {
      let userWord = userWords.find((userWord) => userWord.english === headWord.english);
      if (userWord) headWord.currentLearnData = userWord.currentLearnData;
      else headWord.currentLearnData = false;
    });
    setAllWords(headWords);
  }, [userWords, headWords]);
  return [allWords];
};

export const useUserLearnData = () => {
  const [userLearnData, setUserLearnData] = useState([]);
  useEffect(() => {
    (async () => {
      let response = await axios({
        method: 'get',
        url: `${url}/get/userLearnData`,
        headers: { 'X-Auth-Token': cookies.get('token') },
      });
      let userLearnData = response.data.userLearnData;
      userLearnData = userLearnData.sort((a, b) => new Date(a.practiceDate) - new Date(b.practiceDate));
      setUserLearnData(userLearnData);
    })();
  }, []);
  return [userLearnData];
};
export const useUserSettings = (sendUserInfoToStore) => {
  const [userSettings, setUserSettings] = useState([]);
  useEffect(() => {
    (async () => {
      let response = await axios({
        method: 'get',
        url: `${url}/userSettings`,
        headers: { 'X-Auth-Token': cookies.get('token') },
      });
      setUserSettings(response.data.userInfo);
      sendUserInfoToStore();
    })();
  }, []);
  return [userSettings];
};
export const useSaveUserSettings = (userInfo, sendUserInfoOfflineToStore, dependency) => {
  const [updatedUserSettings, setUpdatedUserSettings] = useState([]);
  useEffect(() => {
    (async () => {
      let response = await axios({
        method: 'post',
        url: `${url}/userSettings`,
        headers: { 'X-Auth-Token': cookies.get('token') },
        data: { userInfo },
      });
      setUpdatedUserSettings(response.data.userInfo);
      sendUserInfoOfflineToStore(response.data.userInfo);
    })();
  }, [dependency]);
};
