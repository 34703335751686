import React, { Component } from 'react';
import levenshtein from 'fast-levenshtein';
import { style } from '../../config/styleConfig.js';
import url from '../../config/config.js';
import axios from 'axios';
import Dropdown from './../Dropdown';
import { connect } from 'react-redux'; // /lib/connect / connect
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class CreateHeadWord extends Component {
  state = {
    english: '',
    spanish: '',
    pos: 'NN',
    searchWords: [],
    searchCandidates: [],
  };

  // componentDidMount() {
  //   this.loadSearchWords();
  // }

  // loadSearchWords() {
  //   axios({
  //     method: 'get',
  //     url: `${url}/get/searchWords`,
  //     headers: { 'X-Auth-Token': cookies.get('token') },
  //   })
  //     .then(({ data }) => {
  //       if (data.searchWords) this.setState({ searchWords: data.searchWords });
  //     })
  //     .catch(e => {
  //       console.error(e);
  //     });
  // }
  sendNewHeadWord() {
    let { pos, english, spanish } = this.state;
    axios({
      method: 'post',
      url: `${url}/create/new/headWord`,
      headers: { 'X-Auth-Token': cookies.get('token') },
      data: { pos, english, spanish },
    })
      .then((response) => {
        //if(response.data.dictWord) this.setState({dictWord: response.data.dictWord});
      })
      .catch((e) => {
        console.error(e);
      });
  }
  filterSearchWords(searchCandidates, searchTerm) {
    searchCandidates = searchCandidates
      .filter((s) => levenshtein.get(s.english, searchTerm) < 3 || levenshtein.get(s.spanish, searchTerm) < 3)
      .sort((a, b) => {
        let A =
          levenshtein.get(a.english, searchTerm) <= levenshtein.get(a.spanish, searchTerm)
            ? levenshtein.get(a.english, searchTerm)
            : levenshtein.get(a.spanish, searchTerm);
        let B =
          levenshtein.get(b.english, searchTerm) <= levenshtein.get(a.spanish, searchTerm)
            ? levenshtein.get(b.english, searchTerm)
            : levenshtein.get(b.spanish, searchTerm);
        return A - B;
      });
    searchCandidates.length = 10;
    return searchCandidates;
  }
  render() {
    return (
      <>
        <div
          className="row white"
          style={{ ...style, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <h5>create head word</h5>
          <div>
            <p>english</p>
            <input value={this.state.english} onChange={(e) => this.setState({ english: e.target.value })} />
            <p>spanish</p>
            <input value={this.state.spanish} onChange={(e) => this.setState({ spanish: e.target.value })} />
            <Dropdown
              dropDownVal={`POS: ${this.state.pos}`}
              dropDownValues={['NN', 'VB', 'RB', 'JJ']}
              changeValue={Dropdown.changeValue.bind(this)}
              stateName={'pos'}
            />
            <button
              className={`btn pink`}
              onClick={() => {
                this.setState({ english: '', spanish: '' });
                this.loadSearchWords();
                this.sendNewHeadWord();
              }}
            >
              submit
            </button>
          </div>
          <div>
            <input
              value={this.state.search}
              onChange={(e) => {
                let input = e.target.value;
                let searchCandidates = this.state.searchWords;
                searchCandidates = this.filterSearchWords(searchCandidates, input);
                this.setState({ search: input, searchCandidates });
              }}
            ></input>
            <SearchResults {...this.state} />
          </div>
        </div>
      </>
    );
  }
}

const SearchResults = ({ searchCandidates }) => {
  return (
    <ul>
      {searchCandidates.map((s) => (
        <li>
          {s.english} - {s.spanish}
        </li>
      ))}
    </ul>
  );
};

const mapStateToProps = (state) => {
  return {
    store: state.vocabReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset: () => {
      dispatch({
        type: 'LESSON_RESET',
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateHeadWord);
