import convert from 'xml-js'
export default class VideoWatcherService {
    static moveForward(currentSubIndex, subtitles, player) {
        if (!subtitles || currentSubIndex == subtitles.length - 1) return false;
        let currentSub = subtitles[currentSubIndex + 1];
        let currentTime = parseFloat(currentSub._attributes.start);
        player.seekTo(currentTime);
        player.playVideo();
        let newIndex = currentSubIndex + 1;
        return newIndex;
    }
    static moveBack(currentSubIndex, subtitles, player) {
        if (currentSubIndex == 0 || !subtitles) return false;
        let currentSub = subtitles[currentSubIndex - 1];
        let currentTime = parseFloat(currentSub._attributes.start);
        player.seekTo(currentTime);
        player.playVideo();
        let newIndex = currentSubIndex - 1;
        return newIndex;
    }
    static getThumbnailUrl(videoId) {
        return `https://img.youtube.com/vi/${videoId}/0.jpg`
    }
    static setCurrentSubtitleIndex(subtitles, player, currentSubIndex, subtitleMode, clickedWord) {
        let oldSubIndex = currentSubIndex;
        let time = 0;
        if (player) time = player.getCurrentTime();
        if (subtitles && player) {
            subtitles.forEach((t, i) => {

                let start = parseFloat(t._attributes.start);

                if (start <= time) {
                    currentSubIndex = i;
                }
            })
            if (subtitles[currentSubIndex + 1] && time > 2) {
                let nextStart = parseFloat(subtitles[currentSubIndex + 1]._attributes.start);
                if (nextStart <= time + 0.5) {
                    if (subtitleMode) player.pauseVideo();
                }
            }
            clickedWord = oldSubIndex == currentSubIndex ? clickedWord : false;
            if (currentSubIndex) return {
                currentSubIndex,
                clickedWord
            };

        }
        return {
            currentSubIndex: false,
            clickedWord: false
        };
    }
    static getIdFromTYURL(youTubeUrl) {
        //var sampleUrl = "http://www.youtube.com/watch?v=JcjoGn6FLwI&asdasd";

        if (youTubeUrl == '' || !youTubeUrl.split("v=")[1]) return ''

        var video_id = youTubeUrl.split("v=")[1].substring(0, 11)
        return video_id;
    }

    static matchSubToDict(wordArray, dictWords) {
        if (dictWords.length === 0) return false;

        wordArray.words.forEach((word, i) => {
            let dictWord = dictWords.find(d => word.lowerCaseWord === d.word.toLowerCase());
            if (dictWord) {
                if (word.lowerCaseWord === dictWord.word.toLowerCase()) {
                    word.dictWord = dictWord;
                }
            }
        })
        return wordArray;
    }
    static convertYouTubeManualCaptions(data) {
        let result1 = convert.xml2json(data, {
            compact: true,
            spaces: 4
        });
        let t = JSON.parse(result1);
        let subtitles = t.transcript;
        subtitles.text.forEach(sub => {
            if (sub._text) {
                sub.words = sub._text.split(/[ \n]/g);
                sub.words = sub.words.map(o => {
                    return {
                        word: o,
                        lowerCaseWord: o.toLowerCase().replace(/[,_:.!?¡]/g, ''),
                        dictWord: false
                    }
                });

            }
        })
        return subtitles;
    }
    static cleanString(string) {
        string = string.replace(' ', '')
        string = string.replace('!', '')
        string = string.replace('.', '')
        string = string.replace(',', '')
        string = string.replace('?', '')
        string = string.replace(':', '')
        string = string.replace('¿', '')
        string = string.replace('¡', '')
        string = string.replace('”', '')
        string = string.replace('“', '')
        string = string.replace('…', '')
        string = string.replace('-', '')
        string = string.replace('–', '')
        string = string.replace('!', '')
        string = string.replace('(', '')
        string = string.replace(')', '')
        string = string.replace('[', '')
        string = string.replace(']', '')
        string = string.toLowerCase()
        string = string.trim()

        return string
    }
    static getStringArray(originalSubs) {
        if (!originalSubs) return false;
        let subtitles = [];
        originalSubs.forEach(t => {
            if (t._text) {
                let wordArry = t._text.split(/[ \n]/g);
                wordArry.forEach(w => {
                    if (!subtitles.some(s => s == w)) subtitles.push(w)
                })
            }
        })
        return subtitles;
    }
}