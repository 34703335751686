import React, { useState, useEffect } from 'react';
import TextAPIService from "./TextAPIService";
import TextService from "./TextService";
import Text from "./Text"

export function useUserDictWords(){
  const [userDictWords, setUserDictWords] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        let userDictWords = await TextAPIService.getUserDictWordsAPI();
        setUserDictWords(userDictWords);
      } catch (e){
        console.log(e)
      }
    })()
  },[])
  return [userDictWords];
}
export function useTextList(){
  const [textList, setTextList] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        let textList = await TextAPIService.getUserTextListAPI();
        setTextList(textList)
      } catch (e){
        console.log(e)
      }
    })()
  },[])
  return [textList]
}


export function useTextArray(textData) {
  const [textArray, setTextArray] = useState([]);
  const [dictWords, setDictWords] = useState([]);

      console.log(textData)

  useEffect(() => {
    (async () => {
      console.log(textData)
      let {textArray, textUrl} = textData;
      console.log(textArray)
      if(textArray){
        let spanishWords = textArray.map( s => Text.cleanString(s.lowerCaseWord));
        let dictWords = await TextAPIService.getOrCreateManyDictWordsAPI(spanishWords);

        textArray = {
          words: textArray
        }
        textArray = TextService.matchSubToDict(textArray, dictWords);
        textArray = textArray.words

        setTextArray(textArray)
        setDictWords(dictWords)
        TextAPIService.saveTextDataAPI(textUrl, textData);
      }
    })()
  }, [textData]);

  return [textArray, dictWords]
}
export function useTextData(url) {
  const [textData, setTextData] = useState({});
  useEffect(() => {
    (async () => {
      try {
        let textData = await TextAPIService.getTextDataAPI(url);
        setTextData(textData);
        console.log(textData)
      }
      catch(e){
        console.log(e)
        let textData = await TextAPIService.scrapeTextAPI(url);
        setTextData(textData);
        console.log(textData)
      }
    })()

  },[url])

  return [textData];
}