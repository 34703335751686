import React from 'react';
import { MdWhatshot } from 'react-icons/md';
import { IoIosFlash } from 'react-icons/io';
export default (props) => {
  let { modifiedLearnData, userLearnData } = props.props;
  if (!userLearnData || (userLearnData && userLearnData.length == 0)) return null;
  let learnData = userLearnData.sort((a, b) => b.counter - a.counter)[0];
  let kpiStyle = { display: 'flex', flexDirection: 'column', alignItems: 'center' };
  let kpiUnderline = { borderBottom: '2px solid black', margin: 25 };
  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: 20, marginBottom: 25 }}
    >
      <div style={kpiStyle}>
        <p style={kpiUnderline}>{learnData && learnData.counter}</p>
        <p>counter</p>
      </div>
      <div style={kpiStyle}>
        <p style={kpiUnderline}>{learnData && learnData.srs}</p>
        <p>srs</p>
      </div>
      <div style={kpiStyle}>
        <p style={kpiUnderline}>{learnData && learnData.learnScore}</p>
        <p>learn score</p>
      </div>
      <div style={kpiStyle}>
        <p style={kpiUnderline}>{learnData && learnData.srsReady ? 'true' : 'false'}</p>
        <p>ready</p>
      </div>
      <div style={kpiStyle}>
        <p style={kpiUnderline}>
          {learnData &&
            Math.floor((modifiedLearnData.reduce((a, b) => a + b.totalTime, 0) / modifiedLearnData.length) * 1000) /
              1000}
        </p>
        <p>average speed</p>
      </div>
      <div style={kpiStyle}>
        <p style={kpiUnderline}>{learnData && new Date(learnData.practiceDate).toLocaleDateString()}</p>
        <p>last time</p>
      </div>
      <div style={kpiStyle}>
        <p style={kpiUnderline}>
          {learnData && Math.floor((Date.now() - new Date(learnData.practiceDate)) / (1000 * 60 * 60 * 24))}
        </p>
        <p>days since last time</p>
      </div>
    </div>
  );
};
