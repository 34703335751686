import React from "react";
import BarChart from '../util/BarChart.js';
export default ({words}) => {
    return (
        <BarChart data={{
            labels: [0,1,2,3,4,5, 6, 7, 8, 9],
            datasets: [
                {
                label: "X: learn level; Y: Number of words",
                data: [0,1,2,3,4,5, 6, 7, 8, 9].map(srs => { 
                    return words.filter(w => w.currentLearnData.srs === srs).length
                }),
                backgroundColor: "#00bcd4",
                //borderColor: '#e91e63'
                }
            ]
            }}
        />
    )
}