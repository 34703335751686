import React, { useState, useEffect } from 'react';
import { style } from '../config/styleConfig.js';
import { connect } from 'react-redux'; // /lib/connect / connect
import UserWordSingle from './UserWordSingle.js';
import TextAPIService from './TextAPIService.js';
import TextService from './TextService.js';
import { useTextData, useTextArray, useUserDictWords, useTextList } from './useArticleText';
import Text from './Text.js';

const TextReader = () => {
  const [state, setState] = useState({
    showPopup: false,
    x: 0,
    y: 0,
    renderText: null,
    img: '',
    title: '',
    subTitle: '',
    textUrl: 'https://elpais.com/tecnologia/2019/07/31/actualidad/1564573310_420871.html',
    userInput: '',
    urlList: [
      //viz politic
      'https://elpais.com/tecnologia/2019/07/31/actualidad/1564573310_420871.html',
    ],
    clickedWord: { word: 'test', translation: 'error' },
    showPopup: false,
    clickedDictWords: [],
    userDictWords: [],
    dictWords: [],
    apiTranslation: false,
    screenWidth: window.innerWidth,
  });
  let [textData] = useTextData(state.textUrl);
  let [textArray] = useTextArray(textData);
  let [userDictWords] = useUserDictWords();
  let [textList] = useTextList();

  //handleWindowSizeChange  ()  {
  //    this.setState({ screenWidth: window.innerWidth })
  //}

  useEffect(() => {
    console.log('use effect called');
    //window.addEventListener('resize', this.handleWindowSizeChange.bind(this));
    document.addEventListener('keydown', (e) => {
      if (e.code == 'Escape') {
        setState((state) => {
          return { ...state, showPopup: false, foundWord: {} };
        });
      }
    });

    return () => {
      document.removeEventListener('keydown', () => {});
      document.removeEventListener('keydown', () => {});
      //window.removeEventListener('resize', this.handleWindowSizeChange);
    };
  }, []);

  const clickedWord = async (e) => {
    e.persist();
    console.log(e);
    setState((state) => {
      return { ...state, x: e.pageX, y: e.pageY, showPopup: true };
    });
    try {
      e.persist();
      let spanish = e.target.id.trim();
      let response = await TextAPIService.getManyDictsWordsAPI(spanish);

      if (response.data.dictWords.length === 0) {
        setState((state) => {
          return {
            ...state,
            apiTranslation: response.data.translation,
            translateText: '',
            showPopup: true,
          };
        });
        return false;
      }

      let { clickedDictWords } = state;
      let data = {
        ...state,
        response,
      };
      let userDictWord = Text.getUserDict([], spanish, response, userDictWords);
      userDictWord = Text.addCounter(userDictWord);
      clickedDictWords = Text.addToList(clickedDictWords, userDictWord);

      setState((state) => {
        return { ...state, clickedWord: response.data.dictWords[0], clickedDictWords };
      });
      TextAPIService.saveDictWordAPI(userDictWord);
    } catch (e) {
      console.log(e);
    }
  };

  const wordMouseLeave = (e) => {
    setState((state) => {
      return { ...state, clickedWord: false, showPopup: false };
    });
  };
  const clickArticleWord = (e) => {
    e.persist();
    setState((state) => {
      return { ...state, modal: true, clickedWord: state.clickedDictWords.find((c) => c.word === e.target.id) };
    });
  };
  const disableModal = () => {
    setState((state) => {
      return { ...state, modal: false };
    });
  };
  const popUpStyle = (screenWidth, style) => {
    let basicPopupStyle = {
      backgroundColor: 'white',
      position: 'absolute',
      textAlign: 'center',
      zIndex: 10,
      borderWidth: 'thin',
      borderColor: 'teal',
      borderStyle: 'solid',
    };
    if (screenWidth > 1200)
      return {
        ...style.box,
        ...basicPopupStyle,
        padding: 10,
        height: 125,
        width: 400,
        top: state.y - 150,
        left: state.x - 150,
      };
    else
      return {
        ...style.box,
        ...basicPopupStyle,
        width: '100%',
        height: 125,
        bottom: 0,
        left: 0,
        padding: 10,
      };
  };

  let { screenWidth } = state;
  let { title, subTitle, textUrl } = textData;
  console.log('check clickedWord', state.clickedWord);
  return (
    <div
      className="row white"
      style={{
        ...style,
        height: '100%',
        width: 'auto',
        width: screenWidth > 1200 ? 'auto' : screenWidth,
        margin: screenWidth > 1200 ? 30 : 0,
        padding: screenWidth > 1200 ? 30 : 0,
      }}
    >
      {state.showPopup && (
        <div style={popUpStyle(screenWidth, style)}>
          <p style={{ fontSize: 10, marginBottom: 25 }}>press escape to close</p>
          <p style={{ fontSize: 30 }}>{state.clickedWord.translation}</p>
        </div>
      )}
      <UserWordSingle dictWord={state.clickedWord} modal={state.modal} disableModal={disableModal} />
      <div
        style={{
          display: 'flex',
          flexDirection: state.screenWidth > 1200 ? 'row' : 'column',
          justifyContent: 'space-evenly',
          alignItems: state.screenWidth > 1200 ? 'flex-start' : 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            order: state.screenWidth > 1200 ? 1 : 2,
          }}
        >
          <input
            style={{ width: 300 }}
            onChange={(e) => {
              e.persist();
              setState((state) => {
                return { ...state, userInput: e.target.value };
              });
            }}
            value={state.userInput}
            placeholder="article url"
          />
          <button
            className="btn blue"
            style={{ width: 300 }}
            onClick={() => {
              setState((state) => {
                return { ...state, textUrl: state.userInput };
              });
            }}
          >
            find
          </button>
          <ul className="collection" style={{ width: 300, height: 600, overflowY: 'scroll' }}>
            {textList.map((text) => {
              return (
                <li
                  className="collection-item"
                  id={text.textUrl}
                  onClick={(e) => {
                    e.persist();
                    setState((state) => {
                      return { ...state, textUrl: e.target.id };
                    });
                  }}
                >
                  {text.title}
                </li>
              );
            })}
          </ul>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            order: state.screenWidth > 1200 ? 2 : 1,
          }}
        >
          <ArticleWords props={{ title, subTitle, textUrl, screenWidth, textArray, wordMouseLeave, clickedWord }} />
        </div>
        <div
          style={{
            height: 800,
            overflowY: state.clickedDictWords.length > 10 ? 'scroll' : '',
            visibility: state.clickedDictWords.length > 0 ? 'visible' : 'hidden',
            order: 3,
          }}
        >
          <ClickedWords state={state} clickArticleWord={clickArticleWord} />
        </div>
      </div>
    </div>
  );
};

const ArticleWords = (props) => {
  let { title, subTitle, textUrl, screenWidth, textArray, wordMouseLeave, clickedWord } = props.props;
  let rightSpace = ['®', ':', ',', '.', '?', '%', '|', '$', ',', '.', '?', '¿', '!', '¡', ';', '.'];
  let matchWord = false;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        //backgroundColor:'white',
        justifyContent: 'center',
        alignItems: 'center',
        //width: this.state.screenWidth > 1200 ? 'auto' : this.state.screenWidth,
        width: screenWidth > 1200 ? '80%' : '100%',
        //maxWidth: 600,
        height: '100%',
        textAlign: 'middle',
        flexWrap: 'wrap',
        //margin: 15
      }}
    >
      <h5 style={{ color: 'black', margin: 15 }}>{title}</h5>
      <h6 style={{ color: 'black', margin: 15 }}>{subTitle}</h6>
      <p style={{ margin: 15 }}>
        {textArray &&
          textArray.map((w, i) => {
            matchWord = w.dictWord.match;
            if (w.word.length > 30) return null;
            return (
              <span key={i}>
                {`${rightSpace.some((w) => w.word && w === w.word) ? '' : ' '}`}
                <span
                  key={i + 'a'}
                  style={{
                    fontSize: screenWidth > 1200 ? 30 : 20,
                    //textDecoration: matchWord ? 'underline' : '',
                    //textDecorationStyle: matchWord ? 'dotted' : '',
                    //textDecorationColor: allowPos.some( p => p === word.pos) ? 'black' : 'red',
                    //textDecorationColor:  matchWord ?  'teal' : '',
                    //backgroundColor: matchWord && matchWord.headWordEnglish == this.state.foundWord.english ? '#81d4fa' : 'white',
                    cursor: matchWord ? 'pointer' : '',
                    //color: word.matches.length == 0 ? 'grey' : 'black'
                  }}
                  onMouseLeave={wordMouseLeave}
                  onClick={clickedWord}
                  id={w.lowerCaseWord}
                >
                  {w.word}
                </span>
              </span>
            );
          })}
      </p>
      <a href={textUrl}>original</a>
    </div>
  );
};
const ClickedWords = ({ state, clickArticleWord }) => {
  if (!state.clickedDictWords) return null;
  return (
    <ul className="collection" style={{ width: 200 }}>
      {state.clickedDictWords.map((dictWord, i) => {
        //console.log(word)
        return (
          <li
            onClick={clickArticleWord}
            id={dictWord.word}
            className="collection-item"
            style={{
              color: 'black',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            key={i}
          >
            <p style={{ maxWidth: '60%', fontSize: '12' }}>
              {dictWord.word} - {dictWord.translation}
            </p>
          </li>
        );
      })}
    </ul>
  );
};
const mapStateToProps = (state) => {
  return {
    store: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    test: () => {
      dispatch({
        type: 'TEST',
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TextReader);

const renderTextDoubble = (state, clickedWord) => {
  return state.textArray.map((w, i) => {
    if (w.toLowerCase === '' || w.toLowerCase === ' ' || w.dictWord.translation === undefined) {
      return <br />;
    } else
      return (
        <span //className="white btn small"
          id={w.lowerCaseWord}
          onClick={(e) => clickedWord(e)}
          //style={{color: !w.dictWord ? 'black' : w.dictWord.match ? 'blue' : 'red', backgroundColor: 'green', margin: 5}}
          style={{
            display: 'flex',
            flexDirection: 'column',
            color: !w.dictWord ? 'black' : w.dictWord.match ? 'teal' : 'black',
            backgroundColor: 'white',
            marginRight: 2.5,
            //padding: 2.5,
            fontSize: 12,
          }}
        >
          <span id={w.lowerCaseWord}>{w.word}</span>
          <span
            id={w.lowerCaseWord}
            style={{
              visibility:
                state.clickedWord && state.clickedWord.translation == w.dictWord.translation ? 'visible' : 'hidden',
            }}
          >
            {w.dictWord.translation}
          </span>
        </span>
      );
  });
};
