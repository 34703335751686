import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import url from '../../config/config.js';
import { style } from '../../config/styleConfig.js';
import ApolloClient from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Cookies from 'universal-cookie';
import { DisabledBadge, LearnScoreBadge } from './components';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import Pagination from './components/Pagination.js';
const cookies = new Cookies();

// or you can use `import gql from 'graphql-tag';` instead

// todo: create parent called LessonEditor
// create vocabLists (lessons)
// see all headWords
// edit headWords
// create new headWords

const Syllabus = ({ headWords, headWord, wordClick, handleDisableWord, navigation, pagination }) => {
  const minRank = 1000;
  // let [headWords, setHeadWords] = useState(headWords);
  const [wordList, setWordList] = useState(headWords);
  // const [navigationData, setNavigationData] = useState({ headWordAmount: 30, rank: 200 });

  // const { loading, error, data } = useQuery(gql`
  //   {
  //     headWords {
  //       english
  //       spanish
  //     }
  //   }
  // `);
  // console.log(' gql data', data);

  // useEffect(() => {
  //   (async () => {
  //     const { headWordAmount, rank } = navigationData;
  //     console.log(navigationData);
  //     let response = await axios({
  //       method: 'post',
  //       url: `${url}/basic/headWords/all`,
  //       headers: { 'X-Auth-Token': cookies.get('token') },
  //       data: { minRank: rank, maxRank: false, limit: headWordAmount },
  //     });
  //     let { headWords } = response.data;
  //     // setHeadWords(headWords);
  //   })();
  // }, [navigationData.headWordAmount, navigationData.rank]);

  useEffect(() => {
    setWordList(headWords);
  }, [headWords]);

  // const handleDisableWord = e => {
  //   const english = e.target.id;
  //   const newWordList = wordList.map(h => {
  //     if (h.english === english) {
  //       h.disabled = true;
  //       return h;
  //     }
  //     return h;
  //   });
  //   setWordList(newWordList);
  // };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const calculateRank = (list) => {
    const startRank = list.slice().sort((a, b) => a.orderRank - b.orderRank)[0].orderRank;
    const newList = list.map((listItem, index) => {
      return { ...listItem, orderRank: startRank + index };
    });
    return newList;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const orderedList = reorder(wordList, result.source.index, result.destination.index);
    const newWordList = calculateRank(orderedList);

    setWordList(newWordList);
  };

  const WordList = React.memo(function WordList({ words, wordClick, headWord, handleDisableWord }) {
    return (
      <Table size="xs">
        <Thead>
          <Tr>
            <Th>orderRank</Th>
            <Th>english </Th>
            <Th>spanish</Th>
            <Th>german</Th>
            <Th>disabled</Th>
            <Th>select</Th>
            <Th>disable</Th>
          </Tr>
        </Thead>
        <Tbody>
          {words.map((word, index) => (
            <Word
              word={word}
              key={word.orderRank}
              wordClick={wordClick}
              headWord={headWord}
              handleDisableWord={handleDisableWord}
            />
          ))}
        </Tbody>
      </Table>
    );
  });

  const Word = ({ word, index, wordClick, headWord, handleDisableWord }) => {
    let selected = false;
    //very annoying
    if (word && word.english && headWord && headWord.english) {
      selected = headWord.english === word.english;
    }
    return (
      <Tr key={Math.random()} bg={selected && 'teal'}>
        <Td>{word.orderRank}</Td>
        <Td>{word.english}</Td>
        <Td>{word.spanish}</Td>
        <Td>{word.german}</Td>
        <Td>
          <DisabledBadge disabled={word.disabled} />
        </Td>
        <Td>
          <Button
            isRound
            onClick={wordClick}
            id={word._id}
            className="btn-floating  waves-effect waves-light blue"
            // style={{ height: 30, width: 30 }}
          >
            select
          </Button>
        </Td>
        <Td>
          <Button
            onClick={handleDisableWord}
            className="btn-floating  waves-effect waves-light pink"
            style={{ height: 30, width: 30 }}
          >
            <Flex>
              <p id={word.english} style={{ lineHeight: 0, fontSize: 8 }}>
                disable
              </p>
            </Flex>
          </Button>
        </Td>
      </Tr>
    );
  };

  if (wordList.length === 0) return false;
  return (
    <div className="row white" style={{ ...style, height: 'auto' }}>
      {navigation}
      <div>
        <WordList words={wordList} wordClick={wordClick} headWord={headWord} handleDisableWord={handleDisableWord} />
      </div>

      {pagination}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    store: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserWords: (response) => {
      dispatch({
        type: 'USERWORDS_GET',
        payload: response,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Syllabus);
