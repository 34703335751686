import { IconButton } from '@chakra-ui/button';
import { ArrowBackIcon, ArrowForwardIcon, ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { HStack, Text } from '@chakra-ui/layout';
import React from 'react';

const Pagination = ({ onSetPagination, paginationStep }) => {
  return (
    <HStack>
      <IconButton
        onClick={() => {
          onSetPagination('first');
        }}
        icon={<ArrowLeftIcon />}
      />
      <IconButton
        onClick={() => {
          onSetPagination('prev');
        }}
        icon={<ArrowBackIcon />}
      />
      <Text>Page {paginationStep}</Text>
      <IconButton
        onClick={() => {
          onSetPagination('next');
        }}
        icon={<ArrowForwardIcon />}
      />
      <IconButton
        onClick={() => {
          onSetPagination('last');
        }}
        icon={<ArrowRightIcon />}
      />
    </HStack>
  );
};

export default Pagination;
