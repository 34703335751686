import {useState, useEffect, useRef} from 'react';
import TextService from './TextService';

export default (subtitles, subtitleMode, clickedWord, player) => {
  let [currentSubIndex, setCurrentSubIndex] = useState(0);

  const intervalRef = useRef();
  useEffect(() => {
    (async () => {

        let interval = setInterval(() => {
            let oldSubIndex = currentSubIndex;
            currentSubIndex = TextService.setCurrentSubtitleIndex(subtitles, player, currentSubIndex, subtitleMode, clickedWord);
            ({currentSubIndex} = currentSubIndex)
            if(oldSubIndex !== currentSubIndex) setCurrentSubIndex(currentSubIndex);

            //if(currentSubIndex) {
            //    //clickedWord = (oldSubIndex == currentSubIndex) ? clickedWord : false;
            //    setCurrentSubIndex(currentSubIndex);
            //}
        }, 500)
        intervalRef.current = interval;

    })();

    return () => {
      console.log('unmount intervall')
      clearInterval(intervalRef.current)
    }

  }, [subtitles, player])
  return [currentSubIndex, setCurrentSubIndex];
}