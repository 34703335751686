
import React, { Component } from 'react';
import {style as st} from "./styleConfig.js"
import { IoIosBook, IoMdBook, IoMdCreate, IoIosSettings, IoMdStats, IoMdHome, IoIosHelpCircleOutline,IoIosInformationCircle, IoIosLogOut,IoIosLogIn } from "react-icons/io";
let style = { box: {...st, height: "400px", padding: "50px"} }
export default class Info extends Component {
  constructor() {
    super()
    this.state = {
      name: '',
      hide: false,
      enableLink: true,
      tempLang: "spanish",
      cardBorderColor: 'teal',
      showPopup: false,
      popupMessage: '',
      x: 0,
      y: 0,

    }
  }

  updatePopup(e){
    // console.log('mouse enter')
    this.setState({
      x: e.mouseX , 
      y: e.mouseY, 
      showPopup: this.props.message && this.props.message.length > 0 ? true : false, 
      popupMessage: this.props.message  
    })
  }


  popupStyle(){
    return {
      ...style.box,
      backgroundColor: 'white', 
      height: 100, 
      width: 250, 
      position: 'absolute', 
      zIndex: 10,
      borderWidth: 'thin', 
      borderColor: 'teal', 
      borderStyle: 'solid' ,
      //top: this.state.y - 45 ,
      //left: this.state.x - 45,
      top: 0,
      left: 0,
      padding: 10,
      textAlign:'center',
      fontSize: 13
    }
  }
  render() {
    let backGroundColorOld = 'blue-grey darken-1'
    if(this.state.hide){
      return null
    }
    return (
      <div style={{...this.props.style, color: 'black'}}>
        <IoIosInformationCircle
          onMouseMove={this.updatePopup.bind(this)}
          onMouseLeave={()=> {

            setTimeout(() => {
              this.setState({showPopup: false})
            }, 500)
          }}
        />
        {false && this.state.showPopup 
        && <div onClick={() => this.setState({showPopup: false})} style={this.popupStyle.bind(this)()} >
            <p >{this.props.message || '...'}</p>
          </div>
        }
        
        <div className="tooltip" style={{
          position: 'relative',
          display: "inline-block",
          //borderBottom: "1px dotted black",
          visibility: this.state.showPopup ? 'visible' : 'hidden',
          //width: 120,
          backgroundColor: 'black',
          color: '#fff',
          textAlign: 'center',
          padding: '5px 0',
          borderRadius: 6,
              //marginBottom: 20,
              bottom: '300%',
              left: '50%', 
              marginLeft: -60,
        }} >

          <span className="tooltiptext" 
          
            style={{

              visibility: this.state.showPopup ? 'visible' : 'hidden',
              width: 250,
              backgroundColor: 'black',
              color: '#fff',
              textAlign: 'center',
              padding: 10,
              borderRadius: 6,
              position: 'absolute',
              zIndex: 1,

              bottom: '300%',
              left: '50%', 
              marginLeft: -60,
          }} >{this.props.message}</span>
        </div>
        
        </div>
        )
      }
    }