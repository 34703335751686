import Cookies from 'universal-cookie';
const cookies = new Cookies();

let date;

export default (
  state = {
    landingPage: false,
    id: '',
    ttsToken: false,
    errorSum: false,
    questions: [],
    question: false,
    displayLanguage: 'spanish',
    testLanguage: 'english',

    learnLanguage: 'spanish',
    baseLanguage: 'english',
    //vocabIds: [],

    token: '',
    // lessonMode: false

    userInfo: false,
    email: '',
    //put these into compontent, will take some work thoug
    userInfoAll: [],
    userInfoAllClean: [],

    userWordAll: [],
    noData: false,

    isLoggedIn: false,
    status: '',
    message: '',
    success: false,
    forceLogin: false,
    sentences: [],
  },
  action
) => {
  if (state.lessonFinsh) {
    state.lessonMode = false;
  } else {
    state.lessonMode = true;
  }

  switch (action.type) {
    case 'LANDING_PAGE':
      state = {
        ...state,
        landingPage: state.landingPage ? false : true,
      };
      break;
    case 'GET_TTS_TOKEN_SUCCESS':
      let ttsToken = action.payload.data.token;
      state = {
        ...state,
        ttsToken: ttsToken,
      };
      break;
    case 'FORCE_LOGIN':
      state = {
        ...state,
        forceLogin: true,
      };
      break;
    case 'REMOVE_MESSAGE':
      state = {
        ...state,
        message: '',
      };
      break;
    case 'USERINFO_GET_ERROR':
      state = {
        noData: true,
      };
      break;
    case 'USERINFO_GET_OFFLINE':
      state = {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload.userInfo },
        //userWords: action.payload.data.userWords,
        learnLanguage: action.payload.userInfo.learnLanguage,
        email: action.payload.userInfo.email,
        //noData: (action.payload.data.userWords.length === 0) ? true : false
      };
      // //console.log(state.input)

      break;
    case 'USERINFO_GET':
      if (!action.payload) break;
      state = {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload.userInfo },
        //userWords: action.payload.data.userWords,
        learnLanguage: action.payload.userInfo?.learnLanguage,
        email: action.payload.userInfo?.email,
        //noData: (action.payload.data.userWords.length === 0) ? true : false
      };
      // //console.log(state.input)

      break;

    //depricated
    case 'USERINFO_GET_SUCCESS':
      if (!action.payload.data.userInfo) break;
      state = {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload.data.userInfo },
        //userWords: action.payload.data.userWords,
        learnLanguage: action.payload.data.userInfo.learnLanguage,
        email: action.payload.data.userInfo.email,
        //noData: (action.payload.data.userWords.length === 0) ? true : false
      };
      // //console.log(state.input)

      break;
    case 'USERINFO_GET_SINGLE_SUCCESS':
      state = {
        ...state,
        userInfo: action.payload.data.userInfo,
        userWords: action.payload.data.userWords,
      };
      // //console.log(state.input)

      break;
    case 'USERINFO_GET_ALL_SUCCESS':
      let clean = action.payload.data.userInfo.filter((user) => user.email && user.email.length > 0);
      state = {
        ...state,
        userInfoAll: action.payload.data.userInfo,
        userWordAll: action.payload.data.userWords,
        userInfoAllClean: clean,
      };
      // //console.log(state.input)

      break;
    case 'USERINFO_USER_SETTING_TEMP':
      state = {
        ...state,
        learnLanguage: action.payload.learnLanguage,
        email: action.payload.email,
      };
      // //console.log(state.input)

      break;

    case 'USERINFO_GET_LOCAL':
      let foundUserInfo = state.userWordAll.filter((word) => {
        return word.user == action.payload;
      });

      state = {
        ...state,
        userInfo: foundUserInfo,
      };
      // //console.log(state.input)

      break;
    case 'LOGIN_CHECK':
      console.log('check login', action.payload);
      state = {
        ...state,
        token: action.payload.token,
        status: action.payload.status,
        message: action.payload.message,
        isLoggedIn: action.payload.success,
        forceLogin: action.payload.success ? false : true,
        userInfo: { ...state.userInfo, ...action.payload },
      };
      console.log('check state at login', state);
      let logoutDate = new Date();
      logoutDate.setDate(logoutDate.getDate() + 10);
      ////console.log(date)
      cookies.set('token', action.payload.token, { path: '/', expires: logoutDate });
      break;
    case 'LOGIN_LOG_OUT':
      state = {
        ...state,
        token: '',
        status: '',
        message: 'logged out',
        isLoggedIn: false,
        success: false,
      };
      // let date = new Date();
      // date.setDate(date.getDate() + 1)
      // ////console.log(date)
      cookies.remove('token');
      break;
    case 'LOGIN_AUTO_LOGIN_FAILURE':
      break;
    case 'LOGIN_AUTO_LOGIN':
      // console.log('auto login reducer', state, action)

      state = {
        ...state,
        status: action.payload.email === 'admin' ? 'admin' : 'default',
        isLoggedIn: action.payload.success,
        userInfo: action.payload,
      };
      break;

    case 'LOGIN_SIGNUP_ANON':
      console.log('login signup anon', action.payload);
      state = {
        ...state,
        token: action.payload.data.token,
        status: action.payload.data.status,
        success: action.payload.data.success,
        isLoggedIn: action.payload.data.success,
        userInfo: { ...state.userInfo, ...action.payload.data },
      };
      console.log('check new state', state);
      date = new Date();
      date.setDate(date.getDate() + 1);
      cookies.set('token', action.payload.data.token, { path: '/', expires: date });
      break;
    case 'LOGIN_SIGNUP':
      state = { ...state, success: action.payload.data.success };
      break;
    default:
      break;
  }
  return state;
};
