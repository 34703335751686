import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux'; // /lib/connect / connect
import { Redirect, Link } from 'react-router-dom';
import { style } from '../config/styleConfig';
import WatsonSpeech from 'watson-speech';
import Answer from './Answer.js';
import UserWordSingle from '../Main/UserWordSingle';
import Status from './Status/Status';
import Info from '../util/Info.js';
import sound from './_success.wav';
import { getTTSTokenAPI, EndlessGetFirstWord, EndlessSendAnswer, getUserInfo } from './vocabAPI.js';
import {
  buildSentenceAnswer,
  buildSentenceAnswerString,
  buildSentenceQuestion,
  buildSentenceQuestionString,
} from './build.js';

// the state contains currentlearndata
// but also question, which has that same learnData, but also userWord
// but userWord is sometimes (I think after submit) out of date, eg counter 2, while learnData counter would be 3
// userWord is not really used, currentlearndata is
// there is also newUserLearnData from the backend from sendData

const cookies = new Cookies();

let TEAL = '#009688';
let PINK = '#e91e63';
let ORANGE = '#ff9800';

let boxStyle = {
  height: '20vh',
  margin: '20px',
  padding: 30,
  verticalAlign: 'middle',
  boxShadow: '0 10px 10px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.05)',
};
// box - shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
class Vocab extends Component {
  constructor() {
    super();
    this.EndlessSendAnswer = EndlessSendAnswer.bind(this);
    this.getTTSTokenAPI = getTTSTokenAPI.bind(this);
    this.EndlessGetFirstWord = EndlessGetFirstWord.bind(this);
    this.getUserInfo = getUserInfo.bind(this);

    this.buildSentenceAnswer = buildSentenceAnswer.bind(this);
    this.buildSentenceAnswerString = buildSentenceAnswerString.bind(this);
    this.buildSentenceQuestion = buildSentenceQuestion.bind(this);
    this.buildSentenceQuestionString = buildSentenceQuestionString.bind(this);

    this.state = {
      performanceData: {
        //energy = letters typed
        //xp = learnscore
        totalEnergyChangeArray: [],
        totalEnergy: 0,

        learnScoreChange: 0,
        totalLearnScoreChange: 0,
        learnScoreChangeArray: [],
        srsLength: 0,
      },
      conjugationMode: false,
      srsMode: false,
      articleMode: false,
      question: false,
      // questions: [],
      currentLearnData: false,
      errorRate: false,
      errorRates: false,
      userInfo: false,
      wordId: false,
      modal: false,
      vocabMode: '',
      sentence: false,
      sentenceIndex: 0,
      scores: false,
      allowEnter: true,
      ttsToken: false,
      input: '',
      questionMode: true,
      timer: 0,
      startTime: Date.now(),
      totalTime: 0,
      activateVoice: false,
      errorScore: false,
      redirect: false,
      learnLanguage: '',
      testLanguage: '',
      baseLanguage: '',
      displayLanguage: '',

      nextQuestion: false,
      nextSentence: false,
      nextSentenceIndex: 0,
      nextSentences: false,
      nextDisplayLanguage: '',
      nextTestLanguage: '',

      windowWidth: window.innerWidth.toFixed(),
    };
  }
  componentDidMount() {
    this.getUserInfo();
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.setState({ ttsToken: this.props.store.ttsToken });
    // console.log('store', this.props.store);

    this.getTTSTokenAPI();
    let type = this.props.match.path;
    if (type === '/endlessMode') {
      this.setState({ vocabMode: 'endless' });
      this.EndlessGetFirstWord(this.props);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillReceiveProps(nextProps) {
    this.checkNewToken(nextProps);
  }

  handleWindowSizeChange = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  startTimer = () => {
    setInterval(() => {
      let timer = this.state.timer;
      timer = timer - 1000;
      timer = timer < 0 ? 0 : timer;
      this.setState({ timer: timer });
    }, 1000);
  };

  setTimer = () => {
    this.setState({ timer: 10 * 1000 });
  };

  checkNewToken = (nextProps) => {
    if (nextProps.token) this.setState({ token: nextProps.token });
  };

  handleClick = () => {
    this.next();
  };
  playSound = () => {
    if (!this.state.questionMode) {
      let audio = new Audio(sound);
      this.state.errorRate === 0 && audio.play();
    }
  };
  next = () => {
    if (!this.state.questionMode) this.setState({ input: '' });
    this.changeLessonMode();
  };
  handleKeyPressNoAnswer = (e) => {
    if (e.key === ' ' && this.state.allowEnter && this.state.input.length < 1) {
      this.next();
    }
  };
  handleKeyPress = (e) => {
    this.handleKeyPressNoAnswer(e);
    if (e.key === 'Enter' && this.state.allowEnter) this.next();
  };
  speech = (t) => {
    if (!this.state.ttsToken || !this.state.activateVoice || !this.state.userInfo.userSettings.allowTTS) return false;
    let text = t ? t : 'audio no disponible';
    let voices = {};
    voices.portuguese = 'pt-BR_IsabelaVoice';
    voices.spanish = 'es-ES_EnriqueVoice';
    voices.french = 'fr-FR_ReneeVoice';
    voices.english = 'en-GB_KateVoice';

    let voice = voices.spanish; // ? voices[props.store.displayLanguage] : "es-ES_EnriqueVoice"

    WatsonSpeech.TextToSpeech.synthesize({
      text: text,
      token: this.state.ttsToken,
      voice: voice,
    });
    this.setState({ activateVoice: false });
  };
  EndlessSwapNextQuestion = () => {
    let {
      nextQuestion,
      nextDisplayLanguage,
      nextTestLanguage,
      nextSentences,
      nextSentenceMetaData,
      nextSelectedLearnData,
    } = this.state;

    let currentLearnData = nextQuestion.currentLearnData ? nextQuestion.currentLearnData : false;
    if (nextSentences) {
      let nextSentenceWord = nextSentences.find((word) => word.english === nextQuestion.english);
      let display = nextSentenceWord && nextSentenceWord.display;
      if (display) {
        currentLearnData =
          nextSentenceWord.currentLearnDataList &&
          nextSentenceWord.currentLearnDataList.find(
            (learnData) => learnData.testWord === display[nextTestLanguage].trim()
          );
        if (!currentLearnData) currentLearnData = false;
      }
    }
    // test if works
    if (nextSentenceMetaData) {
      // currentLearnData = nextSentenceMetaData.chosenLearnData;
      currentLearnData = nextQuestion.userWord;
    }
    this.setState({
      i: this.state.i + 1,
      question: nextQuestion,
      displayLanguage: nextDisplayLanguage ? nextDisplayLanguage : this.state.displayLanguage,
      sentence: nextSentences ? nextSentences : false,
      sentenceMetaData: nextSentenceMetaData ? nextSentenceMetaData : false,
      currentLearnData,
      testLanguage: nextTestLanguage ? nextTestLanguage : this.state.testLanguage,
      selectedLearnData: nextSelectedLearnData,
    });
  };
  checkValidIndex = (i, question, word) => {
    if (question[i] === undefined) i = 0;
    else if (question[i].english === word.english) i++;
    if (question[i] === undefined) i = 0;
    return i;
  };
  changeLessonMode = () => {
    let { questionMode, vocabMode, performanceData, sentence, startTime } = this.state;
    if (!questionMode) {
      if (vocabMode === 'endless') this.EndlessSwapNextQuestion();

      let start = Date.now();
      performanceData.learnScoreChange = 0;
      this.setState({
        learnScoreChange: 0,
        startTime: start,
        questionMode: true,
        activateVoice: true,
        performanceData,
      });
    } else {
      let end = Date.now();
      let totalTime = (end - startTime) / 1000;
      if (sentence) totalTime = totalTime / sentence.length;

      this.setState({
        endTime: end,
        totalTime: totalTime,
        questionMode: false,
        errorScore: false,
      });

      if (vocabMode === 'endless') this.EndlessSendAnswer(totalTime);
    }
  };

  sentenceFeedBackColor = (scores, i) => {
    //return {color: 'black'};
    if (scores === undefined || scores[i] === undefined || !scores) return { color: 'black' };
    let style;
    scores = scores.map((score) => {
      if (score.errorScore !== undefined) return score.errorScore;
      else return score;
    });

    if (scores[i] === 0) return { color: 'teal' };
    else if (scores[i] === 1) style = { color: 'orange' };
    else style = { color: PINK };

    return style;
  };

  disableModal = () => {
    this.setState({ modal: false });
  };

  noAnswer = () => {
    this.next();
  };

  setDebug = () => {
    this.setState({ debug: !this.state.debug });
  };

  setConjugationMode = () => {
    this.setState({ conjugationMode: !this.state.conjugationMode });
  };

  setSrsMode = () => {
    this.setState({ srsMode: !this.state.srsMode });
  };

  setArticleMode = () => {
    this.setState({ articleMode: !this.state.articleMode });
  };

  render() {
    if (!cookies.get('token')) return <Redirect to="/Login" />;
    let { question, vocabMode, questionMode, displayLanguage, testLanguage, sentence, articleMode } = this.state;
    // console.log('check state', this.state);
    //  let progressBarLeft = Math.floor((totalSessionCounter / lessonLength) * 100);

    let answer,
      answerString,
      questionString = 'Loading...';
    let word, newWord, wordId, showArticleQuestion, showArticleAnswer;
    let questionJsx;

    if (vocabMode === 'endless' && question) {
      word = question;
      wordId = word.wordId ? word.wordId : word._id;
      newWord = word.new;
      answerString = question[testLanguage];
      showArticleQuestion =
        articleMode && displayLanguage == 'spanish' && question.pos == 'NN'
          ? question.gender.spanish == 'f'
            ? 'la'
            : 'el'
          : '';
      showArticleAnswer =
        articleMode && testLanguage == 'spanish' && question.pos == 'NN'
          ? question.gender.spanish == 'f'
            ? 'la'
            : 'el'
          : '';
      answer = question[testLanguage];
      question = question[displayLanguage];
    }

    if (sentence) {
      questionJsx = this.buildSentenceQuestion();
      answer = this.buildSentenceAnswer();
      answerString = this.buildSentenceAnswerString();
      questionString = this.buildSentenceQuestionString();
      // if (!questionMode) {
      //   if (displayLanguage === 'spanish') this.speech(questionString);
      //   if (testLanguage === 'spanish') this.speech(answerString);
      // }
    } else {
      //if(this.state.displayLanguage == 'spanish')this.speech(question)
      // if (!questionMode) {
      //   if (displayLanguage === 'spanish') this.speech(question);
      //   if (testLanguage === 'spanish') this.speech(answer);
      // }
      questionJsx = (
        <h1
          id={wordId}
          style={{
            color: newWord ? ORANGE : 'black',
            textAlign: 'center',
            textDecoration: !questionMode ? 'underline' : '',
            textDecorationStyle: !questionMode ? 'dotted' : '',
            textDecorationColor: !questionMode ? 'grey' : '',
            fontSize: this.state.sentence ? 25 : 44,
            //fontSize: "24pt"
            //fontSize: answer == undefined ? '3.2rem' : answerString.length < 40 ? '3.2rem' : '2.4rem'
          }}
          onClick={(e) => {
            if (!questionMode) return this.setState({ wordId: e.target.id, modal: true });
          }}
        >
          {showArticleQuestion} {question}
        </h1>
      );
    }
    if (this.state.debug && !this.state.input) this.setState({ input: answerString.trim() });

    let learnData = this.state.currentLearnData;
    if (!learnData && word) {
      learnData = word.currentLearnData;
    }
    // console.log(learnData);

    return (
      <div>
        <UserWordSingle id={wordId} modal={this.state.modal} disableModal={this.disableModal.bind(this)} />
        <div className="container" style={{ minHeight: '100vh' }}>
          {this.state.windowWidth > 1200 && (
            <Status
              userInfo={this.state.userInfo}
              setDebug={this.setDebug.bind(this)}
              debug={this.state.debug}
              conjugationMode={this.state.conjugationMode}
              setConjugationMode={this.setConjugationMode.bind(this)}
              srsMode={this.state.srsMode}
              articleMode={this.state.articleMode}
              setSrsMode={this.setSrsMode.bind(this)}
              setArticleMode={this.setArticleMode.bind(this)}
              currentLearnData={learnData}
              headWord={word}
              // this was buggy
              // currentLearnData={this.state.currentLearnData}
              performanceData={this.state.performanceData}
            />
          )}
          {false && this.state.vocabMode === 'endless' && (
            <div
              className="row white"
              style={{
                ...style,
                margin: '20px',
                verticalAlign: 'middle',
                height: '50px',
                padding: '0px',
              }}
            >
              <h4 style={{ textAlign: 'center', lineHeight: '160%' }}>{this.state.timer}</h4>
            </div>
          )}
          {this.state.userInfo && this.state.userInfo.loggedInDate.length == 1 && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Link to="/usersettings" className="btn pink" style={{ zIndex: 100, color: 'white' }}>
                Sentences are experimental and can be disabled in the settings
              </Link>
            </div>
          )}

          <div
            className="row white"
            style={{
              ...boxStyle,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {questionJsx}
          </div>

          <div
            className="white"
            style={{
              ...boxStyle,
              padding: 15,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {this.state.windowWidth > 1200 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginBottom: '20px',
                }}
              >
                <p style={{ textAlign: 'center', marginRight: 5 }}>translate to {this.state.testLanguage}</p>
                <Info message={'If you dont know a word just skip it and submit the answer'} />
              </div>
            )}
            <input
              style={{
                borderWidth: 5,
                textAlign: 'center',
                fontSize: 35,
                paddingBottom: 5,
                maxWidth: '100%',
                width: 150 + 20 * (this.state.input.length + 1),
              }}
              autoFocus={true}
              onChange={(e) => this.setState({ input: e.target.value })}
              onKeyPress={this.handleKeyPress.bind(this)}
              value={this.state.debug && !this.state.input ? answerString : this.state.input}
              defualtValue="test val"
              placeholder={`${false && word.new ? answerString : 'translate'}`}
            />
          </div>

          <Answer
            {...this.state}
            {...this.props}
            handleClick={this.handleClick.bind(this)}
            showArticleAnswer={showArticleAnswer}
            answer={answer}
            noAnswer={this.noAnswer.bind(this)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    activateVoice: () => {
      dispatch({
        type: 'VOCAB_ACTIVATE_VOICE',
      });
    },
    disableVoice: () => {
      dispatch({
        type: 'VOCAB_DISABLE_VOICE',
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vocab);
