import { HStack, Text } from '@chakra-ui/layout';
import React from 'react';

const StatusItem = ({ text, icon }) => {
  return (
    <HStack mx="1">
      <HStack mx="1">
        <Text mx="1">{text}</Text>
        {icon}
      </HStack>
    </HStack>
  );
};

export default StatusItem;
