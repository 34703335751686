import React, { Component } from 'react';
import { style } from '../../config/styleConfig.js';
import axios from 'axios';
import { connect } from 'react-redux'; // /lib/connect / connect
import levenshtein from 'fast-levenshtein';
import Dropdown from '../Dropdown';
import Cookies from 'universal-cookie';
import url from '../../config/config.js';
import { Word, Synonyms } from './components';
import { HStack, VStack } from '@chakra-ui/layout';
import { Select, Text, Input } from '@chakra-ui/react';
import Pagination from './components/Pagination.js';
const cookies = new Cookies();

class QuickWordEdit extends Component {
  constructor() {
    super();
    this.state = { grammar: 'presentIndicative' };
  }

  componentWillReceiveProps(nextProps) {
    console.log('nextProps', nextProps);
  }
  shouldComponentUpdate({ headWord }) {
    // this.setState({ headWord });
    return true;
  }
  customChangeValue(e) {
    this.setState({ grammar: e.target.innerText });
  }

  render() {
    let grammar = [
      'presentIndicative',
      'preteritIndicative',
      'futureIndicative',
      'presentContinuous',
      'futureContinuous',
      'futurePerfect',
      // 'imperative',
      'presentPerfect',
      'pastPerfect',
    ];
    console.log('receive props at render', this.props, this.props.headWord, this.props.handleChangeWordEnglish);
    const {
      english,
      spanish,
      german,
      disabled,
      synonyms,
      orderRank,
      onSetRank,
      success,
      originalOrderRank,
      handleChangeWord,
      handleSynonymChange,
      handleOrderRankChange,
      handleWordSave,
      handleDisableWord,
      handleAddWordSynonyms,
      handleKeyPress,
      handleWordClick,
      headWord,
    } = this.props;
    if (!english) {
      return null;
    }

    return (
      <div className="row white" style={{ ...style, height: 'auto' }}>
        <HStack spacing="4">
          <button
            style={{ margin: 30, marginLeft: 0 }}
            className={`btn ${success ? 'teal ' : 'red'}`}
            onClick={handleWordSave}
          >
            save
          </button>
          <button
            style={{ margin: 30, marginLeft: 0 }}
            className={`btn ${disabled ? 'red' : 'teal'}`}
            onClick={handleDisableWord}
          >
            Disable
          </button>
          <Dropdown
            style={{ width: 600 }}
            dropDownVal={`${this.state.grammar}`}
            dropDownValues={grammar}
            changeValue={this.customChangeValue.bind(this)}
            stateName={'grammar'}
          />
        </HStack>
        <h5>QuickWordEdit</h5>
        <HStack alignItems="flex-start">
          <VStack>
            <Word field={english} language={'english'} changeWord={handleChangeWord} />
            <Word field={spanish} language={'spanish'} changeWord={handleChangeWord} />
            <Word field={german} language={'german'} changeWord={handleChangeWord} />
          </VStack>
          <HStack alignItems="flex-start">
            <VStack>
              <Synonyms
                headWord={headWord}
                synonyms={synonyms.english}
                language={'english'}
                changeSynonyms={handleSynonymChange}
                handleAddWordSynonyms={handleAddWordSynonyms}
              />
            </VStack>
            <VStack>
              <Synonyms
                headWord={headWord}
                synonyms={synonyms.spanish}
                language={'spanish'}
                changeSynonyms={handleSynonymChange}
                handleAddWordSynonyms={handleAddWordSynonyms}
              />
            </VStack>
            <VStack>
              <Synonyms
                headWord={headWord}
                synonyms={synonyms.german}
                language={'german'}
                changeSynonyms={handleSynonymChange}
                handleAddWordSynonyms={handleAddWordSynonyms}
              />
            </VStack>
          </HStack>
          <VStack>
            {headWord.grammar?.spanish?.paradigms?.[this.state.grammar]?.map((g) => {
              return <Input value={g.word} />;
            })}
          </VStack>
          <VStack>
            {headWord.grammar?.english?.paradigms?.[this.state.grammar]?.map((g) => {
              return <Input value={g.word} />;
            })}
          </VStack>
          <VStack>
            {headWord.grammar?.german?.paradigms?.[this.state.grammar]?.map((g) => {
              return <Input value={g.word} />;
            })}
          </VStack>
          <VStack>
            <Text>{headWord.gender?.english}</Text>
            <Text>{headWord.gender?.spanish}</Text>
          </VStack>
        </HStack>

        <p>orderRank: {originalOrderRank}</p>
        <p>new orderRank</p>
        <input
          id={orderRank}
          style={{ marginRight: 5, width: 300 }}
          value={orderRank}
          onChange={handleOrderRankChange}
        ></input>
        <Pagination onSetPagination={onSetRank} paginationStep={orderRank} />
        <br />
        <HStack>
          <button
            style={{ margin: 30, marginLeft: 0 }}
            className={`btn ${success ? 'teal ' : 'red'}`}
            onClick={handleWordSave}
          >
            save
          </button>
        </HStack>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    store: state.vocabReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    reset: () => {
      dispatch({
        type: 'LESSON_RESET',
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(QuickWordEdit);
