import React, { Component } from 'react';
import { style as st } from './styleConfig.js';
import { connect } from 'react-redux'; // /lib/connect / connect
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router-dom';
import url from '../config/config';

const cookies = new Cookies();

class RootRedirect extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    //console.log( this.props)
    //console.log( cookies.get("token") )
  }
  render() {
    if (cookies.get('token')) {
      //return <Redirect to='/LessonOverview' />
      return <Redirect to="/endlessMode" />;
    } else if (window.innerWidth < 1000) {
      return <Redirect to="/watch" />;
    } else {
      return <Redirect to="/LandingPage" />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.vocabReducer,
    // wordManager: state.allWordsReducer,
    // login: state.LoginReducer,
    // userSettings: state.UserInfoReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTtsToken: () => {
      //console.log( "getting tts token right now ")
      dispatch({
        type: 'GET_TTS_TOKEN',
        payload: {
          request: {
            method: 'get',
            url: `${url}/ttsToken`,
            headers: { 'X-Auth-Token': cookies.get('token') },
          },
        },
      });
    },
    onLogIn: (response) => {
      dispatch({
        type: 'LOGIN_CHECK',
        payload: response,
      });
    },
    anonSignUp: (response) => {
      dispatch({
        type: 'LOGIN_SIGNUP_ANON',
        payload: response,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RootRedirect);
