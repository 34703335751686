let whiteBox = {
    margin: "30px",
    padding: "30px",
    verticalAlign: "middle",
    boxShadow: "0 10px 10px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.05)",
    teal : "#009688",
    pink : "#e91e63"
}

let color = {
        nonLearnedWordColor : '#e91e63',
        selectedWordColor : '#fff9c4',
        learnedWordColor : 'teal',
        newWordColor : 'orange',
        freshWordColor : 'cyan',
        learnedWordColorHex : '#009688',
        newWordColorHex : '#ff9800',
        freshWordColorHex : '#00bcd4',
}

export {whiteBox as style, color}