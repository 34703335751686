import React from 'react';
import { color } from './styleConfig.js';
import { IoIosFlash } from 'react-icons/io';
// needs words
let WordProgressBar = (props) => {
  let innerWidth = 0;
  let word;
  let newWordColorHex = color.newWordColorHex;
  let freshWordColorHex = color.freshWordColorHex;
  let learnedWordColorHex = color.learnedWordColorHex;
  let learnScoreChange = props.learnScoreChange;
  if (props.word) {
    let nonLearnedWordColor = color.nonLearnedWordColor;
    let selectedWordColor = '#fff9c4';
    let learnedWordColor = 'teal';
    let newWordColor = 'orange';
    let freshWordColor = 'cyan';
    word = props.word;
    let limit = 65;
    let learnScorePercent = (word && word.learnScore / limit) * 100 || 0; //eg 20 / 65 = 0.307 -> * 100 = 30.7
    if (word && learnScorePercent > 100) {
      innerWidth = 100;
    } else if (word && word.learnScore == 0) {
      innerWidth = 0;
    } else {
      //default
      innerWidth = (word && word.learnScore / limit) * 100;
    }
  }
  if (!innerWidth) innerWidth = 0;

  return (
    <div style={{ ...props.style, height: 20, backgroundColor: !word ? newWordColorHex : freshWordColorHex }}>
      <div style={{ width: `${innerWidth}%`, backgroundColor: learnedWordColorHex, height: '100%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: 'white',
            marginLeft: 15,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-start' }}>
            {false && <IoIosFlash />}
            <p style={{ textAlign: 'left', position: 'relative', color: 'white', width: 50 }}>
              {word ? word.learnScore + ' XP' : 'new'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WordProgressBar;
