import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { style } from './styleConfig.js';
import { connect } from 'react-redux'; // /lib/connect / connect
import Cookies from 'universal-cookie';
import { autoLogin, anonSignUp, getUserInfo } from '../Main/authentication';
import url from './config';

import {
  IoIosBook,
  IoMdBook,
  IoMdCreate,
  IoIosSettings,
  IoMdStats,
  IoMdHome,
  IoIosHelpCircleOutline,
  IoIosInformationCircle,
  IoIosLogOut,
  IoIosLogIn,
} from 'react-icons/io';
import { FaYoutube } from 'react-icons/fa';

const cookies = new Cookies();

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      isLoggedIn: false,
      windowWidth: window.innerWidth,
    };
    // console.log(anonSignUp);
    this.anonSignUp = anonSignUp.bind(this);
  }
  handleWindowSizeChange = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);

    let pass = cookies.get('token') ? true : false;
    if (pass) autoLogin(this.props);
    else anonSignUp(this.props);
    getUserInfo(this.props);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  currentUrl() {
    //console.log( 'landing',this.props.match)
    let visibility = this.props.match.params == 'LandingPage' ? 'hidden' : 'visible';
  }
  render() {
    // console.log(this.props);
    if (this.props.store.vocabReducer.landingPage) return null;
    let userInfo = this.props.store.vocabReducer.userInfo;
    let anonUser = userInfo?.userName === '' && userInfo?.email === '' ? true : false;
    if (!userInfo) anonUser = true;

    let linkSytle = { color: 'black', fontSize: 20, display: 'flex', alignItems: 'center', flexDirection: 'column' };

    return (
      <div className="white" style={{ ...style, margin: 10, padding: 15, width: 'auto' }}>
        <ul
          style={{
            textAlign: 'center',
            listStyleType: 'none',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Link style={{ ...linkSytle, alignSelf: 'flex-start' }} to="/LandingPage">
              Ling Academy
            </Link>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: 600,
              justifyContent: 'space-evenly',
              alignSelf: 'flex-start',
            }}
          >
            {false && (
              <Link style={linkSytle} to="/LessonOverview">
                <IoMdHome />
                <p style={{ fontSize: 10 }}>Home</p>
              </Link>
            )}
            {this.props.store.status === 'admin' && (
              <Link style={linkSytle} to="/Editor">
                Editor
              </Link>
            )}
            <Link style={linkSytle} to="/endlessMode">
              <IoMdCreate />
              <p style={{ fontSize: 10 }}>Learn</p>
            </Link>
            <Link style={linkSytle} to="/watch">
              <FaYoutube />
              <p style={{ fontSize: 10 }}>watch</p>
            </Link>
            {/* <Link style={linkSytle} to="/Reader">
              <IoMdBook />
              <p style={{ fontSize: 10 }}>Read</p>
            </Link> */}
            {this.state.windowWidth > 1200 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: 300,
                  justifyContent: 'space-evenly',
                  alignSelf: 'flex-start',
                }}
              >
                <li style={{ ...linkSytle, marginRight: 50, marginLeft: 50 }}></li>
                <Link style={linkSytle} to="/userinfo">
                  <IoMdStats />
                  <p style={{ fontSize: 10 }}>Insights</p>
                </Link>
                <Link style={linkSytle} to="/usersettings">
                  <IoIosSettings />
                  <p style={{ fontSize: 10 }}>Settings</p>
                </Link>
                <Link style={linkSytle} to="/About">
                  <IoIosInformationCircle />
                  <p style={{ fontSize: 10 }}>About</p>
                </Link>
                {this.props.store.status === 'admin' && (
                  <Link style={linkSytle} to="/adminTDash">
                    D
                  </Link>
                )}
              </div>
            )}
          </div>

          <div
            style={{
              float: 'right',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              alignSelf: 'flex-end',
            }}
          >
            {anonUser && false && (
              <Link
                to="/Login"
                className="btn pink hide-on-med-and-down"
                style={{ ...linkSytle, width: 250, marginRight: 25, color: 'white' }}
              >
                not signed in
              </Link>
            )}
            {anonUser ? (
              <Link style={{ ...linkSytle, float: 'right' }} className="right" to="/Login">
                <IoIosLogIn />
                <p style={{ fontSize: 10 }}>Login</p>
              </Link>
            ) : (
              <Link style={{ ...linkSytle, float: 'right' }} className="right" to="/Login" onClick={this.props.LogOut}>
                <IoIosLogOut />
                <p style={{ fontSize: 10 }}>Logout</p>
              </Link>
            )}
          </div>
        </ul>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    store: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendUserInfoToReducer: (data) => {
      dispatch({
        type: 'USERINFO_GET',
        payload: data,
      });
    },
    LogOut: () => {
      cookies.remove('token');
      dispatch({
        type: 'LOGIN_LOG_OUT',
      });
      //this.props.anonSignUp()
    },
    anonSignUp: (response) => {
      dispatch({
        type: 'LOGIN_SIGNUP_ANON',
        payload: response,
      });
    },
    autoLogin: (response) => {
      dispatch({
        type: 'LOGIN_AUTO_LOGIN',
        payload: response,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
