import { IconButton } from '@chakra-ui/button';
import { CloseButton } from '@chakra-ui/close-button';
import { ArrowBackIcon, ArrowForwardIcon, ArrowLeftIcon, ArrowRightIcon, SearchIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import { Text } from '@chakra-ui/react';
import { Flex, HStack, VStack } from '@chakra-ui/layout';
import React from 'react';
import Dropdown from '../../Dropdown';
import Pagination from './Pagination';

export const Word = ({ field, language, changeWord }) => {
  return (
    <VStack alignItems="flex-start" mr="4">
      <b>{language}</b>
      <Input size="xs" value={field} name={language} onChange={changeWord} />
    </VStack>
  );
};

export const Synonyms = ({ synonyms, language, changeSynonyms, handleAddWordSynonyms, headWord }) => {
  // if (synonyms[language].length === 0) return null;
  return (
    <div style={{ width: 300 }}>
      <b>{language} synonyms</b>
      {headWord?.synonyms?.[language]?.map((s, i) => (
        <Input
          name={language}
          key={language + i}
          id={i}
          style={{ marginRight: 5 }}
          value={s}
          onChange={changeSynonyms}
        />
      ))}
      <div style={{ width: 150, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <b>add {language}</b>
        <button className={'teal btn'} name={language} onClick={handleAddWordSynonyms}>
          +
        </button>
      </div>
    </div>
  );
};

Synonyms.defaultProps = {
  synonyms: [''],
  language: 'spanish',
};

export const SearchResults = ({ showSearch, search, value, handleKeyPress, handleWordClick, find, toggleSearch }) => {
  if (!showSearch) {
    return null;
  }

  return (
    <div
      style={{
        width: 600,
        heigth: 400,
        backgroundColor: 'white',
        position: 'absolute',
        top: 20,
        left: 200,
        padding: 30,
        borderColor: 'teal',
        borderSize: 'thin',
        borderStyle: 'solid',
        zIndex: 99,
      }}
    >
      <CloseButton onClick={toggleSearch} />
      <input autoFocus={true} onChange={search} value={value} placeholder="find word" onKeyPress={handleKeyPress} />
      <div className="collection">
        <Find find={find} handleWordClick={handleWordClick} />
      </div>
    </div>
  );
};

export const Find = ({ find, handleWordClick }) => {
  return find.map((word) => (
    <a href="#!" key={word._id} name={word.english} id={word._id} onClick={handleWordClick} className="collection-item">
      {word.english}
      <span className="new badge"> add </span>
    </a>
  ));
};

export const SyllabusNavigation = ({
  paginationStep,
  handleSetNavigation,
  navigationData,
  handleNavtionToggle,
  handleDropdown,
  onSetPagination,
}) => {
  const { headWordAmount, rank, showDisabled, pos } = navigationData;
  return (
    <HStack spacing="4">
      <div>
        <p># of words</p>
        <input name="headWordAmount" onChange={handleSetNavigation} value={headWordAmount}></input>
      </div>
      <Pagination onSetPagination={onSetPagination} paginationStep={paginationStep} />
      <div>
        <p>rank</p>
        <input name="rank" onChange={handleSetNavigation} value={rank}></input>
      </div>
      <div>
        <button
          className={`btn ${showDisabled ? 'black' : 'blue'}`}
          name="showDisabled"
          onClick={handleNavtionToggle}
          // value={showDisabled}
        >
          Disabled words: {showDisabled ? 'now shown' : 'now hidden'}
        </button>
      </div>
      <Dropdown
        dropDownVal={pos}
        dropDownValues={['all', 'NN', 'VB', 'JJ', 'RB']}
        changeValue={handleDropdown}
        stateName={'pos'}
      />
    </HStack>
  );
};

export const DisabledBadge = ({ disabled }) => {
  // if (!disabled) return null;
  return (
    <div
      style={{
        visibility: !disabled ? 'hidden' : 'visible',
        height: 15,
        width: 60,
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p style={{ color: 'white', fontSize: 9 }}>disabled</p>
    </div>
  );
};

export const LearnScoreBadge = ({ currentLearnData }) => {
  if (!currentLearnData) return null;
  let { learnScore } = currentLearnData;
  return (
    <div
      className="teal"
      style={{
        visibility: !currentLearnData ? 'hidden' : 'visible',
        height: 15,
        width: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p style={{ color: 'white', fontSize: 14 }}>{learnScore}</p>
    </div>
  );
};
