import React from 'react';
export default function PlayerControlls(props) {
  let {
    screenWidth,
    moveBack,
    moveForward,
    toggleSubtitleMode,
    subtitleMode,
    togglePlayback,
    toggleAdvancedControllsMode,
    isAdvancedMode,
    isPlaying,
  } = props;
  let btnWrapperStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: screenWidth > 1000 ? '10%' : '30%',
    height: '100%',
  };
  let btnColor = {
    width: '100%',
    height: '100%',
    backgroundColor: 'grey',
  };
  return (
    <div
      style={{
        color: 'white',
        width: '100%',
        height: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={btnWrapperStyle}>
        <button style={btnColor} onClick={() => moveBack()}>
          {'<'}
        </button>
      </div>
      <div style={btnWrapperStyle}>
        <button style={{ ...btnColor, backgroundColor: isPlaying ? 'grey' : 'grey' }} onClick={() => togglePlayback()}>
          {isPlaying ? 'pause' : 'play'}
        </button>
      </div>
      <div style={btnWrapperStyle}>
        <button style={btnColor} onClick={() => moveForward()}>
          {'>'}
        </button>
      </div>
      <div style={btnWrapperStyle}>
        <button
          style={{ ...btnColor, backgroundColor: subtitleMode ? 'teal' : 'grey' }}
          onClick={() => toggleSubtitleMode()}
        >
          {'subtitle mode'}
        </button>
      </div>
      <div style={btnWrapperStyle}>
        <button
          style={{ ...btnColor, backgroundColor: isAdvancedMode ? 'teal' : 'grey' }}
          onClick={() => toggleAdvancedControllsMode()}
        >
          {'advanced mode'}
        </button>
      </div>
    </div>
  );
}
