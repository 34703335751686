import React, { Component } from 'react';
import LineChart from '../util/LineChart';
import axios from 'axios';
// import _ from 'lodash'
import Table from '../util/Table.js';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux'; // /lib/connect / connect
import WordProgressBar from '../util/WordProgressBar.js';
import LearnDataKPI from './LearnDataKPI';
import Dropdown from './Dropdown';

import url from '../config/config.js';
import WordKPI from './WordKPI';
import levenshtein from 'fast-levenshtein';
const cookies = new Cookies();
class UserWordSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedConjugation: 'presentIndicative',
      newHeadWordEnglish: '',
      newHeadWordSpanish: '',
      transformNewHeadWord: false,
      dropDownPos: 'NN',
      searchCandidates: [],
      searchWordInput: '',
      searchWords: [],
      headWord: false,
      selectedSubWord: false,
      subWords: false,
      userLearnData: false,
      userInfo: false,
      word: false,
      dictWord: false,
      requestDone: false,
      modal: false,
      columns: [
        {
          Header: 'counter',
          width: 150,
          accessor: 'counter', // String-based value accessors!
        },
        {
          Header: 'totalCounter',
          width: 150,
          accessor: 'totaCounter', // String-based value accessors!
        },
        {
          Header: 'correct Answer',
          width: 150,
          accessor: 'testWord', // String-based value accessors!
        },
        {
          Header: 'your answer',
          width: 150,
          accessor: 'userAnswer', // String-based value accessors!
        },
        {
          Header: 'error',
          width: 150,
          accessor: 'errorScore', // String-based value accessors!
        },
        {
          Header: 'learn score',
          width: 150,
          accessor: 'learnScore', // String-based value accessors!
        },
        {
          width: 150,
          Header: 'time',
          accessor: 'time', // String-based value accessors!
        },
        {
          width: 150,
          Header: 'date',
          accessor: 'date', // String-based value accessors!
        },
        {
          width: 150,
          Header: 'srs',
          accessor: 'srs', // String-based value accessors!
        },
        {
          Header: 'speed',
          width: 150,
          accessor: 'totalTime', // String-based value accessors!
        },
      ],
    };
  }
  componentWillReceiveProps(nextProps) {
    //initial show: comes from parents, when link is clicked it should trigger props.modal = true
    this.setState({ modal: nextProps.modal ? true : false });
    if (nextProps.dictWord && nextProps.dictWord !== this.state.dictWord) {
      this.setState({ dictWord: nextProps.dictWord });
    }
    if (nextProps.modal && nextProps.id == '') this.props.disableModal();
    if (nextProps.id && nextProps.modal && !nextProps.dictWord) {
      let id = nextProps.id;
      let existingId = this.state._id;
      if (existingId !== id) this.getSingleWordAPI(id, this.state.headWord.english, this.props.userId);
    }
  }

  componentWillMount() {
    if (this.props.disableModal) {
      document.addEventListener('keydown', (e) => (e.keyCode === 27 ? this.close() : ''), false);
    }
    if (this.props.store.userInfo) this.setState({ userInfo: this.props.store.userInfo });
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', (e) => (e.keyCode === 27 ? this.close() : ''), false);
  }
  getDictWordAPI(headWord) {
    axios({
      method: 'post',
      url: `${url}/get/dictWord/any`,
      headers: { 'X-Auth-Token': cookies.get('token') },
      data: {
        searchWord: headWord,
      },
    })
      .then((response) => {
        if (response.data.dictWords) {
          this.setState({ dictWord: response.data.dictWords[0] });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }
  updateDictWord(dictWord) {
    if (dictWord.manualMatch) dictWord.match = dictWord.manualMatch;
    axios({
      method: 'post',
      url: `${url}/dictWord/update`,
      headers: { 'X-Auth-Token': cookies.get('token') },
      data: { dictWord: dictWord },
    })
      .then((response) => {
        if (response.data.dictWord) this.setState({ dictWord: response.data.dictWord });
      })
      .catch((e) => {
        console.error(e);
      });
  }
  getSingleWordAPI(wordId, english, userId = null) {
    //if(this.state.requestDone) return false;
    //this.setState({requestDone: true})
    console.log('userId modal', userId);
    axios({
      method: 'post',
      url: `${url}/Data/Word/Single/${userId}`,
      headers: { 'X-Auth-Token': cookies.get('token') },
      data: { wordId, english },
    })
      .then((response) => {
        let { word, userLearnData, headWord } = response.data;
        if (word) {
          let subWords = [];
          userLearnData.forEach((data) => {
            if (!subWords.some((subWord) => subWord == data.displayWord)) subWords.push(data.displayWord);
          });

          subWords = subWords.filter((u) => u);
          //let englishSubWord = subWords.find(s => s == headWord.english)
          //let spanishSubWord = subWords.find(s => s == headWord.spanish)
          //subWords = subWords.filter( s => {
          //    return s !== headWord.english
          //}).filter(s => s !== headWord.spanish).filter(s => s)
          //if(spanishSubWord)subWords.unshift(spanishSubWord)
          //if(englishSubWord)subWords.unshift(englishSubWord)
          let selectedSubWord = subWords[0];
          this.setState({ word, userLearnData, subWords, headWord });
          this.getDictWordAPI(response.data.word);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  saveUserInfo(userInfo) {
    axios({
      method: 'post',
      url: `${url}/userSettings`,
      headers: { 'X-Auth-Token': cookies.get('token') },
      data: { userInfo },
    }).then((response) => {
      //console.log(response.data)
      this.setState({ userInfo: response.data.userInfo });
    });
  }

  getUserInfo() {
    axios({
      method: 'get',
      url: `${url}/userSettings`,
      headers: { 'X-Auth-Token': cookies.get('token') },
    }).then((response) => {
      // //console.log(response.data.userInfo.learnLanguage)
      this.setState({ userInfo: response.data.userInfo });
      //this.props.tempUserSettings(response.data.userInfo.learnLanguage, response.data.userInfo.email)
    });
  }

  deleteWordAPI() {
    if (!this.state.headWord) return false;
    axios({
      method: 'delete',
      url: `${url}/delete/word`,
      headers: { 'X-Auth-Token': cookies.get('token') },
      data: { word: this.state.headWord },
    }).then((response) => {
      // //console.log(response.data.userInfo.learnLanguage)
      this.setState({ deleteWordSuccess: response.data.success });
      if (response.data.success) this.getSingleWordAPI(this.state.id, this.state.headWord.english);
      //this.props.tempUserSettings(response.data.userInfo.learnLanguage, response.data.userInfo.email)
    });
  }
  ignoreWordAPI() {
    let { headWord } = this.state;
    if (!headWord) return false;
    axios({
      method: 'post',
      url: `${url}/ignoreWord/${headWord._id}`,
      headers: { 'X-Auth-Token': cookies.get('token') },
    }).then((response) => {
      console.log('response ignore', response.data);
    });
  }
  getSubWordLearnData() {
    let { userLearnData, selectedSubWord } = this.state;
    if (!userLearnData || !selectedSubWord) return userLearnData;
    let learnData = userLearnData
      .filter((data) => data.testWord === selectedSubWord.trim())
      .sort((a, b) => b.counter - a.counter);
    return learnData;
  }
  prepData() {
    let userLearnData = this.state.userLearnData;
    if (!userLearnData || userLearnData.length == 0) return false;
    let wordDataPrep,
      learnData,
      allData,
      word,
      headWord,
      modifiedLearnData,
      tableData,
      data,
      counter,
      chartData = false;
    headWord = this.state.headWord;
    let kpiStyle = { display: 'flex', flexDirection: 'column', alignItems: 'center' };
    let kpiUnderline = { borderBottom: '2px solid black', margin: 25 };
    //console.log(word)
    let linkedAritlces = false;

    learnData = this.getSubWordLearnData();
    word = learnData[0];
    modifiedLearnData = learnData;
    modifiedLearnData.forEach((d) => {
      d.time = new Date(d.practiceDate).toTimeString().slice(0, 8);
      d.date = new Date(d.practiceDate).toLocaleDateString();
    });
    data = modifiedLearnData.map((dataPoint) => {
      return { x: dataPoint.counter, y: dataPoint.learnScore };
    });
    chartData = [...modifiedLearnData].sort((a, b) => a.counter - b.counter);
    tableData = [...modifiedLearnData].sort((a, b) => b.counter - a.counter);

    //counter = learnData.map(data => data.counter).sort((a,b) => a - b)
    counter = learnData
      .sort((a, b) => a.counter - b.counter)
      .map((data) => {
        let date = new Date(data.practiceDate);
        return `${date.getMonth()}/${date.getDate()}/${date.getHours()}`;
      });
    modifiedLearnData = learnData;
    data = learnData;
    if (this.state.dictWord) {
      linkedAritlces = this.state.dictWord.counters
        .filter((counter) => counter.articleId)
        .sort((a, b) => b.counter - a.counter);
    }
    return {
      wordDataPrep,
      learnData,
      allData,
      word,
      headWord,
      modifiedLearnData,
      tableData,
      data,
      counter,
      chartData,
      kpiStyle,
      kpiUnderline,
      linkedAritlces,
      userLearnData,
    };
    //let {word, headWord, modifiedLearnData, tableData, counter, chartData, kpiStyle, kpiUnderline, linkedAritlces} = this.prepData();
  }
  getLinkedArticles() {
    if (this.state.dictWord)
      return this.state.dictWord.counters.filter((counter) => counter.articleId).sort((a, b) => b.counter - a.counter);
    else return false;
  }
  loadSearchWords() {
    axios({
      method: 'get',
      url: `${url}/get/searchWords`,
      headers: { 'X-Auth-Token': cookies.get('token') },
    })
      .then((response) => {
        if (response.data.searchWords) this.setState({ searchWords: response.data.searchWords });
      })
      .catch((e) => {
        console.error(e);
      });
  }
  getWordInfoStyle() {
    return {
      //margin: 'auto',
      width: '80%',
      height: '90vh',
      backgroundColor: 'white',
      padding: 20,
      borderColor: 'black',
      borderStyle: 'solid',
      borderWidth: 'thin',
      position: 'fixed',
      top: 20,
      left: '10%',
      visibility: this.state.modal ? 'visible' : 'hidden',
      zIndex: 1000,
    };
  }
  close() {
    this.props.disableModal();
  }
  transfromToVocab() {
    //if(dictWord.manualMatch) dictWord.match = dictWord.manualMatch
    let dictWord = this.state.dictWord;
    axios({
      method: 'post',
      url: `${url}/dictWord/transform/headWord`,
      headers: { 'X-Auth-Token': cookies.get('token') },
      data: { dictWord: dictWord, pos: this.state.dropDownPos },
    })
      .then((response) => {
        //if(response.data.dictWord) this.setState({dictWord: response.data.dictWord});
      })
      .catch((e) => {
        console.error(e);
      });
  }
  transformNewHeadWord() {
    this.setState({ transformNewHeadWord: !this.state.transformNewHeadWord });
  }
  sendNewHeadWord() {
    //if(dictWord.manualMatch) dictWord.match = dictWord.manualMatch
    let dictWord = this.state.dictWord;
    axios({
      method: 'post',
      url: `${url}/dictWord/new/headWord`,
      headers: { 'X-Auth-Token': cookies.get('token') },
      data: {
        dictWord: dictWord,
        pos: this.state.dropDownPos,
        english: this.state.newHeadWordEnglish,
        spanish: this.state.newHeadWordSpanish,
      },
    })
      .then((response) => {
        //if(response.data.dictWord) this.setState({dictWord: response.data.dictWord});
      })
      .catch((e) => {
        console.error(e);
      });
  }
  static disableModal() {
    this.setState({ modal: false });
  }
  render() {
    let { modal } = this.state;
    if (this.state.userLearnData.length == 0 || !this.state.userLearnData) {
      let headWord = this.state.headWord;
      let kpiStyle = { display: 'flex', flexDirection: 'column', alignItems: 'center' };
      let kpiUnderline = { borderBottom: '2px solid black', margin: 25 };
      //let linkedAritlces = this.getLinkedArticles()
      let { dictWord } = this.state;

      // new word, no learn data
      return (
        <div className="Userinfo">
          <div style={this.getWordInfoStyle()}>
            <button
              className="btn red"
              onClick={() => this.close()}
              style={{ position: 'fixed', height: 50, display: this.state.modal ? 'block' : 'none' }}
            >
              close
            </button>
            <div className="">
              <div className="row">
                <br />
                <div
                  className="col s12 white"
                  style={{ overflowY: 'auto', marginTop: '50px', height: '65vh', padding: '50px' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      fontSize: 20,
                      marginLeft: 25,
                      width: 200,
                    }}
                  >
                    {headWord && <WordKPI props={{ kpiStyle, kpiUnderline, headWord }} />}
                  </div>
                  {dictWord && (
                    <div
                      className="col s12 white"
                      style={{ overflowY: 'auto', marginTop: '50px', height: '65vh', padding: '50px' }}
                    >
                      <p>
                        {dictWord.word} - {dictWord.translation}
                      </p>
                      {dictWord.match && (
                        <p
                          id={dictWord.match.headWordId}
                          //onClick={(e) => this.getSingleWordAPI(e.target.id, '')}
                        >
                          {dictWord.match.headWordEnglish}
                        </p>
                      )}
                      <p>change translation:</p>
                      <input
                        value={dictWord.translation}
                        onChange={(e) => {
                          let dictWord = this.state.dictWord;
                          dictWord.translation = e.target.value;

                          this.setState({ dictWord });
                        }}
                      />
                      <p>find match</p>
                      <button onClick={() => this.loadSearchWords()}>load</button>
                      <input
                        value={this.state.searchWordInput}
                        onChange={(e) => {
                          let input = e.target.value;
                          let searchCandidates = this.state.searchWords
                            .filter(
                              (s) => levenshtein.get(s.english, input) < 4 || levenshtein.get(s.spanish, input) < 4
                            )
                            .sort((a, b) => {
                              let A =
                                levenshtein.get(a.english, input) <= levenshtein.get(a.spanish, input)
                                  ? levenshtein.get(a.english, input)
                                  : levenshtein.get(a.spanish, input);
                              let B =
                                levenshtein.get(b.english, input) <= levenshtein.get(a.spanish, input)
                                  ? levenshtein.get(b.english, input)
                                  : levenshtein.get(b.spanish, input);

                              return A - B;
                            });
                          searchCandidates.length = 10;
                          this.setState({ searchWordInput: input, searchCandidates });
                        }}
                      />
                      <button
                        onClick={() => {
                          let dictWord = this.state.dictWord;
                          if (this.state.userMatchWord) {
                            dictWord.manualMatch = {
                              headWordEnglish: this.state.userMatchWord.english,
                              headWordId: this.state.userMatchWord._id,
                            };
                          }
                          this.updateDictWord(dictWord);
                        }}
                      >
                        save
                      </button>
                      <p>
                        current match if id:{' '}
                        {this.state.dictWord && this.state.dictWord.match && this.state.dictWord.match.headWordEnglish}
                      </p>
                      <p>
                        current match if full:{' '}
                        {this.state.dictWord && this.state.dictWord.match && this.state.dictWord.match.english}
                      </p>
                      <p>user match: {this.state.userMatchWord && this.state.userMatchWord.english}</p>
                      <ul>
                        {this.state.searchCandidates.map((cand) => {
                          return (
                            <li
                              id={cand._id}
                              onClick={(e) =>
                                this.setState({
                                  userMatchWord: this.state.searchWords.find((s) => s._id === e.target.id),
                                })
                              }
                            >
                              {cand.english} - {cand.spanish}
                            </li>
                          );
                        })}
                      </ul>
                      <div style={{}}>
                        <button
                          className={`btn ${this.state.transformNewHeadWord ? 'teal' : 'grey'}`}
                          onClick={() => this.transformNewHeadWord()}
                        >
                          diffrent translation for headWord
                        </button>

                        <Dropdown
                          dropDownVal={`POS: ${this.state.dropDownPos}`}
                          dropDownValues={['NN', 'VB', 'RB', 'JJ']}
                          changeValue={Dropdown.changeValue.bind(this)}
                          stateName={'dropDownPos'}
                        />

                        {!this.state.transformNewHeadWord && (
                          <button className="btn pink" onClick={() => this.transfromToVocab()}>
                            transform vocab
                          </button>
                        )}
                        {this.state.transformNewHeadWord && (
                          <div>
                            <p>english</p>
                            <input
                              value={this.state.newHeadWordEnglish}
                              onChange={(e) => this.setState({ newHeadWordEnglish: e.target.value })}
                            />
                            <p>spanish</p>
                            <input
                              value={this.state.newHeadWordSpanish}
                              onChange={(e) => this.setState({ newHeadWordSpanish: e.target.value })}
                            />
                            <button className={`btn pink`} onClick={() => this.sendNewHeadWord()}>
                              submit
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      let prepositions = {
        english: ['I', 'You', 'He / She', 'We', 'You', 'They'],
        spanish: ['Yo', 'Tu', 'El / Ella', 'Nosotros', 'Vosotros', 'Ellas'],
      };
      let {
        word,
        headWord,
        modifiedLearnData,
        tableData,
        counter,
        chartData,
        kpiStyle,
        kpiUnderline,
        linkedAritlces,
        userLearnData,
      } = this.prepData();
      return (
        <div className="Userinfo">
          <div style={this.getWordInfoStyle()}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
                  <WordProgressBar word={word} style={{ marginLeft: 30, width: '80%' }} />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      fontSize: 20,
                      marginLeft: 25,
                    }}
                  >
                    <WordKPI props={{ kpiStyle, kpiUnderline, headWord }} />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        fontSize: 20,
                        marginLeft: 25,
                      }}
                    >
                      <button
                        className="btn red"
                        style={{ marginTop: 50, marginRight: 10, display: modal ? 'block' : 'none' }}
                        onClick={this.deleteWordAPI.bind(this)}
                      >
                        reset Word
                      </button>
                      <button
                        className="btn red"
                        style={{ marginTop: 50, marginRight: 10, display: modal ? 'block' : 'none' }}
                        onClick={this.ignoreWordAPI.bind(this)}
                      >
                        ignore word
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="btn red"
                  onClick={() => this.close()}
                  style={{ alignSelf: 'flex-end', height: 50, display: this.state.modal ? 'block' : 'none' }}
                >
                  close
                </button>
              </div>
            </div>

            <div className="">
              <div className="row">
                <br />
                <div
                  className="col s12 white"
                  style={{
                    overflowY: 'auto',
                    height: '65vh',
                    padding: '50px',
                  }}
                >
                  <LearnDataKPI props={{ kpiStyle, kpiUnderline, modifiedLearnData, userLearnData }} />

                  {this.state.userLearnData.length > 0 && (
                    <LineChart
                      data={{
                        labels: counter,
                        datasets: [
                          {
                            label: 'learn score',
                            data: chartData.map((dp) => dp.learnScore),
                            fill: false,
                            //backgroundColor: "#00bcd4",
                            borderWidth: 2,
                            borderColor: 'teal',
                            hidden: false,
                          },
                          {
                            label: 'speed',
                            fill: false,
                            data: chartData.map((dp) => dp.totalTime),
                            //backgroundColor: "red",
                            borderWidth: 2,
                            borderColor: 'black',
                            hidden: false,
                          },
                          {
                            label: 'errors',
                            fill: false,
                            data: chartData.map((dp) => dp.errorScore),
                            borderColor: 'pink',
                            borderWidth: 2,
                            hidden: false,
                            //backgroundColor: "green",
                          },
                          {
                            label: 'streak',
                            fill: false,
                            data: chartData.map((dp) => dp.streak),
                            borderColor: 'orange',
                            borderWidth: 2,
                            hidden: false,
                            //backgroundColor: "green",
                          },
                        ],
                      }}
                    />
                  )}
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: 50 }}>
                    <button className="btn blue" onClick={() => this.setState({ selectedSubWord: false })}>
                      all
                    </button>
                    <button
                      className="btn blue"
                      onClick={() => this.setState({ selectedSubWord: this.state.headWord.english })}
                    >
                      english
                    </button>
                    <button
                      className="btn blue"
                      onClick={() => this.setState({ selectedSubWord: this.state.headWord.spanish })}
                    >
                      spanish
                    </button>
                    <Dropdown
                      dropDownVal={`${this.state.selectedConjugation}`}
                      dropDownValues={[
                        'presentIndicative',
                        'preteritIndicative',
                        'futureIndicative',
                        'presentContinuous',
                        'futureContinuous',
                        'futurePerfect',
                        'imperative',
                        'presentPerfect',
                        'pastPerfect',
                      ]}
                      changeValue={Dropdown.changeValue.bind(this)}
                      stateName={'selectedConjugation'}
                      style={{ width: 500 }}
                    />
                  </div>
                  {this.state.headWord.grammar && (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                        {this.state.headWord.grammar.english.paradigms[this.state.selectedConjugation].map(
                          (phrase, i) => {
                            if (phrase)
                              return (
                                <p
                                  id={phrase.word}
                                  className={`${this.state.selectedSubWord == phrase.word ? 'teal' : 'grey'}`}
                                  onClick={(e) => {
                                    this.setState({ selectedSubWord: e.target.id });
                                  }}
                                >
                                  {prepositions.english[i]} {phrase.word}
                                </p>
                              );
                          }
                        )}
                      </div>
                      <div>
                        {this.state.headWord.grammar.spanish.paradigms[this.state.selectedConjugation].map(
                          (phrase, i) => {
                            if (phrase)
                              return (
                                <p
                                  id={phrase.word}
                                  className={`${this.state.selectedSubWord == phrase.word ? 'teal' : 'grey'}`}
                                  onClick={(e) => this.setState({ selectedSubWord: e.target.id })}
                                >
                                  {prepositions.spanish[i]} {phrase.word}
                                </p>
                              );
                          }
                        )}
                      </div>
                    </div>
                  )}
                  {this.state.userInfo && this.state.modal && this.state.userLearnData.length > 0 && (
                    <button
                      className="btn blue"
                      onClick={() => {
                        let userInfo = this.state.userInfo;
                        userInfo.userSettings.showTables = userInfo.userSettings.showTables ? false : true;
                        this.setState({ userInfo });
                        this.saveUserInfo(userInfo);
                      }}
                      style={{
                        width: '100%',
                        marginBottom: 20,
                        visibility: this.state.modal ? 'visible' : 'hidden',
                      }}
                    >
                      {this.state.userInfo.userSettings.showTables ? 'hide' : 'show details'}
                    </button>
                  )}
                  <Table
                    data={word && tableData}
                    columns={this.state.columns}
                    showPageJump={true}
                    showPagination={true}
                    defaultPageSize={30}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    store: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    endTime: () => {
      dispatch({
        type: 'VOCAB_END_TIME',
        payload: '',
      });
    },
    userInfoGet: () => {
      dispatch({
        type: 'USERINFO_GET',
        payload: {
          request: {
            method: 'get',
            url: `${url}/Data/UserInfo`,
            headers: { 'X-Auth-Token': cookies.get('token') },
          },
        },
      });
    },
    articlesGet: () => {
      //console.log("1")
      dispatch({
        type: 'ARTCLE_GET',
        payload: {
          request: {
            method: 'get',
            url: `${url}/Data/Articles`,
            headers: { 'X-Auth-Token': cookies.get('token') },
          },
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserWordSingle);
