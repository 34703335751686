import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {style} from "../config/styleConfig.js"
import { connect } from "react-redux";// /lib/connect / connect

import { signUp } from './authentication.js'

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmPrivacy: false,
            confirmTOS: false,
            email: '',
            password: '',
            password2: '',
            message: "",
            userName: ""

        }
        this.signUp = signUp.bind(this)
    }

    handleInput(e){
        this.setState({[e.target.name]: e.target.value})
    }
    getOut() {
        cookies.remove('token');
        this.setState({
            token: '',
            isLoggedIn: false,

        })
    }
    componentWillMount() {
        this.setState({ message: "" })
    }
    render() {
      return ( 
        <div className="container" >
          <div className="row ">
            <div className="white" style={{...style, width: '50%', margin: 'auto' }} >
              <Link className="btn grey" to="/Login"> Go to Login </Link>
              <br />
              <br />
              <h4>Sign Up</h4>
              <br />
              <p>{this.state.message}</p>
              <input type="text" name="userName" placeholder="username"  onChange={this.handleInput.bind(this)} />
              <input type="text" name="email" placeholder="Email (optional)" value={this.email} onChange={this.handleInput.bind(this)} />
              <input type="password" name="password" placeholder="Password" value={this.password} onChange={this.handleInput.bind(this)} />
              <input type="password" name="password2" placeholder="repeat password" value={this.password} onKeyPress={(e)=> {
                  if(false && e.key == 'Enter') {
                      this.handleSignUp()
                      }
              }} onChange={this.handleInput.bind(this)} />
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 25}}>
                  <Link to='/TOS'>I have read and agree to the Terms of Service</Link>
                  <button className={`btn ${this.state.confirmTOS ? 'teal' : 'grey'}`} onClick={() => this.setState({confirmTOS: this.state.confirmTOS ? false : true})} >Confirm</button>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 25}}>
                  <Link to='/Privacy'>I have read and agree to the Privacy Policy</Link>
                  <button className={`btn ${this.state.confirmPrivacy ? 'teal' : 'grey'}`} onClick={() => this.setState({confirmPrivacy: this.state.confirmPrivacy ? false : true})} >Confirm</button>
              </div>
              <button type="button" className="btn" onClick={this.signUp.bind(this)} disabled={(this.state.confirmPrivacy && this.state.confirmTOS) ? false : true} style={{width: '100%', marginTop: 25}}>Sign up</button>
            </div>
          </div>
        </div>
      )
    }
}








const mapStateToProps = (state) => {
    return {
        store: state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        //anonSignUp: (response) => {
        //    dispatch({
        //    type: "LOGIN_SIGNUP_ANON",
        //    payload: response
        //    })
        //},
        onLogIn: (response) => {
            dispatch({
                type: "LOGIN_CHECK",
                payload: response
            })
        }, 
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Signup)