import React from 'react';
let boxStyle = {
  height: '20vh',
  margin: '20px',
  padding: 10,
  verticalAlign: 'middle',
  boxShadow: '0 10px 10px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.05)',
};
let teal = '#009688';
let pink = '#e91e63';
let answerStyle = {
  ...boxStyle,
  boxShadow: '',
  height: '6vh',
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const Answer = (props) => {
  let answerLength = 0;
  //if(props.errorScore === 0) audio.play()
  if (props.answer) answerLength = props.answer.length;
  return (
    <div style={{ ...props.style }}>
      <div className="row white" style={{ ...boxStyle }}>
        {!props.questionMode && (
          <h1
            style={{
              color:
                props.errorScore === false
                  ? 'black'
                  : props.errorScore == 0
                  ? teal
                  : props.errorScore == 1
                  ? 'orange'
                  : pink,
              textAlign: 'center',
              fontSize: answerLength <= 3 ? '2.4rem' : '3.2rem',
            }}
          >
            {props.showArticleAnswer} {props.answer}
            {props.errorScore === 0 && (
              <div>
                <i
                  className="material-icons medium "
                  style={{
                    display: props.errorScore == 0 ? 'block' : 'none',
                    color: 'teal',
                    width: 100,
                    margin: 'auto',
                    fontSize: 42,
                  }}
                >
                  check
                </i>
              </div>
            )}
          </h1>
        )}
      </div>

      <div
        className=""
        style={{ ...answerStyle, justifyContent: props.windowWidth > 1200 ? 'space-between' : 'center' }}
      >
        {props.windowWidth > 1200 && (
          <button onClick={props.history.goBack} type="button" className="btn grey">
            Back
          </button>
        )}
        {props.windowWidth > 1200 && (
          <button className="btn blue" onClick={props.noAnswer}>
            Dont know (Spacebar)
          </button>
        )}
        <button onClick={props.handleClick} className="btn">
          {props.windowWidth > 1200 ? 'Next (Enter)' : 'Next'}
        </button>
      </div>
    </div>
  );
};
export default Answer;
