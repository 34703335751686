import React from "react";
import ScatterPlot from '../util/ScatterPlot.js';
export default (props) => {
    let {words, dropDownValX, dropDownValY, getId, scatterData, scatterDataLearnedWords, scatterDataHardWords} = props.props;
    return (
        <ScatterPlot 
            getId={getId}
            data={{
                labels: words.map(word => word.english + ' - ' + word.spanish),
                ids: words.map(word => word._id),
                // getId: this.getId.bind(this),
                datasets: [
                    {
                    label: `${dropDownValY} Y by ${dropDownValX} X`,
                    data: scatterData,
                    backgroundColor: "#00bcd4",
                    // borderColor: '#e91e63'
                    },
                    {
                    label: `learned`,
                    data: scatterDataLearnedWords,
                    backgroundColor: "teal",
                    // borderColor: '#e91e63'
                    },
                    {
                    label: `difficult`,
                    data: scatterDataHardWords,
                    backgroundColor: "#ff80ab",
                    // borderColor: '#e91e63'
                    },
                ]
                // borderColor: '#e91e63'
            }}
            options={{
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        usePointStyle: true,
                    }
                },
                scales: {
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: dropDownValY
                        }
                    }],
                    xAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: dropDownValX
                        }
                    }]
                }
            }}
        />
    )
}