import {useState, useEffect} from 'react';
import TextApiService from './TextAPIService';

export default (token) => {
  const [userDictWords, setUserDictWords] = useState([]);

  useEffect(() => {
    (async () => {
      let dictWords = await TextApiService.getUserDictWordsAPI();
      setUserDictWords(dictWords);
    })();

  }, [token])
  return [userDictWords]
}