import axios from 'axios';
import url from '../config/config.js';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
export function autoLogin(props) {
  axios({
    method: 'post',
    url: `${url}/autoLogin`,
    headers: { 'X-Auth-Token': cookies.get('token') },
  })
    .then((response) => {
      if (response.data.success) props.autoLogin(response.data);
      else anonSignUp(props);
    })
    .catch((e) => {
      console.log('autologin did not work');
      anonSignUp();
    });
}

export async function getUserInfo(props) {
  const response = await axios({
    method: 'get',
    url: `${url}/Data/UserInfo`,
    headers: { 'X-Auth-Token': cookies.get('token') },
  });
  props.sendUserInfoToReducer(response.data);
  return response.data;
}

export async function anonSignUp(props) {
  let response = await axios({
    method: 'post',
    url: `${url}/signUp/anon`,
  });

  let response2 = await axios({
    method: 'post',
    url: `${url}/authenticate/tempId`,
    data: { tempId: response.data._id },
  });
  if (response2.data.success) {
    props.anonSignUp(response2);
  } else {
    console.error('fail', response.data, response2.data);
    //anonSignUp()
  }
}
export async function loginAPI() {
  ////console.log(this.state)
  let response = await axios({
    method: 'post',
    url: `${url}/authenticate`,
    data: {
      userName: this.state.userName.toLowerCase(),
      password: this.state.password,
    },
  });
  this.props.onLogIn(response.data);
  this.setState({ loginData: response.data });
  let { success, message } = response.data;
  if (!response.data.success) {
    this.setState({ success, message });
    this.anonSignUp(this.props);
  } else this.props.history.push('/endlessMode');
}

export async function signUp() {
  if (this.state.password !== this.state.password2) {
    this.setState({ message: 'Passwords did not match' });
  } else if (this.state.userName.length === 0) {
    this.setState({ message: 'no userName' });
  } else {
    let response = await axios({
      method: 'post',
      url: `${url}/signUp/anon/real`,
      headers: { 'X-Auth-Token': cookies.get('token') || false },
      data: {
        userName: this.state.userName.toLowerCase().trim(),
        email: this.state.email.toLowerCase().trim(),
        password: this.state.password2,
      },
    });
    if (response.data.success) {
      ////console.log(this.props)
      this.setState({ message: response.data.message });

      const { history } = this.props;
      history.push({ pathname: '/' });
      // this.changeLoginStatus()
      // history.push({pathname:'/login'});
    } else {
      this.setState({ message: 'error: ' + response.data.message });
    }
  }
}
