
import React from "react";
export default function (props) {
    let {kpiStyle, kpiUnderline, headWord} = props.props;
    return (
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: 20, marginLeft: 25, width: 200}} >
            <div  style={kpiStyle}>
                <p style={{...kpiUnderline, borderBottom: '' }}>{headWord && headWord.english}</p>
                <p>{headWord && headWord.spanish}</p>
            </div>
            <div  style={kpiStyle}>
                <p style={kpiUnderline}>{headWord && headWord.pos}</p>
                <p>pos</p>
            </div>
            <div  style={kpiStyle}>
                <p style={kpiUnderline}>{headWord && headWord.rank}</p>
                <p>rank</p>
            </div>
            <div  style={kpiStyle}>
                <p style={kpiUnderline}>{headWord && headWord.frequency}</p>
                <p>frequency</p>
            </div>
        </div>
    )
}