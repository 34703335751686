import React, { Component } from 'react';
import { style } from '../config/styleConfig.js';
import Dropdown from './Dropdown';
import axios from 'axios';
import { connect } from 'react-redux'; // /lib/connect / connect
import url from '../config/config.js';
import Cookies from 'universal-cookie';
import Table from '../util/Table.js';
import { Link } from 'react-router-dom';
const cookies = new Cookies();

class AdminDashboard extends Component {
  constructor() {
    super();
    this.allUserDataUrl = '/basic/admin/userWords/get/all';
    this.state = {
      userLearnData: false,
      uniqueUsers: false,
      userNameSearch: '',
      singleUserLearnData: false,
      selectedTable: 'userLearnData',
      userInfo: false,
      columns: {
        userLearnData: [
          {
            Header: 'English',
            accessor: 'english',
            width: 150,
          },
          {
            Header: 'user name',
            accessor: 'userName',
            Cell: (props) => {
              //   console.log('table', props);
              return (
                <div>
                  <Link to={`/userinfo/${props.original.user}`}>{props.value}</Link>
                  <a
                    style={{ cursor: 'pointer' }}
                    id={props.original.userName}
                    onClick={(e) => this.sortForSingleUser(e)}
                    className="String"
                  >
                    sort by
                  </a>
                </div>
              );
            },
            width: 150,
          },
          {
            Header: 'date',
            accessor: 'practiceDate',
            width: 250,
          },
          {
            Header: 'test word ',
            accessor: 'testWord',
            width: 150,
          },
          {
            Header: 'userAnswer',
            accessor: 'userAnswer',
            width: 150,
          },
          {
            Header: 'Learnscore',
            accessor: 'learnScore',
            Cell: (props) => <span className="number">{props.value}</span>,
            width: 150,
          },
          {
            Header: 'counter',
            accessor: 'counter',
            width: 150,
          },
        ],

        userInfo: [
          {
            Header: 'user name',
            accessor: 'userName',
          },
          {
            Header: 'rank',
            accessor: 'rank',
          },
          {
            Header: 'last login',
            accessor: 'lastLoginDate',
          },
        ],
      },
    };
  }
  componentDidMount() {
    //console.log( this.props )
    this.getAllUserDataAdminAPI();
  }

  searchForUser() {
    let { userLearnData, userNameSearch } = this.state;
    let userName = userNameSearch;
    let singleUserLearnData = userLearnData.filter((u) => u.userName === userName);
    this.setState({ singleUserLearnData, selectedTable: 'singleUserLearnData' });
  }

  sortForSingleUser(e) {
    let userName = e.target.id;
    let { userLearnData, userNameSearch } = this.state;
    if (userNameSearch.length > 0) userName = userNameSearch;
    // console.log('clicked on user', e.target.id)
    let singleUserLearnData = userLearnData.filter((u) => u.userName === userName);
    this.setState({ singleUserLearnData, selectedTable: 'singleUserLearnData' });
  }
  getUniqueUsers(userLearnData) {
    let uniqueUsers = [];
    userLearnData.forEach((user) => {
      if (!uniqueUsers.some((unique) => unique.userName === user.userName)) uniqueUsers.push(user);
    });
    return uniqueUsers;
  }
  async getAllUserDataAdminAPI() {
    ////console.log(this.state)
    let response = await axios({
      method: 'get',
      url: `${url}${this.allUserDataUrl}`,
      headers: { 'X-Auth-Token': cookies.get('token') },
    });
    let { userLearnData, userInfo } = response.data;
    userInfo = userInfo.sort(
      (a, b) => b.loggedInDate[a.loggedInDate.length - 1] - a.loggedInDate[a.loggedInDate.length - 1]
    );
    userInfo = userInfo.map((u) => {
      return { ...u, lastLoginDate: u.loggedInDate[u.loggedInDate.length - 1] };
    });
    // console.log('respone', userLearnData, userInfo, response.data);
    let uniqueUsers = this.getUniqueUsers(userLearnData);
    if (userLearnData) {
      this.setState({ userLearnData, userInfo, uniqueUsers });
    }
  }
  render() {
    let { selectedTable } = this.state;
    let tableData = this.state[selectedTable];
    // console.log('allUserData',  this.state)
    // console.log('table data', tableData)
    return (
      <div className="row white" style={style}>
        <button className="btn blue" onClick={() => this.getAllUserDataAdminAPI()}>
          refresh
        </button>
        <button className="btn blue" onClick={() => this.searchForUser()}>
          search
        </button>
        <button className="btn grey" onClick={() => this.setState({ selectedTable: 'userLearnData' })}>
          back
        </button>

        <Dropdown
          dropDownVal={this.state.selectedTable}
          dropDownValues={['userLearnData', 'userInfo', 'uniqueUsers', 'singleUserLearnData']}
          changeValue={Dropdown.changeValue.bind(this)}
          stateName={'selectedTable'}
        />
        <p>Length: {tableData.length}</p>
        <input
          onChange={(e) => {
            this.setState({ userNameSearch: e.target.value });
          }}
          value={this.state.userNameSearch}
          placeholder={'search for a user'}
          onKeyDown={(e) => {
            console.log(e.key, e.keyCode);
            if (e.key == 'Enter') {
              this.searchForUser();
            }
          }}
        />
        {tableData && (
          <Table
            data={tableData}
            columns={this.state.columns[selectedTable === 'userInfo' ? 'userInfo' : 'userLearnData']}
            showPageJump={true}
            showPagination={true}
            defaultPageSize={30}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    store: state.vocabReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    reset: () => {
      dispatch({
        type: 'LESSON_RESET',
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
