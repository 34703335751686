import React from 'react';

export default class Dropdown extends React.Component {
  state = {
    displayMenu: false,
  };

  showDropdownMenu = this.showDropdownMenu.bind(this);
  hideDropdownMenu = this.hideDropdownMenu.bind(this);

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  }
  static changeValue(e) {
    this.setState({ [e.target.getAttribute('name')]: e.target.innerText });
  }

  render() {
    let { dropDownVal, dropDownValues, changeValue, stateName } = this.props;
    return (
      <div style={{ ...this.props.style, width: 250, position: 'relative', display: 'inline-block', zIndex: 10 }}>
        <button style={{ width: 250 }} onClick={this.showDropdownMenu} className="btn teal">
          {dropDownVal}
        </button>
        {this.state.displayMenu ? (
          <ul className="collection" style={{ margin: 0, cursor: 'pointer', position: 'absolute', width: 250 }}>
            {dropDownValues.map(selection => {
              return (
                <a className="collection-item" name={stateName} onClick={changeValue}>
                  {selection}
                </a>
              );
            })}
          </ul>
        ) : null}
      </div>
    );
  }
}
