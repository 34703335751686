import React from "react";
export default (props) => {
    let {kpiStyle, kpiUnderline, words, learnedWords, hardWords, todayWords} = props.props;
    return (
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}} >
            <div  style={{...kpiStyle, marginRight: 25}}>
                <h5 style={kpiUnderline}>{words.length}</h5>
                <h5>words</h5>
            </div>
            <div  style={{...kpiStyle, marginRight: 25}}>
                <h5 style={kpiUnderline}>{learnedWords.length}</h5>
                <h5>learned</h5>
            </div>
            <div  style={{...kpiStyle, marginRight: 25}}>
                <h5 style={kpiUnderline}>{hardWords.length}</h5>
                <h5>difficult</h5>
            </div>
            <div  style={kpiStyle}>
                <h5 style={kpiUnderline}>{todayWords.length}</h5>
                <h5>practiced today</h5>
            </div>
        </div>
    )
}