import {useState, useEffect} from 'react';
import VideoAPIService from './TextAPIService';

export default (token) => {
  const [idList, setIdList] = useState([]);
  const [initialVideoId, setInitialVideoId] = useState('');

  useEffect(() => {
    (async () => {

      let allVideos = await VideoAPIService.getAllYouTubeDataAPI();
      let idList = allVideos.youTubeDataIds.map(v => v.videoId);
      let videoId = idList[Math.floor(Math.random() * idList.length )]
      setIdList(idList)
      setInitialVideoId(videoId)

    })();

  }, [])
  return [idList, initialVideoId];
}