import {useState, useEffect} from 'react';
import TextAPIService from './TextAPIService';
import TextService from './TextService'
import url from '../config/config.js';
import axios from "axios"

export default (videoId, videoUrl) => {
  const [subtitles, setSubtitles] = useState([]);
  const [currentSub, setCurrentSub] = useState({words: []});
  const [errorMessage, setErrorMessage] = useState([]);
  const [dictWords, setDictWords] = useState([]);

  useEffect(() => {
    (async () => {

        try {
            let savedVideoData = await TextAPIService.getSingleYoutubeDataAPI(videoId);
            let subtitles = {text: []};
            if(savedVideoData.success){
                subtitles = savedVideoData.videoData.subtitles;

            } else {
                let LANG = 'es';
                //let VIDEOID = 'gNAdnYTcgUo';
                //VIDEOID = this.getIdFromTYURL(state.videoUrl)
                let ytURL = `https://video.google.com/timedtext?lang=${LANG}&v=${videoId}` ;

                let rawSubXML = await axios({ method: 'get', url: ytURL})
                subtitles = TextService.convertYouTubeManualCaptions(rawSubXML.data);
            }
            
            TextAPIService.saveVideoDataAPI(subtitles, videoUrl, TextService.getIdFromTYURL(videoUrl))
            let stringArray = TextService.getStringArray(subtitles.text);
            let dictWords = await TextAPIService.getDictWordsAPI(stringArray);
            setSubtitles(subtitles.text);
            setCurrentSub(subtitles.text[0]);
            setErrorMessage('');
            setDictWords(dictWords);
        } catch (e){
                console.log(e)
            try {
                let res = await axios({ 
                    method: 'post',
                    url: `${url}/get/autoCaption`,
                    data: {videoId},
                })
                let subtitles = res.data
                console.log(res)
                if(!subtitles.text) {
                  setErrorMessage('the video creator dosent want you to have subtitles')
                  return 
                }
                TextAPIService.saveVideoDataAPI(subtitles, videoUrl, TextService.getIdFromTYURL(videoUrl))
                let stringArray = TextService.getStringArray(subtitles.text);
                let dictWords = await TextAPIService.getDictWordsAPI(stringArray);

                setSubtitles(subtitles.text);
                setCurrentSub(subtitles.text[0]);
                setErrorMessage('');
                setDictWords(dictWords);
                
            } catch (e) {
                console.log(e)
                setErrorMessage('no subtitles available');
            }
        }
    })();

  }, [videoId])
  return [subtitles, currentSub, errorMessage, dictWords]
}