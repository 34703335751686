import React from 'react';
import { Route, Switch } from 'react-router-dom';

import About from '../Main/About/About';
import AdminDashboard from '../Main/AdminDashboard';
import LandingPage from '../landingPage/LandingPage';

import TextReader from '../Main/TextReader';
import Vocab from '../vocab/Vocab';
import UserInfo from '../Main/userInfo.js';
import Login from '../Main/login.js';
import Signup from '../Main/SignUp.js';
import UserSettings from '../Main/UserSettings';
import RootRedirect from '../util/RootRedirect';
import VideoWatcher from '../Main/VideoWatcher.js';
import Editor from '../Main/admin/Editor.js';
//import CreateHeadWord  from '../Main/CreateHeadWord';
import TOS from '../Main/TOS.js';
import Privacy from '../Main/Privacy';
import PrivacyMobileAndroid from '../Main/PrivacyMobileAndroid';

const Main = () => (
  <main>
    <Switch>
      <Route exact path="/about" component={About} />
      <Route exact path="/LandingPage" component={LandingPage} />
      <Route exact path="/adminTDash" component={AdminDashboard} />
      <Route exact path="/Reader" component={TextReader} />
      <Route exact path="/watch" component={VideoWatcher} />
      <Route exact path="/endlessMode" component={Vocab} />
      <Route exact path="/" component={RootRedirect} />
      <Route exact path="/userInfo/:userId" component={UserInfo} />
      <Route exact path="/userInfo" component={UserInfo} />
      <Route exact path="/usersettings" component={UserSettings} />
      <Route exact path="/TOS" component={TOS} />
      <Route exact path="/Privacy" component={Privacy} />
      <Route exact path="/PrivacyMobileAndroid" component={PrivacyMobileAndroid} />
      <Route exact path="/SignUp" component={Signup} />
      <Route exact path="/Login" component={Login} />
      <Route exact path="/Editor" component={Editor} />
    </Switch>
  </main>
);

//<Route exact path="/adminNewHeadWord" component={CreateHeadWord}/>

export default Main;
