import React, { Component } from 'react';
let orange = '#ff9800';
export function buildSentenceQuestion() {
  let sentence = this.state.sentence;
  let displayLanguage = this.state.displayLanguage;
  let question;
  return (
    <h1 style={{ textAlign: 'center', width: '100%' }}>
      {sentence.map((word, i) => {
        if (word.display) question = word.display[displayLanguage];
        else question = word[displayLanguage];
        return (
          <span
            key={i}
            id={word._id}
            onClick={(e) => {
              if (!this.state.questionMode && word._id) return this.setState({ wordId: e.target.id, modal: true });
            }}
            style={{
              color: word.new ? orange : 'black',
              textAlign: 'center',
              textDecoration: !this.state.questionMode && word._id ? 'underline' : '',
              textDecorationStyle: !this.state.questionMode ? 'dotted' : '',
              textDecorationColor: !this.state.questionMode ? 'grey' : '',
              fontSize: this.state.sentence ? '24pt' : '44pt',
              //fontSize: "24pt"
              //fontSize: answer == undefined ? '3.2rem' : answerString.length < 40 ? '3.2rem' : '2.4rem'
            }}
          >
            {question + ' '}
          </span>
        );
      })}
    </h1>
  );
}
export function buildSentenceAnswer() {
  let sentence = this.state.sentence;
  let testLanguage = this.state.testLanguage;
  let answer;
  return sentence.map((word, i) => {
    if (word.display) answer = word.display[testLanguage];
    else answer = word[testLanguage];
    return (
      <span key={i} style={this.sentenceFeedBackColor(this.state.scores, i)}>
        {answer + ' '}
      </span>
    );
  });
}
export function buildSentenceQuestionString() {
  let sentence = this.state.sentence;
  let displayLanguage = this.state.displayLanguage;
  let answer;
  return sentence.reduce((a, word) => {
    if (word.display) answer = word.display[displayLanguage];
    else answer = word[displayLanguage];
    return (a = a + ' ' + answer);
  }, '');
}
export function buildSentenceAnswerString() {
  let sentence = this.state.sentence;
  let testLanguage = this.state.testLanguage;
  let answer;
  return sentence.reduce((a, word) => {
    if (word.display) answer = word.display[testLanguage];
    else answer = word[testLanguage];
    return (a = a + ' ' + answer);
  }, '');
}
